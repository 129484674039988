import { Button, Input, Popconfirm, Popover } from 'antd'
import queryString from 'query-string'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import DotsThreeVerticalIcon from '../../assets/icon/DotsThreeVerticalIcon'
import FilterIcon from '../../assets/icon/FilterIcon'
import SearchIcon from '../../assets/icon/SearchIcon'
import MainHeader from '../../components/headers/MainHeader'
import LotModalCreate from '../../components/lots/LotModalCreate'
import LotsFilter from '../../components/lots/LotsFilter'
import PaginationGlobal from '../../components/pagination/PaginationGlobal'
import TableCustom from '../../components/table/Table'
import { useCancelLot } from '../../queries/mutation'
import { useLots } from '../../queries/queries'
import { lotStatus } from '../../utils/constants/lotStatus'
import styles from './lots.module.scss'

const Lots = () => {
  const [createLotModalVisible, setCreateLotModalVisible] = useState<{
    id: number | null
    visible: boolean
  }>({
    id: null,
    visible: false,
  })
  const location = useLocation()
  const navigate = useNavigate()
  const [pageSize, setPageSize] = useState(10)
  const [total, setTotal] = useState(1)
  const [drawerVisible, setDrawerVisible] = useState(false)
  const cancelLotMutation = useCancelLot()

  const { data, isLoading, isFetching } = useLots()

  useEffect(() => {
    if (data && data?.data.length > 0) {
      setTotal(Number(data?.totalElements))
      setPageSize(
        queryString.parse(location.search).size
          ? Number(queryString.parse(location.search)?.size)
          : 10
      )
    }
  }, [data, location])

  const pageHandle = (page: any, size: number) => {
    setPageSize(size)
    navigate(
      location.pathname +
        '?' +
        queryString.stringify({
          ...queryString.parse(location.search),
          page: page,
          size: size,
        })
    )
  }

  // cancel lot
  const cancelLot = (id: number) => {
    cancelLotMutation.mutate(id)
  }

  // lot status
  const statusLot = (status: string) => {
    switch (status) {
      case lotStatus.ACTIVE:
        return 'Aktiv'
      case lotStatus.NEW:
        return 'Yangi'
      case lotStatus.WAIT:
        return 'Kutilmoqda'
      case lotStatus.COMPLETED:
        return 'Tugallangan'
      case lotStatus.CANCELED:
        return <span>Bekor&nbsp;qilingan</span>
      case lotStatus.FAILED:
        return "O'tkazilmadi"
      default:
        return ''
    }
  }

  // update lot
  const updateLot = (id: number) => {
    setCreateLotModalVisible({ id, visible: true })
  }

  const columns = [
    {
      title: '#',
      dataIndex: 'number',
      render: (text: any, record: any, index: number) =>
        pageSize * (Number(data?.currentPage) - 1) + index + 1,
    },
    {
      title: 'NOMI',
      dataIndex: 'name',
      width: 400,
    },
    {
      title: 'Bo’lim',
      dataIndex: 'categoryName',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      align: 'center',
      render: (status: string) => (
        <span className={status}>{statusLot(status)}</span>
      ),
    },
    {
      title: 'Boshlang’ich narx',
      dataIndex: 'startingPrice',
      align: 'right',
      render: (startingPrice: number) => (
        <span>{startingPrice?.toLocaleString()}&nbsp;UZS</span>
      ),
    },
    {
      title: 'Badal',
      dataIndex: 'initialPercentage',
      align: 'right',
      render: (percentage: number) => <span>{percentage}%</span>,
    },
    {
      title: 'Boshlanish vaqti',
      dataIndex: 'startSaleTime',
    },
    {
      title: '',
      render: (record: { id: number; status: string }) => {
        return record.status === lotStatus.NEW ? (
          <Popover
            placement="left"
            trigger="click"
            content={
              <>
                <Popconfirm
                  title="Lotni bekor qilmoqchimisiz?"
                  placement="left"
                  okText="Ha"
                  trigger="click"
                  cancelText="Yo'q"
                  onConfirm={() => cancelLot(record.id)}
                >
                  <p className="cur-p">Bekor qilish</p>
                </Popconfirm>
                <p className="m0 cur-p" onClick={() => updateLot(record.id)}>
                  Tahrirlash
                </p>
              </>
            }
          >
            <span className="cur-p">
              <DotsThreeVerticalIcon />
            </span>
          </Popover>
        ) : (
          <span className="h32 w32 d-f" />
        )
      },
    },
  ]

  return (
    <>
      <MainHeader>Lotlar</MainHeader>
      <div style={{ margin: '16px' }}>
        <div className={styles.lots}>
          <div className={styles.lots_header}>
            <div className={styles.lots_header_left}>
              <Button
                className="filterButton"
                onClick={() => setDrawerVisible(true)}
              >
                <FilterIcon />
              </Button>
              <Input
                className="searchInput"
                prefix={<SearchIcon />}
                placeholder="Qidirish..."
                onChange={(e) =>
                  navigate(
                    location.pathname +
                      '?' +
                      queryString.stringify({
                        ...queryString.parse(location.search),
                        page: 1,
                        search: e.target.value,
                      })
                  )
                }
              />
            </div>
            <Button
              className="createButton"
              type="primary"
              onClick={() => setCreateLotModalVisible({ id: null, visible: true })}
            >
              + Yangi yaratish
            </Button>
          </div>
          <TableCustom
            className="lotsTableSize"
            data={data?.data ? data.data : []}
            columns={columns}
            loading={isLoading || isFetching}
          />
        </div>
        <PaginationGlobal onChange={pageHandle} total={total} pageSize={pageSize} />
        <LotModalCreate
          obj={createLotModalVisible}
          setVisible={setCreateLotModalVisible}
        />
        <LotsFilter visible={drawerVisible} onClose={setDrawerVisible} />
      </div>
    </>
  )
}

export default Lots
