import { Button } from "antd";
import React, { useEffect, useState } from "react";
import queryString from "query-string";
import { useLocation, useNavigate } from "react-router-dom";
import ArrowDownBlue from "../../assets/icon/ArrowDownBlue";
import ArrowUpBlueIcon from "../../assets/icon/ArrowUpBlueIcon";
import FilterIcon from "../../assets/icon/FilterIcon";
import PaginationGlobal from "../../components/pagination/PaginationGlobal";
import TableCustom from "../../components/table/Table";
import { usePaymentsAll } from "../../queries/queries";
import styles from "./payments.module.scss";
import ArrowUpIconRed from "../../assets/icon/ArrowUpIconRed";
import ArrowDownGreenIcon from "../../assets/icon/ArrowDownGreenIcon";
import TickCircleGreenIcon from "../../assets/icon/TickCircleGreenIcon";
import XCircleIcon from "../../assets/icon/XCircleIcon";
import WarningCircleIcon from "../../assets/icon/WarningCircleIcon";
import { rootPaths } from "../../routing/rootPaths";
import { MainPaths } from "../../routing/main/mainPaths";
import { UsersPaths } from "../../routing/users/usersPath";
import PaymentCreateModal from "../../components/payments/PaymentCreateModal";
import PaymentFilter from "../../components/payments/PaymentFilter";
import MainHeader from "../../components/headers/MainHeader";

const Payments = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [paymentModal, setPaymentModal] = useState({
    outcome: false,
    visible: false,
  });
  const [pageSize, setPageSize] = useState(10);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [total, setTotal] = useState(1);
  const { data, isLoading, isFetching } = usePaymentsAll();

  useEffect(() => {
    if (data) {
      setTotal(Number(data?.totalElements));
      setPageSize(
        queryString.parse(location.search).size
          ? Number(queryString.parse(location.search)?.size)
          : 10
      );
    }
  }, [data, location]);

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      render: (text: any, record: any, index: number) =>
        pageSize * Number(data?.pageable.pageNumber) + index + 1,
    },
    {
      title: "To’lov summasi",
      dataIndex: "amount",
      render: (i: number) => <span>{i?.toLocaleString()} UZS</span>,
    },
    {
      title: "To’lov turi",
      dataIndex: "paymentType",
      render: (i: string) =>
        i === "CASH" ? (
          <span className="paymentType">Naqd</span>
        ) : i === "CARD" ? (
          <span
            className="paymentType"
            style={{ color: "#228AC7", background: "#E1F2FC" }}
          >
            Karta
          </span>
        ) : (
          <span
            className="paymentType"
            style={{ color: "#FBBF26", background: "#FCFAE1" }}
          >
            Bank
          </span>
        ),
    },
    {
      title: "Foydalanuvchi",
      dataIndex: "clientName",
    },
    {
      title: "Maqsad",
      dataIndex: "goal",
      render: (i: string) =>
        i === "BALANCE" ? (
          <span style={{ color: "#FBBF26" }}>Balans</span>
        ) : i === "REFUNDED" ? (
          <span style={{ color: "#FC2E2E" }}>Qaytarish</span>
        ) : i === "AUCTION" ? (
          <span style={{ color: "#14804A" }}>Auksion</span>
        ) : (
          <span>Tuzish</span>
        ),
    },
    {
      title: "Yo’nalish",
      dataIndex: "outcome",
      render: (i: boolean) =>
        i ? (
          <span style={{ color: "#FC2E2E" }}>
            <ArrowUpIconRed /> Chiqim
          </span>
        ) : (
          <span style={{ color: "#1EB53A" }}>
            <ArrowDownGreenIcon /> Kirim
          </span>
        ),
    },
    {
      title: "To’lov vaqti",
      dataIndex: "paymentDate",
    },
    {
      title: "Holati",
      dataIndex: "paymentStatus",
      render: (i: string) =>
        i === "ACCEPTED" ? (
          <span>
            <TickCircleGreenIcon />
          </span>
        ) : i === "UNACCEPTED" ? (
          <span>
            <XCircleIcon />
          </span>
        ) : (
          <span style={{ color: "#FFAF37" }}>
            <WarningCircleIcon /> Tasdiqlash
          </span>
        ),
    },
    // {
    //   width: 50,
    //   render: (text: any, record: any) => (
    //     <Dropdown
    //       overlay={
    //         <Menu
    //           items={[
    //             {
    //               label: <span>Edit</span>,
    //               key: "0",
    //             },
    //           ]}
    //         />
    //       }
    //       trigger={["click"]}
    //     >
    //       <Button className="customWhiteCircleButton">
    //         <DotsThreeVerticalIcon />
    //       </Button>
    //     </Dropdown>
    //   ),
    // },
  ];

  const pageHandle = (page: any, size: number) => {
    navigate(
      location.pathname +
        "?" +
        queryString.stringify({
          ...queryString.parse(location.search),
          page: page,
          size: size,
        })
    );
    setPageSize(size);
  };
  return (
    <>
      <MainHeader>To’lovlar</MainHeader>
      <div style={{ margin: "16px" }}>
        <div className={styles.payments}>
          <div className={styles.payments_header}>
            <div className={styles.payments_header_left}>
              <Button
                className="filterButton"
                onClick={() => setDrawerVisible(true)}
              >
                <FilterIcon />
              </Button>
            </div>
            <div className={styles.payments_header_right}>
              <Button
                onClick={() =>
                  setPaymentModal({ outcome: false, visible: true })
                }
              >
                <ArrowUpBlueIcon /> Kirim qilish
              </Button>
              <Button
                onClick={() =>
                  setPaymentModal({ outcome: true, visible: true })
                }
              >
                <ArrowDownBlue /> Chiqim qilish
              </Button>
            </div>
          </div>
          <TableCustom
            onRow={(record: any) => {
              return {
                onClick: () =>
                  navigate(
                    rootPaths.MAIN +
                      MainPaths.USERS +
                      "/" +
                      record.clientId +
                      UsersPaths.PAYMENTS
                  ),
              };
            }}
            className="lotsTableSize"
            data={data?.content ? data.content : []}
            columns={columns}
            loading={isLoading || isFetching}
          />
        </div>
        <PaginationGlobal
          onChange={pageHandle}
          total={total}
          pageSize={pageSize}
        />
        <PaymentCreateModal obj={paymentModal} onClose={setPaymentModal} />
        <PaymentFilter visible={drawerVisible} onClose={setDrawerVisible} />
      </div>
    </>
  );
};

export default Payments;
