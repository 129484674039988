import { Button, Form, Input } from 'antd'
import LockIcon from '../../assets/icon/LockIcon'
import SmsIcon from '../../assets/icon/SmsIcon'
import inner_shapes from '../../assets/img/inner_shapes.png'
import login_text from '../../assets/img/login_text.png'
import { useSignIn } from '../../queries/mutation'
import styles from './login.module.scss'

const Login = () => {
  const login = useSignIn()

  // useEffect(() => {
  //   const token = LocalStorage.get(TOKEN_KEY);
  //   const user = LocalStorage.get(USER_KEY);
  //   if (token && user) {
  //     signInSuccess(user as User);
  //   }
  // }, []);

  const onFinish = (values: any) => {
    login.mutate(values)
  }

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo)
  }

  return (
    <div className={styles.login}>
      <div className={styles.login_left}>
        <img src={inner_shapes} alt="inner_shapes" />
        <img src={login_text} alt="login_text" />
      </div>
      <div className={styles.login_right}>
        <Form
          name="basic"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <h1>Tizimga kirish</h1>
          <Form.Item
            name="username"
            rules={[{ required: true, message: 'Usernameni kiriting!' }]}
          >
            <Input
              className={styles.loginInput}
              prefix={<SmsIcon />}
              placeholder="Loginni kiriting"
            />
          </Form.Item>

          <Form.Item
            name="password"
            rules={[{ required: true, message: 'Passwordni kiriting!' }]}
          >
            <Input.Password
              className={styles.loginInput}
              prefix={<LockIcon />}
              placeholder="Parolni kiriting"
            />
          </Form.Item>

          <p>Parolni unitdingizmi?</p>

          <Button type="primary" htmlType="submit">
            KIRISH
          </Button>
        </Form>
      </div>
    </div>
  )
}

export default Login
