import BuildingsIcon from '../../assets/icon/BuildingsIcon'
import LightingIcon from '../../assets/icon/LightingIcon'
import SketchLogoIcon from '../../assets/icon/SketchLogoIcon'
import UserCircleGearIcon from '../../assets/icon/UserCircleGearIcon'
import { MainPaths } from '../../routing/main/mainPaths'
import { rootPaths } from '../../routing/rootPaths'
import { SettingsPaths } from '../../routing/setting/settingPath'
import { Permissions } from '../../utils/constants/permissions'

const fullSettingLink = [
  {
    to: rootPaths.MAIN + MainPaths.SETTINGS + SettingsPaths.AUKSION_SETTING,
    icon: LightingIcon,
    name: 'Auksion sozlamalari',
    permission: Permissions.ROLE_SUPPER_ADMIN,
  },
  {
    to: rootPaths.MAIN + MainPaths.SETTINGS + SettingsPaths.ADMIN_SETTING,
    icon: UserCircleGearIcon,
    name: 'Admin sozlamalari',
    permission: Permissions.ROLE_SUPPER_ADMIN,
  },
  {
    to: rootPaths.MAIN + MainPaths.SETTINGS + SettingsPaths.KOMPANY_SETTING,
    icon: BuildingsIcon,
    name: 'Kompaniya sozlamalari',
    permission: Permissions.ROLE_SUPPER_ADMIN,
  },
  {
    to: rootPaths.MAIN + MainPaths.SETTINGS + SettingsPaths.KATEGORY_SETTING,
    icon: SketchLogoIcon,
    name: 'Kategoriya sozlamalari',
    permission: Permissions.ROLE_SUPPER_ADMIN,
  },
]

// const settingLink: typeof fullSettingLink = []
const settingLink: typeof fullSettingLink = fullSettingLink

// if (typeof user === 'object' && user) {
//   fullSettingLink.forEach((link) => {
//     if (link.permission) {
//       if (user.role.permissions.includes(link.permission)) {
//         settingLink.push(link)
//       }
//     } else {
//       settingLink.push(link)
//     }
//   })
// }

export default settingLink
