import ContractIcon from '../../../assets/icon/ContractIcon'
import LotsIcon from '../../../assets/icon/LotsIcon'
import PaymentsIcon from '../../../assets/icon/PaymentsIcon'
import ProtocolIcon from '../../../assets/icon/ProtocolIcon'
import SettingIcon from '../../../assets/icon/SettingIcon'
import StatistikaIcon from '../../../assets/icon/StatistikaIcon'
import UsersIcon from '../../../assets/icon/UsersIcon'
import { MainPaths } from '../../../routing/main/mainPaths'
import { rootPaths } from '../../../routing/rootPaths'

export const navbarLinks = [
  {
    name: 'Lotlar',
    icon: <LotsIcon />,
    path: rootPaths.MAIN + MainPaths.INDEX,
  },
  // {
  //   name: 'Statistika',
  //   icon: <StatistikaIcon />,
  //   path: rootPaths.MAIN + MainPaths.STATISTIKA,
  // },
  {
    name: 'QR-kodli bayonnomalar',
    icon: <ProtocolIcon />,
    path: rootPaths.MAIN + MainPaths.PROTOCOLS,
  },
  {
    name: 'Foydalanuvchilar',
    icon: <UsersIcon />,
    path: rootPaths.MAIN + MainPaths.USERS,
  },
  {
    name: "To'lovlar",
    icon: <PaymentsIcon />,
    path: rootPaths.MAIN + MainPaths.PAYMENTS,
  },
  {
    name: 'Shartnomalar',
    icon: <ContractIcon />,
    path: rootPaths.MAIN + MainPaths.CONTRACT,
  },
  {
    name: 'Sozlamalar',
    icon: <SettingIcon />,
    path: rootPaths.MAIN + MainPaths.SETTINGS,
  },
]
