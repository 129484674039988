import { Button, Dropdown, Form, Input, message, Modal, Select, Spin } from 'antd'
import React, { FC, useEffect, useState } from 'react'
import CheveronRightIcon from '../../assets/icon/CheveronRightIcon'
import SearchIcon from '../../assets/icon/SearchIcon'
import UserIcon from '../../assets/icon/UserIcon'
import { useMakePayment } from '../../queries/mutation'
import { useClientAll } from '../../queries/queries'

interface PaymentCreateModalProps {
  obj: {
    outcome: boolean
    visible: boolean
  }
  onClose: React.Dispatch<
    React.SetStateAction<{
      outcome: boolean
      visible: boolean
    }>
  >
}

const PaymentCreateModal: FC<PaymentCreateModalProps> = ({ obj, onClose }) => {
  const [form] = Form.useForm()
  const makePayment = useMakePayment()
  const [searchClient, setSearchClient] = useState('')
  const [searchVisible, setSearchVisible] = useState(false)
  const [clientObj, setClientObj] = useState<any>({
    id: null,
    name: '',
    phoneNumber: '',
  })
  const {
    data: clients,
    isLoading: clientsIsLoading,
    isFetching: clientsIsFetching,
  } = useClientAll(1, 5, searchClient)
  const handleCansel = () => {
    onClose({ outcome: false, visible: false })
  }

  const onFinish = (values: any) => {
    if (clientObj.id) {
      if (obj.outcome) {
        makePayment.mutate({
          data: {
            amount: values.amount.replace(/\s/g, ''),
            clientId: clientObj.id,
            paymentType: values.paymentType,
          },
          type: 'outcome',
        })
      } else {
        makePayment.mutate({
          data: {
            amount: values.amount.replace(/\s/g, ''),
            clientId: clientObj.id,
            paymentType: values.paymentType,
          },
          type: 'income',
        })
      }
    } else {
      message.info('Klientni tanlang!')
    }
  }

  useEffect(() => {
    if (makePayment.isSuccess) {
      onClose({ outcome: false, visible: false })
    }
    // eslint-disable-next-line
  }, [makePayment.isSuccess])
  return (
    <Modal
      title={obj.outcome ? 'Chiqim' : 'Kirim'}
      visible={obj.visible}
      onCancel={handleCansel}
      footer={false}
      width={368}
      wrapClassName="paymentCreateModal"
    >
      <Form layout="vertical" onFinish={onFinish} form={form}>
        <Dropdown
          visible={searchVisible}
          onVisibleChange={(visible: boolean) => setSearchVisible(visible)}
          overlay={
            <div className="paymentCreateModal_users">
              <Spin spinning={clientsIsLoading || clientsIsFetching}>
                {clients?.data?.map((client) => (
                  <div
                    className="paymentCreateModal_users_item"
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      form.setFieldsValue({ search: client.name })
                      setSearchClient(client.name)
                      setSearchVisible(false)
                      setClientObj({
                        id: client.id,
                        name: client.name,
                        phoneNumber: client.phoneNumber,
                      })
                    }}
                  >
                    <span>
                      <UserIcon />
                    </span>
                    <div>
                      <h1>{client.name}</h1>
                      <p>{client.phoneNumber}</p>
                    </div>
                  </div>
                ))}
              </Spin>
            </div>
          }
        >
          <Form.Item name={'search'} rules={[{ required: true }]}>
            <Input
              className="searchInput"
              prefix={<SearchIcon />}
              placeholder="Qidirish..."
              value={searchClient}
              onChange={(e) => {
                setSearchClient(e.target.value)
                if (e.target.value === '') {
                  setClientObj({ id: null, name: '', phoneNumber: '' })
                  setSearchVisible(false)
                } else {
                  setSearchVisible(true)
                }
              }}
              autoComplete="off"
            />
          </Form.Item>
        </Dropdown>
        {clientObj.id && (
          <div className="paymentCreateModal_users_item">
            <span>
              <UserIcon />
            </span>
            <div>
              <h1>{clientObj.name}</h1>
              <p>{clientObj.phoneNumber}</p>
            </div>
          </div>
        )}
        <Form.Item
          label="To’lov turi"
          name={'paymentType'}
          rules={[{ required: true }]}
        >
          <Select
            className="customSelect"
            style={{ width: 126 }}
            suffixIcon={<CheveronRightIcon />}
          >
            <Select.Option value="CASH">Naqd</Select.Option>
            <Select.Option value="CARD">Kart</Select.Option>
            <Select.Option value="BANK">Bank</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          label="To’lov summasi"
          name={'amount'}
          rules={[{ required: true }]}
        >
          <Input
            className="customInput"
            onKeyUp={(e: any) => {
              let val = e.target.value.replace(/[^0-9]/g, '')
              let valBoom = val.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
              e.target.value = valBoom
              form.setFieldsValue({ amount: valBoom })
            }}
          />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            className="saveButton"
            htmlType="submit"
            style={{ float: 'right' }}
          >
            Tasdiqlash
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default PaymentCreateModal
