import { Button, Form, Input, Select } from 'antd'
import { FormInstance } from 'antd/es/form/Form'
import dayjs from 'dayjs'
import React, { ChangeEvent, useState } from 'react'
import ReactInputMask from 'react-input-mask'
import { LoadingOutlined } from '@ant-design/icons'

import CalendarIcon from '../../assets/icon/CalendarIcon'
import SearchIcon from '../../assets/icon/SearchIcon'
import { useGetInfoWithPassport } from '../../queries/mutation'
import CustomDatePicker from '../../utils/helper/CustomDatePicker'
import { getInfoWithPassportTypes } from '../../utils/constants/GetInfoWithPassportTypes'
import { personTypes } from '../../utils/constants/PersonTypes'

type props = {
  formInstance: FormInstance
  onFinishErr: string[]
  setOnFinishErr: (errList: []) => void
  type: string
  salerType: string
  customerType: string
  setSalerType: (type: string) => void
  setCustomerType: (type: string) => void
}

const GetInfoWithPassport: React.FC<props> = ({
  formInstance,
  onFinishErr,
  setOnFinishErr,
  type,
  customerType,
  salerType,
  setCustomerType,
  setSalerType,
}) => {
  const { Option } = Select
  const { Item } = Form
  const getInfoWithPassport = useGetInfoWithPassport(formInstance, type)
  const [passportErr, setPassportErr] = useState(false)

  // title
  const title = () => {
    switch (type) {
      case getInfoWithPassportTypes.SALER:
        return 'Mol-mulk sotuvchi'
      case getInfoWithPassportTypes.CUSTOMER:
        return 'Sotib oluvchi ma’lumotlari'
      default:
        return ''
    }
  }

  // change user type
  const changeUserType = (value: string) => {
    switch (type) {
      case getInfoWithPassportTypes.SALER:
        setSalerType(value)
        break
      case getInfoWithPassportTypes.CUSTOMER:
        setCustomerType(value)
        break
    }

    setOnFinishErr([])
  }

  // change passport seria
  const changePassportSeria = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.value?.includes('_')) {
      document.getElementById(`${type}_passport_number`)?.focus()
    }
  }

  // change passport number
  const changePassportNumber = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.value?.includes('_')) {
      document.getElementById(`${type}_birthday`)?.focus()
    }
  }

  // get info
  const getInfo = () => {
    const data = {
      passportSeria: formInstance
        .getFieldValue(`${type}_passport_seria`)
        ?.toUpperCase(),
      passportNumber: formInstance.getFieldValue(`${type}_passport_number`),
      birthday: dayjs(
        formInstance.getFieldValue(`${type}_birthday`),
        'DD.MM.YYYY'
      ).format('YYYY-MM-DD'),
    }

    if (data.birthday && data.passportNumber && data.passportSeria) {
      getInfoWithPassport.mutate(data)
      setPassportErr(false)
    } else {
      setPassportErr(true)
    }
  }

  // add error classname
  const addErrClassName = (itemName: string) => {
    if (onFinishErr.includes(itemName)) {
      return 'error_input'
    }
  }

  // user type value
  const userTypeValue = () => {
    switch (type) {
      case getInfoWithPassportTypes.SALER:
        return salerType
      case getInfoWithPassportTypes.CUSTOMER:
        return customerType
      default:
        return undefined
    }
  }

  // person label
  const personLabel = () => {
    switch (type) {
      case getInfoWithPassportTypes.SALER:
        return 'Sotuvchi'
      case getInfoWithPassportTypes.CUSTOMER:
        return 'Xaridor'
    }
  }

  return (
    <div className="saler_cont">
      <div className="saler_cont_header">
        <h4 className="little_title">{title()}</h4>
        <Select value={userTypeValue()} onChange={changeUserType}>
          <Option value={personTypes.PHYSICAL}>Jismoniy</Option>
          <Option value={personTypes.LEGAL}>Yuridik</Option>
        </Select>
      </div>
      {userTypeValue() === personTypes.PHYSICAL ? (
        <>
          <div className="passport_cont">
            <Item
              name={`${type}_passport_seria`}
              rules={[{ required: true }]}
              className={`${passportErr ? 'error_input' : ''} ${addErrClassName(
                `${type}_passport_seria`
              )}`}
            >
              <ReactInputMask
                mask="aa"
                className="ant-input passport_seria"
                onChange={changePassportSeria}
              />
            </Item>
            <Item
              name={`${type}_passport_number`}
              label="Pasport seriyasi va raqami"
              className={`${passportErr ? 'error_input' : ''} ${addErrClassName(
                `${type}_passport_number`
              )} passpart_number_item`}
              rules={[{ required: true }]}
            >
              <ReactInputMask
                mask="9999999"
                className="ant-input passport_number"
                id={`${type}_passport_number`}
                onChange={changePassportNumber}
              />
            </Item>
            <div className="customDatePickerCont">
              <Item
                name={`${type}_birthday`}
                label="Tug'ilgan sana"
                rules={[{ required: true }]}
                className={`${passportErr ? 'error_input' : ''} ${addErrClassName(
                  `${type}_birthday`
                )}`}
              >
                <ReactInputMask
                  className="ant-input"
                  mask="99.99.9999"
                  id={`${type}_birthday`}
                />
              </Item>
              <span className="calendar_icon">
                <CustomDatePicker
                  formInstance={formInstance}
                  formItemName={`${type}_birthday`}
                >
                  <CalendarIcon />
                </CustomDatePicker>
              </span>
            </div>
            <Button type="primary" className="searchBtn" onClick={getInfo}>
              {getInfoWithPassport.isLoading ? (
                <LoadingOutlined style={{ fontSize: 16 }} spin />
              ) : (
                <SearchIcon />
              )}
              <span>Shaxs ma'lumotlarini olish</span>
            </Button>
          </div>
          <div className="physicalSalerCont">
            <Item
              label={personLabel()}
              name={type}
              className={addErrClassName(type)}
              rules={[{ required: true }]}
            >
              <Input />
            </Item>
            <div className="d-f">
              <div className="customDatePickerCont">
                <Item
                  name={`${type}_passport_given_date`}
                  label="Pasport berilgan sana"
                  rules={[{ required: true }]}
                  className={addErrClassName(`${type}_passport_given_date`)}
                >
                  <ReactInputMask className="ant-input" mask="99.99.9999" />
                </Item>
                <span className="calendar_icon">
                  <CustomDatePicker
                    formInstance={formInstance}
                    formItemName={`${type}_passport_given_date`}
                  >
                    <CalendarIcon />
                  </CustomDatePicker>
                </span>
              </div>
              <Item
                label="Pasport kim tomonidan berilgan"
                name={`${type}_passport_given_place`}
                className="passport_given_place"
                rules={[{ required: true }]}
              >
                <Input />
              </Item>
            </div>
            <Item
              label="Manzil"
              name={`${type}_address`}
              rules={[{ required: true }]}
            >
              <Input />
            </Item>
          </div>
        </>
      ) : (
        <div className="legalSalerCont">
          <Item
            name={`${type}_company_name`}
            label="Tashkilot nomi"
            rules={[{ required: true }]}
          >
            <Input />
          </Item>
          <Item
            name={`${type}_company_director`}
            label="Tashkilot rahbari"
            rules={[{ required: true }]}
          >
            <Input />
          </Item>
          <div className="doubleInput">
            <Item
              label="Shaxsiy hisob raqam"
              name={`${type}_account_number`}
              rules={[{ required: true }]}
              className={addErrClassName(`${type}_account_number`)}
            >
              <ReactInputMask mask="9999999999999999999" className="ant-input" />
            </Item>
            <Item
              label="MFO"
              name={`${type}_mfo`}
              rules={[{ required: true }]}
              className={addErrClassName(`${type}_mfo`)}
            >
              <ReactInputMask mask="99999" className="ant-input" />
            </Item>
          </div>
          <div className="doubleInput">
            <Item
              label="INN"
              name={`${type}_inn`}
              rules={[{ required: true }]}
              className={addErrClassName(`${type}_inn`)}
            >
              <ReactInputMask mask="999999999" className="ant-input" />
            </Item>
            <Item
              label="Bank nomi"
              name={`${type}_bank`}
              rules={[{ required: true }]}
            >
              <Input />
            </Item>
          </div>
          <Item
            label="Tashkilot manzili"
            name={`${type}_company_address`}
            rules={[{ required: true }]}
          >
            <Input />
          </Item>
        </div>
      )}
    </div>
  )
}

export default GetInfoWithPassport
