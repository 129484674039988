import { Form, FormInstance, Input } from 'antd'
import React from 'react'
import CurrencyInput from 'react-currency-input-field'
import ReactInputMask from 'react-input-mask'
import CalendarIcon from '../../assets/icon/CalendarIcon'
import CustomDatePicker from '../../utils/helper/CustomDatePicker'

type props = {
  onFinishErr: string[]
  amount: string | undefined
  setAmount: (amount: string | undefined) => void
  formInstance: FormInstance
}

const ProductInfo: React.FC<props> = ({
  onFinishErr,
  amount,
  setAmount,
  formInstance,
}) => {
  const { Item } = Form

  // add error classname
  const addErrClassName = (itemName: string) => {
    if (onFinishErr.includes(itemName)) {
      return 'error_input'
    }
  }

  // change amount
  const changeAmount = (value: string | undefined) => {
    setAmount(value)
  }

  return (
    <div className="productInfoCont">
      <h4 className="little_title">Mol-mulk ma'lumotlari</h4>
      <div className="price_date">
        <Item
          rules={[{ required: true }]}
          // name="productPrice"
          label="Mol-mulk narxi (so'm)"
          className={addErrClassName('productPrice')}
        >
          <CurrencyInput
            className="ant-input"
            autoComplete="off"
            value={amount}
            onValueChange={changeAmount}
            allowDecimals={false}
            allowNegativeValue={false}
          />
        </Item>
        <div className="customDatePickerCont">
          <Item
            name="product_date"
            label="To'lab berish sanasi"
            rules={[{ required: true }]}
            className={addErrClassName('product_date')}
          >
            <ReactInputMask className="ant-input" mask="99.99.9999" />
          </Item>
          <span className="calendar_icon">
            <CustomDatePicker
              formInstance={formInstance}
              formItemName="product_date"
            >
              <CalendarIcon />
            </CustomDatePicker>
          </span>
        </div>
      </div>
      <Item
        name="product_description"
        label="Mulk tavsifi"
        rules={[{ required: true }]}
      >
        <Input.TextArea />
      </Item>
      <Item
        name="product_category"
        label="Mahsulot kategoriyasi"
        rules={[{ required: true }]}
      >
        <Input />
      </Item>
    </div>
  )
}

export default ProductInfo
