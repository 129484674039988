import React from "react";
import { NavLink, useParams } from "react-router-dom";
import usersLink from "../../components/users/usersLink";
import { MainPaths } from "../../routing/main/mainPaths";
import { rootPaths } from "../../routing/rootPaths";
import UsersRoutes from "../../routing/users/UsersRouter";
import styles from "./users.module.scss";

const Users = () => {
  const params = useParams();
  return (
    <div className={styles.users}>
      <div className={styles.users_navbar}>
        {usersLink.map((item, index) => {
          return (
            <NavLink
              to={rootPaths.MAIN + MainPaths.USERS + "/" + params?.id + item.to}
              key={index}
              className="usersLink"
            >
              {item.icon && <item.icon />}
              <span>{item.name}</span>
            </NavLink>
          );
        })}
      </div>
      <div className={styles.users_container}>
        <UsersRoutes />
      </div>
    </div>
  );
};

export default Users;
