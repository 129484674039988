import { useQuery } from 'react-query'
import { useLocation } from 'react-router-dom'
import { $api, BASE_URL } from '../service/RequestService'
import {
  adminControllerEndpoints,
  authEndpoints,
  cityControllerEndpoints,
  clientEndpoints,
  companyControllerEndpoints,
  contractEndpoints,
  filesEndpoints,
  lotControllerEndpoints,
  paymentEndpoints,
  petitionEndpoints,
  privateContractEndpoints,
  regionControllerEndpoints,
  settingCategoryEndpoints,
} from '../utils/constants/apis'
import { queryNames } from '../utils/constants/queryNames'
import { CategoryParents } from '../utils/models/CategoryParents'
import { CategorySubs } from '../utils/models/CategorySubs'
import { CityAll } from '../utils/models/CityAll'
import { Client, ClientId } from '../utils/models/Client'
import { ClientID } from '../utils/models/ClientId'
import { CompanySettingCreate } from '../utils/models/CompanySetting'
import { Protocols } from '../utils/models/Protocols'
import { FileObject } from '../utils/models/File'
import { Pagination } from '../utils/models/Pagination'
import { PaymentOfUsers } from '../utils/models/PaymentsOfUsers'
import { PetitionClientLots } from '../utils/models/PetitionClientLots'
import { baseErrorHandler } from './baseErrorHandler'
import { PrivateContracts } from '../utils/models/PrivateContracts'
import { OneLot } from '../utils/models/Lot'

//get premium originator
export function useKategorySetting() {
  return useQuery<Pagination<CategoryParents>>(
    [queryNames.CATEGORY_PARENTS],
    async () => {
      const res = await $api.get(settingCategoryEndpoints.CATEGORY_PARENTS)
      return res.data.data
    },
    {
      onError: baseErrorHandler,
      refetchOnWindowFocus: false,
      refetchInterval: Infinity,
      retry: false,
    }
  )
}

export function useSubKategorySetting(id: number) {
  return useQuery<Pagination<CategorySubs>>(
    [queryNames.CATEGORY_SUB_CATEGORY, id],
    async () => {
      const res = await $api.get(
        settingCategoryEndpoints.CATEGORY_SUB_CATEGORY + '/' + id
      )
      return res.data.data
    },
    {
      enabled: !!id,
      onError: baseErrorHandler,
      refetchOnWindowFocus: false,
      refetchInterval: Infinity,
      retry: false,
    }
  )
}

// file

export function useFile(id: number) {
  return useQuery<FileObject>(
    [queryNames.FILE, id],
    async () => {
      const res = await $api.get(filesEndpoints.GET_INDEX + '/' + id)
      return res.data.data
    },
    {
      enabled: !!id,
      onError: baseErrorHandler,
      refetchOnWindowFocus: false,
      refetchInterval: Infinity,
      retry: false,
    }
  )
}

// city

export function useCityAll() {
  return useQuery<Pagination<CityAll>>(
    [queryNames.CITY_ALL],
    async () => {
      const res = await $api.get(
        cityControllerEndpoints.CITY_ALL + '?page=1&size=100'
      )
      return res.data.data
    },
    {
      // onError: baseErrorHandler,
      refetchOnWindowFocus: false,
      refetchInterval: Infinity,
      retry: false,
    }
  )
}

// region

export function useRegion(id: number) {
  return useQuery<Pagination<CityAll>>(
    [queryNames.REGION_PUBLIC_CITY, id],
    async () => {
      const res = await $api.get(
        regionControllerEndpoints.REGION_PUBLIC_CITY + '/' + id
      )
      return res.data.data
    },
    {
      enabled: !!id,
      // onError: baseErrorHandler,
      refetchOnWindowFocus: false,
      refetchInterval: Infinity,
      retry: false,
    }
  )
}

// company

export function useCompany(id: number = 1) {
  return useQuery<CompanySettingCreate>(
    [queryNames.COMPANY_ID, id],
    async () => {
      const res = await $api.get(companyControllerEndpoints.COMPANY_ID + '/' + id)
      return res.data.data
    },
    {
      enabled: !!id,
      onError: baseErrorHandler,
      refetchOnWindowFocus: false,
      refetchInterval: Infinity,
      retry: false,
    }
  )
}

// admin

export function useAdminAll(page: number = 1, size: number = 10) {
  return useQuery<Pagination<any>>(
    [queryNames.ADMIN_GET_ALL_ADMIN, page, size],
    async () => {
      const res = await $api.get(
        adminControllerEndpoints.GET_ALL_ADMIN + `?page=${page}&size=${size}`
      )
      return res.data.data
    },
    {
      onError: baseErrorHandler,
      refetchOnWindowFocus: false,
      refetchInterval: Infinity,
      retry: false,
    }
  )
}

// lot
export function useLots() {
  const location = useLocation()
  return useQuery<Pagination<any>>(
    [queryNames.LOTS, location.search],
    async () => {
      const res = await $api.post(lotControllerEndpoints.LOTS_GET + location.search)
      return res.data.data
    },
    {
      onError: baseErrorHandler,
      refetchOnWindowFocus: false,
      refetchInterval: Infinity,
      retry: false,
    }
  )
}

// one lot
export function useGetOneLot(id: number | null) {
  return useQuery<OneLot>(
    [queryNames.ONE_LOT, id],
    async () => {
      const res = await $api.get(`${lotControllerEndpoints.LOT_GET}/${id}`)
      return res.data.data
    },
    {
      onError: baseErrorHandler,
      refetchOnWindowFocus: false,
      enabled: !!id,
    }
  )
}

// lot
export function useClientAll(
  page: number = 1,
  size: number = 5,
  search: string = '',
  type?: string
) {
  let url = authEndpoints.GET_ALL_CLIENT

  if (type) {
    url += `/${type?.toLowerCase()}`
  }

  url += `?page=${page}&size=${size}&search=${search}`

  return useQuery<Pagination<Client>>(
    [queryNames.GET_ALL_CLIENT, page, size, search],
    async () => {
      const res = await $api.get(url)
      return res.data.data
    },
    {
      onError: baseErrorHandler,
      refetchOnWindowFocus: false,
      refetchInterval: Infinity,
      retry: false,
    }
  )
}

// get all confirmed
export function useClientAllConfirmed(search: string = '') {
  let url = authEndpoints.ALL_CONFIRMED_CLIENTS

  url += `?page=1&size=5&search=${search}`

  return useQuery<Pagination<Client>>(
    [queryNames.GET_ALL_CLIENT_CONFIRMED, search],
    async () => {
      const res = await $api.get(url)
      return res.data.data
    },
    {
      onError: baseErrorHandler,
      refetchOnWindowFocus: false,
      refetchInterval: Infinity,
      retry: false,
    }
  )
}

// client

export function useClient(id: number) {
  return useQuery<ClientId>(
    [queryNames.CLIENT, id],
    async () => {
      const res = await $api.get(clientEndpoints.CLIENT + '/' + id)
      return res.data.data
    },
    {
      enabled: !!id,
      onError: baseErrorHandler,
      refetchOnWindowFocus: false,
      refetchInterval: Infinity,
      retry: false,
    }
  )
}

// payments of users
export function usePaymentsOfUsers(
  id: string | undefined,
  page: number,
  size: number
) {
  return useQuery<PaymentOfUsers>([queryNames.USERS_PAYMENTS], async () => {
    const res = await $api.post(
      `${paymentEndpoints.ALL_PAYMENT}/${id}?page=${page}&size=${size}`
    )
    return res.data.data
  })
}

export function usePaymentsAll() {
  const location = useLocation()
  return useQuery<PaymentOfUsers>(
    [queryNames.GET_ALL_PAYMENT, location.search],
    async () => {
      const res = await $api.post(paymentEndpoints.GET_ALL_PAYMENT + location.search)
      return res.data.data
    }
  )
}

//clientId

export function useClientId(id: number) {
  return useQuery<ClientID>(
    [queryNames.CLIENT_ID, id],
    async () => {
      const res = await $api.get(clientEndpoints.CLIENT_ID + '/' + id)
      return res.data.data
    },
    {
      enabled: !!id,
      onError: baseErrorHandler,
      refetchOnWindowFocus: false,
      refetchInterval: Infinity,
      retry: false,
    }
  )
}

export function usePetitonClientLots(
  page: number = 1,
  size: number = 10,
  id: number
) {
  return useQuery<PetitionClientLots[]>(
    [queryNames.PETITION_CLIENT_LOTS, page, size, id],
    async () => {
      const res = await $api.get(
        petitionEndpoints.PETITION_CLIENT_LOTS +
          `/${id}/lots?page=${page}&size=${size}`
      )
      return res.data.data
    },
    {
      enabled: !!id,
      onError: baseErrorHandler,
      refetchOnWindowFocus: false,
      refetchInterval: Infinity,
      retry: false,
    }
  )
}

// all contracts
export function useGetAllProtocols() {
  const location = useLocation()

  return useQuery<Protocols>(
    [queryNames.CONTRACTS, location.search],
    async () => {
      const res = await $api.get(
        contractEndpoints.GET_ALL_CONTRACT + location.search
      )
      return res.data.data
    },
    {
      onError: baseErrorHandler,
    }
  )
}

// private contracts
export function useGetPrivateContracts() {
  const location = useLocation()

  return useQuery<PrivateContracts>(
    [queryNames.CONTRACTS, location.search],
    async () => {
      const res = await $api.get(privateContractEndpoints.INDEX + location.search)
      return res.data.data
    },
    {
      onError: baseErrorHandler,
    }
  )
}

// get path of images
export function useGetImagesPath(
  images: { imageId: number; step: number }[] | undefined,
  setItems: (items: { step: string; path: string; id: null | number }[]) => void,
  items: { step: string; path: string; id: null | number }[]
) {
  return useQuery(
    [queryNames.FILES_PATH, images],
    async () => {
      if (images && images.length > 0) {
        images.forEach(async (image) => {
          const res = await $api.get(`${filesEndpoints.GET_INDEX}/${image.imageId}`)
          let newImages = items
          newImages.forEach((item) => {
            if (image.step === +item.step) {
              item.path = BASE_URL + res?.data?.data?.absolutePath
              item.id = image.imageId
            }
          })
          setItems(newImages)
        })
      }
    },
    {
      enabled: images && images.length > 0,
      refetchOnWindowFocus: false,
      onError: baseErrorHandler,
    }
  )
}

// get path of docs
export function useGetDocsPath(
  docs: number[] | undefined,
  setFiles: (files: FileObject[]) => void
) {
  return useQuery(
    [queryNames.FILES_PATH, docs],
    async () => {
      if (docs && docs.length > 0) {
        let newDocs: FileObject[] = []
        docs.forEach(async (doc) => {
          const res = await $api.get(`${filesEndpoints.GET_INDEX}/${doc}`)
          newDocs = [...newDocs, res.data.data]
          setFiles(newDocs)
        })
      }
    },
    {
      enabled: docs && docs?.length > 0,
      refetchOnWindowFocus: false,
      retry: 0,
      staleTime: Infinity,
      onError: baseErrorHandler,
    }
  )
}
