import React from "react";
import { Route, Routes } from "react-router-dom";
import usersRoutes from "./usersRoutes";

const UsersRoutes = () => {
  return (
    <Routes>
      {usersRoutes.map((route, index) => (
        <Route
          key={index}
          path={route.exact ? route.path : `${route.path}/*`}
          element={<route.component />}
        />
      ))}
      <Route path={"*"} element={""} />
    </Routes>
  );
};

export default UsersRoutes;
