import { Button, Table } from 'antd'
import React, { ReactElement, useState } from 'react'
import { useParams } from 'react-router-dom'

import { usePaymentsOfUsers } from '../../../queries/queries'
import { paymentStatus } from '../../../utils/constants/paymentStatus'
import { paymentMethods } from '../../../utils/constants/paymentMethods'
import { OnePaymentType } from '../../../utils/models/PaymentsOfUsers'
import AcceptIcon from '../../../assets/icon/AcceptIcon'
import UnacceptIcon from '../../../assets/icon/UnacceptIcon'
import ArrowUp from '../../../assets/icon/ArrowUp'

import styles from './UsersPayments.module.scss'
import MakingPaymentModal from './MakingPaymentModal'
import { paymentTypes } from '../../../utils/constants/paymentTypes'
import PaginationGlobal from '../../../components/pagination/PaginationGlobal'

const UsersPayments = () => {
  const { Column } = Table
  const [page, setPage] = useState(1)
  const [size] = useState(20)
  const params = useParams()
  const [modalVisible, setModalVisible] = useState(false)
  const [paymentType, setPaymentType] = useState('')
  const { data, isLoading } = usePaymentsOfUsers(params.id, page, size)

  // payment method
  const paymentMethod = (method: string) => {
    let result = ''

    switch (method) {
      case paymentMethods.BANK:
        result = 'Bank'
        break
      case paymentMethods.CASH:
        result = 'Naqd'
        break
      case paymentMethods.CARD:
        result = 'Karta'
        break
      case paymentMethods.AUCTION:
        result = 'Auksion'
        break
      case paymentMethods.BALANCE:
        result = 'Balans'
    }
    return <span>{result}</span>
  }

  // payment status
  const paymentStatusFunc = (status: string) => {
    let result: string | ReactElement = ''

    switch (status) {
      case paymentStatus.ACCEPTED:
        result = <AcceptIcon />
        break
      case paymentStatus.UNACCEPTED:
        result = <UnacceptIcon />
        break
      case paymentStatus.WAITING:
        result = 'Kutilmoqda'
        break
    }

    return <span>{result}</span>
  }

  // make payment
  const makePayment = (type: string) => {
    setPaymentType(type)
    setModalVisible(true)
  }

  // handle page
  const handlePage = (page: number) => {
    setPage(page)
  }

  return (
    <>
      <div className={styles.payments}>
        <div className={styles.payments_header}>
          <Button onClick={() => makePayment(paymentTypes.INCOME)}>
            <ArrowUp />
            <span>Kirim qilish</span>
          </Button>
          <Button onClick={() => makePayment(paymentTypes.OUTCOME)}>
            <ArrowUp />
            <span>Chiqim qilish</span>
          </Button>
        </div>
        <Table
          className={styles.payments_table}
          dataSource={data?.content}
          loading={isLoading}
          pagination={false}
        >
          <Column
            title="#"
            render={(_, __, index: number) => <span>{index + 1}</span>}
          />
          <Column
            title="To'lov summasi"
            align="right"
            render={(record: OnePaymentType) => (
              <span>{record.amount.toLocaleString()} UZS</span>
            )}
          />
          <Column
            title="To'lov turi"
            align="center"
            render={(record: OnePaymentType) => paymentMethod(record.paymentType)}
          />
          <Column
            title="Yo'nalish"
            align="center"
            render={(record: OnePaymentType) => (
              <span>{record.outcome ? 'Chiqim' : 'Kirim'}</span>
            )}
          />
          <Column title="To'lov vaqti" align="center" dataIndex="paymentDate" />
          <Column
            title="Holati"
            align="center"
            render={(record: OnePaymentType) =>
              paymentStatusFunc(record.paymentStatus)
            }
          />
        </Table>
        <div className="d-f j-c-c">
          <PaginationGlobal
            total={data?.totalElements}
            pageSize={size}
            onChange={handlePage}
          />
        </div>
      </div>
      <MakingPaymentModal
        visible={modalVisible}
        setVisible={setModalVisible}
        type={paymentType}
      />
    </>
  )
}

export default UsersPayments
