import React from "react";

const CheveronRightIcon = () => {
  return (
    <svg
      width="11"
      height="12"
      viewBox="0 0 11 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.91109 4.5111C3.12588 4.29631 3.47412 4.29631 3.68891 4.5111L5.5 6.32219L7.31109 4.5111C7.52588 4.29631 7.87412 4.29631 8.08891 4.5111C8.3037 4.72589 8.3037 5.07413 8.08891 5.28891L5.88891 7.48891C5.67412 7.7037 5.32588 7.7037 5.11109 7.48891L2.91109 5.28891C2.6963 5.07413 2.6963 4.72589 2.91109 4.5111Z"
        fill="#9CA3AF"
      />
    </svg>
  );
};

export default CheveronRightIcon;
