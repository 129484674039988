import React from 'react'

const DragIcon = () => {
  return (
    <svg
      width="11"
      height="7"
      viewBox="0 0 11 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.83906 1.64688C1.83906 1.13903 1.42737 0.727347 0.919529 0.727347C0.411687 0.727347 -6.23924e-08 1.13903 -4.01939e-08 1.64688C-1.79954e-08 2.15472 0.411687 2.56641 0.919529 2.56641C1.42737 2.56641 1.83906 2.15472 1.83906 1.64688Z"
        fill="black"
      />
      <path
        d="M1.83906 5.33438C1.83906 4.82653 1.42737 4.41485 0.919529 4.41485C0.411687 4.41485 -6.23924e-08 4.82653 -4.01939e-08 5.33438C-1.79954e-08 5.84222 0.411687 6.25391 0.919529 6.25391C1.42737 6.25391 1.83906 5.84222 1.83906 5.33438Z"
        fill="black"
      />
      <path
        d="M6.34351 1.64688C6.34351 1.13903 5.93183 0.727347 5.42398 0.727347C4.91614 0.727347 4.50446 1.13903 4.50446 1.64688C4.50446 2.15472 4.91614 2.56641 5.42399 2.56641C5.93183 2.56641 6.34351 2.15472 6.34351 1.64688Z"
        fill="black"
      />
      <path
        d="M6.34351 5.33438C6.34351 4.82653 5.93183 4.41485 5.42398 4.41485C4.91614 4.41485 4.50446 4.82653 4.50446 5.33438C4.50446 5.84222 4.91614 6.25391 5.42399 6.25391C5.93183 6.25391 6.34351 5.84222 6.34351 5.33438Z"
        fill="black"
      />
      <path
        d="M10.8478 1.64688C10.8478 1.13903 10.4362 0.727347 9.92832 0.727347C9.42048 0.727347 9.00879 1.13903 9.00879 1.64688C9.00879 2.15472 9.42048 2.56641 9.92832 2.56641C10.4362 2.56641 10.8478 2.15472 10.8478 1.64688Z"
        fill="black"
      />
      <path
        d="M10.8478 5.33438C10.8478 4.82653 10.4362 4.41485 9.92832 4.41485C9.42048 4.41485 9.00879 4.82653 9.00879 5.33438C9.00879 5.84222 9.42048 6.25391 9.92832 6.25391C10.4362 6.25391 10.8478 5.84222 10.8478 5.33438Z"
        fill="black"
      />
    </svg>
  )
}

export default DragIcon
