import CardHolderIcon from '../../assets/icon/CardHolderIcon'
import NumberCircleEightIcon from '../../assets/icon/NumberCircleEightIcon'
import QrCodeIcon from '../../assets/icon/QrCodeIcon'
import UserCircleIcon from '../../assets/icon/UserCircleIcon'
import { UsersPaths } from '../../routing/users/usersPath'
import { Permissions } from '../../utils/constants/permissions'

const usersFullLink = [
  {
    to: UsersPaths.GENERAL_INFO,
    icon: UserCircleIcon,
    name: 'Umumiy ma’lumotlar',
    permission: Permissions.ROLE_SUPPER_ADMIN,
  },
  {
    to: UsersPaths.PAYMENTS,
    icon: CardHolderIcon,
    name: 'To’lovlar',
    permission: Permissions.ROLE_SUPPER_ADMIN,
  },
  {
    to: UsersPaths.LOTS,
    icon: NumberCircleEightIcon,
    name: 'Lotlar',
    permission: Permissions.ROLE_SUPPER_ADMIN,
  },
  {
    to: UsersPaths.PROTOCOLS,
    icon: QrCodeIcon,
    name: 'QR-kodli bayonnomalar',
    permission: Permissions.ROLE_SUPPER_ADMIN,
  },
]

// const usersLink: typeof usersFullLink = []
const usersLink: typeof usersFullLink = usersFullLink

// if (typeof user === 'object' && user) {
//   usersFullLink.forEach((link) => {
//     if (link.permission) {
//       if (user.role.permissions.includes(link.permission)) {
//         usersLink.push(link)
//       }
//     } else {
//       usersLink.push(link)
//     }
//   })
// }

export default usersLink
