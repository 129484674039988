import { Spin, Tabs } from "antd";
import React from "react";
import { useParams } from "react-router-dom";
import TickCircleGreenIcon from "../../../assets/icon/TickCircleGreenIcon";
import XCircleIcon from "../../../assets/icon/XCircleIcon";
import { usePetitonClientLots } from "../../../queries/queries";
import styles from "./userLots.module.scss";
const { TabPane } = Tabs;

const UsersLots = () => {
  const params = useParams();

  const { data, isLoading, isFetching } = usePetitonClientLots(
    1,
    10,
    Number(params?.id)
  );

  return (
    <div className={styles.userLots}>
      <Tabs className="userLotsTab">
        <TabPane tab="Qatnashyotgan auksionlarim" key="1">
          <div className={styles.userLots_body}>
            <div className={styles.userLots_body_content}>
              <h1>Amaldagi auksionlar</h1>
              <Spin spinning={isLoading || isFetching}>
                {data?.map(
                  (item) =>
                    !(
                      item.status === "COMPLETED" ||
                      item.status === "FIELD" ||
                      item.status === "CANCELED"
                    ) && (
                      <div className={styles.userLots_body_content_item}>
                        <div className={styles.userLots_body_content_item_left}>
                          <h1>
                            <span>
                              LOT <br /> №
                            </span>
                            {item.number}
                          </h1>
                          <p>{item.name}</p>
                        </div>
                        <div
                          className={styles.userLots_body_content_item_right}
                        >
                          <div>
                            <h1>Boshlang‘ich narxi:</h1>
                            <p>{item?.startingPrice.toLocaleString()} UZS</p>
                          </div>
                          <div>
                            <h1>Joriy narx</h1>
                            <p>{item?.bidPrice.toLocaleString()} UZS</p>
                          </div>
                        </div>
                      </div>
                    )
                )}
              </Spin>
            </div>
            <div className={styles.userLots_body_content}>
              <h1>Yakunlangan auksionlar</h1>
              <Spin spinning={isLoading || isFetching}>
                {data?.map(
                  (item) =>
                    (item.status === "COMPLETED" ||
                      item.status === "FIELD" ||
                      item.status === "CANCELED") && (
                      <div className={styles.userLots_body_content_item}>
                        <div className={styles.userLots_body_content_item_left}>
                          <h1>
                            <span>
                              LOT <br /> №
                            </span>
                            {item.number}
                          </h1>
                          <p>{item.name}</p>
                        </div>
                        <div
                          className={styles.userLots_body_content_item_right}
                        >
                          <div>
                            <h1>Yakuniy narx</h1>
                            <p>{item?.bidPrice.toLocaleString()} UZS</p>
                          </div>
                          {item.win ? (
                            <h1>
                              <TickCircleGreenIcon /> Muvaffaqiyatli
                            </h1>
                          ) : (
                            <p>
                              <XCircleIcon /> Muvaffaqiyatsiz
                            </p>
                          )}
                        </div>
                      </div>
                    )
                )}
              </Spin>
            </div>
          </div>
        </TabPane>
        <TabPane tab="Mening auksionlarim" key="2">
          Mening auksionlarim
        </TabPane>
      </Tabs>
    </div>
  );
};

export default UsersLots;
