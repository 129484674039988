import React from "react";
import MainHeader from "../../components/headers/MainHeader";

const Statistika = () => {
  return (
    <>
      <MainHeader>Statistika</MainHeader>
      <div>Statistika</div>
    </>
  );
};

export default Statistika;
