import UsersInfo from '../../pages/users/users-info/UsersInfo'
import UsersLots from '../../pages/users/users-lots/UsersLots'
import UsersPayments from '../../pages/users/users-payments/UsersPayments'
import UsersProtacols from '../../pages/users/users-protacols/UsersProtacols'
import { Permissions } from '../../utils/constants/permissions'
import { UsersPaths } from './usersPath'

const routes = [
  {
    path: UsersPaths.GENERAL_INFO,
    component: UsersInfo,
    exact: true,
    permission: Permissions.ROLE_SUPPER_ADMIN,
  },
  {
    path: UsersPaths.PAYMENTS,
    component: UsersPayments,
    exact: true,
    permission: Permissions.ROLE_SUPPER_ADMIN,
  },
  {
    path: UsersPaths.LOTS,
    component: UsersLots,
    exact: true,
    permission: Permissions.ROLE_SUPPER_ADMIN,
  },
  {
    path: UsersPaths.PROTOCOLS,
    component: UsersProtacols,
    exact: true,
    permission: Permissions.ROLE_SUPPER_ADMIN,
  },
]

// const mainRoutes: typeof routes = [];
const mainRoutes: typeof routes = routes

// if (typeof user === "object" && user) {
//   routes.forEach((route) => {
//     if (route.permission) {
//       if (user.role.permissions.includes(route.permission)) {
//         mainRoutes.push(route);
//       }
//     } else {
//       mainRoutes.push(route);
//     }
//   });
// }

export default mainRoutes
