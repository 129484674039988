export const queryNames = {
  CATEGORY_PARENTS: 'CATEGORY_PARENTS',
  CATEGORY_SUB_CATEGORY: 'CATEGORY_SUB_CATEGORY',
  CITY_ALL: 'CITY_ALL',
  REGION_PUBLIC_CITY: 'REGION_PUBLIC_CITY',
  COMPANY_ID: 'COMPANY_ID',
  ADMIN_GET_ALL_ADMIN: 'ADMIN_GET_ALL_ADMIN',
  LOTS: 'LOTS',
  FILE: 'FILE',
  GET_ALL_CLIENT: 'GET_ALL_CLIENT',
  GET_ALL_CLIENT_CONFIRMED: 'GET_ALL_CLIENT_CONFIRMED',
  CLIENT: 'CLIENT',
  CLIENT_ID: 'CLIENT_ID',
  PETITION_CLIENT_LOTS: 'PETITION_CLIENT_LOTS',
  USERS_PAYMENTS: 'USERS_PAYMENTS',
  GET_ALL_PAYMENT: 'GET_ALL_PAYMENT',
  CONTRACTS: 'CONTRACTS',
  ONE_LOT: 'ONE_LOT',
  FILES_PATH: 'FILES_PATH',
}
