import React, { ChangeEvent, useState } from 'react'
import { Button, Input } from 'antd'

import styles from './Contract.module.scss'
import TableCustom from '../../components/table/Table'
import FilterIcon from '../../assets/icon/FilterIcon'
import SearchIcon from '../../assets/icon/SearchIcon'
import { useLocation, useNavigate } from 'react-router-dom'
import { parse, stringify } from 'query-string'
import PaginationGlobal from '../../components/pagination/PaginationGlobal'
import CreateContractModal from '../../components/contracts/CreateContractModal'
import MainHeader from '../../components/headers/MainHeader'
import { useGetPrivateContracts } from '../../queries/queries'
import { OnePrivateContract } from '../../utils/models/PrivateContracts'
import DownloadIcon from '../../assets/icon/DownloadIcon'
import { useDownloadFile } from '../../queries/mutation'

const Contract: React.FC = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [pageSize, setPageSize] = useState(10)
  const [modalVisible, setModalVisible] = useState(false)
  const { data, isLoading } = useGetPrivateContracts()
  const downloadFileMutation = useDownloadFile()

  // download file
  const downloadFile = (path: string, name: string) => {
    downloadFileMutation.mutate({ path, name })
  }

  // columns
  const columns = [
    {
      title: '#',
      render: (_: any, __: any, index: number) => {
        return pageSize * (Number(data?.currentPage) - 1) + index + 1
      },
      align: 'center',
    },
    {
      title: 'SOTUVCHI',
      dataIndex: 'salesmanName',
    },
    {
      title: 'XARIDOR',
      dataIndex: 'buyerName',
    },
    {
      title: 'SOTILGAN BAHOSI',
      render: (record: OnePrivateContract) => {
        return <span>{record?.salePrice?.toLocaleString()} UZS</span>
      },
      align: 'right',
    },
    {
      title: 'AUKSION SANASI',
      dataIndex: 'date',
      align: 'center',
    },
    {
      title: 'YUKLAB OLISH',
      render: (record: OnePrivateContract) => {
        return (
          <span
            onClick={() =>
              downloadFile(record?.file?.absolutePath, record?.file?.name)
            }
            className="cur-p"
          >
            <DownloadIcon />
          </span>
        )
      },
      align: 'center',
    },
  ]

  // change search text
  const changeSearchText = (e: ChangeEvent<HTMLInputElement>) => {
    navigate(
      location.pathname +
        '?' +
        stringify({
          ...parse(location.search),
          page: 1,
          search: e.target.value,
        })
    )
  }

  // handle page
  const handlePage = (page: any, size: number) => {
    setPageSize(size)
    navigate(
      location.pathname +
        '?' +
        stringify({
          ...parse(location.search),
          page: page,
          size: size,
        })
    )
  }

  // open modal
  const openModal = () => {
    setModalVisible(true)
  }

  return (
    <>
      <MainHeader>Shartnomalar</MainHeader>
      <div className={styles.contracts}>
        <div className={styles.contracts_body}>
          <div className={styles.contracts_body_top}>
            <div className={styles.contracts_body_top_left}>
              <Button className="filterButton">
                <FilterIcon />
              </Button>
              <Input
                className="searchInput"
                prefix={<SearchIcon />}
                placeholder="Qidirish..."
                onChange={changeSearchText}
              />
            </div>
            <Button className="createButton" type="primary" onClick={openModal}>
              + Yangi yaratish
            </Button>
          </div>
          <TableCustom
            columns={columns}
            data={data?.data || []}
            loading={isLoading}
          />
        </div>
        <PaginationGlobal
          onChange={handlePage}
          total={data?.totalElements}
          pageSize={pageSize}
        />
      </div>
      <CreateContractModal visible={modalVisible} setVisible={setModalVisible} />
    </>
  )
}

export default Contract
