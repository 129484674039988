import React from "react";
import { Route, Routes } from "react-router-dom";
import { rootRoutes } from "./rootRoutes";

const RootRouter = () => {
  return (
    <div>
      <Routes>
        {rootRoutes.map((route) => (
          <Route
            element={<route.component />}
            path={route.exact ? route.path : `${route.path}/*`}
            key={route.path}
          />
        ))}
      </Routes>
    </div>
  );
};

export default RootRouter;
