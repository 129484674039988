import React from "react";

const WarningCircleIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
        fill="#FFAF37"
      />
      <path
        d="M12 7.5V12.75"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 17.375C12.5523 17.375 13 16.9273 13 16.375C13 15.8227 12.5523 15.375 12 15.375C11.4477 15.375 11 15.8227 11 16.375C11 16.9273 11.4477 17.375 12 17.375Z"
        fill="white"
      />
    </svg>
  );
};

export default WarningCircleIcon;
