import { Spin } from 'antd'
import { useParams } from 'react-router-dom'
import { useClientId } from '../../../queries/queries'
import styles from './usersInfo.module.scss'

const UsersInfo = () => {
  const params = useParams()
  const { data: clientData, isLoading, isFetching } = useClientId(Number(params?.id))

  return (
    <div className={styles.usersInfo}>
      <div className={styles.usersInfo_container}>
        <div className={styles.usersInfo_container_title}>
          <h1>Umumiy ma’lumotlar</h1>
        </div>
        <Spin spinning={isLoading || isFetching}>
          {clientData?.legal ? (
            <div className={styles.usersInfo_container_body}>
              <div className={styles.usersInfo_container_body_item}>
                <h1>Shaxs turi:</h1>
                <p>{clientData?.legal ? 'Yuridik shaxs' : 'Jismoniy shaxs'}</p>
              </div>
              {/* <div className={styles.usersInfo_container_body_item}>
                <h1>STIR:</h1>
                <h3>Kiritilmagan</h3>
              </div> */}
              <div className={styles.usersInfo_container_body_item}>
                <h1>Hudud/viloyat nomi:</h1>
                {clientData?.data.cityName ? (
                  <p>{clientData?.data.cityName}</p>
                ) : (
                  <p>Kiritilmagan</p>
                )}
              </div>
              <div className={styles.usersInfo_container_body_item}>
                <h1>Tuman nomi:</h1>
                {clientData?.data.regionName ? (
                  <p>{clientData?.data.regionName}</p>
                ) : (
                  <p>Kiritilmagan</p>
                )}
              </div>
              <div className={styles.usersInfo_container_body_item}>
                <h1>Manzil (Lotin alifbosida):</h1>
                {clientData?.data.addressName ? (
                  <p>{clientData?.data.addressName}</p>
                ) : (
                  <p>Kiritilmagan</p>
                )}
              </div>
              <div className={styles.usersInfo_container_body_item}>
                <h1>Ishtirokchi nomi</h1>
                {clientData?.data.bossFullName ? (
                  <p>{clientData?.data.bossFullName}</p>
                ) : (
                  <p>Kiritilmagan</p>
                )}
              </div>
              {/* <div className={styles.usersInfo_container_body_item}>
                <h1>Tug‘ilgan kuni:</h1>
                {clientData?.data.bossBirthday ? (
                  <p>{clientData?.data.bossBirthday}</p>
                ) : (
                  <p>Kiritilmagan</p>
                )}
              </div> */}
              {/* <div className={styles.usersInfo_container_body_item}>
                <h1>Jinsi:</h1>
                {clientData?.data.bossGender ? (
                  <p>
                    {clientData?.data.bossGender === "MALE" ? "Erkak" : "Ayol"}
                  </p>
                ) : (
                  <p>Kiritilmagan</p>
                )}
              </div> */}
              {/* <div className={styles.usersInfo_container_body_item}>
                <h1>Pasport berilgan sana:</h1>
                {clientData?.data.bossPassportDateOfIssue ? (
                  <p>{clientData?.data.bossPassportDateOfIssue}</p>
                ) : (
                  <p>Kiritilmagan</p>
                )}
              </div> */}
              {/* <div className={styles.usersInfo_container_body_item}>
                <h1>Pasport seriyasi va raqami</h1>
                {clientData?.data.bossPassportSerialNumber ? (
                  <p>{clientData?.data.bossPassportSerialNumber}</p>
                ) : (
                  <p>Kiritilmagan</p>
                )}
              </div> */}
              {/* <div className={styles.usersInfo_container_body_item}>
                <h1>JSHSHIR:</h1>
                {clientData?.data.bossPassportJshshir ? (
                  <p>{clientData?.data.bossPassportJshshir}</p>
                ) : (
                  <p>Kiritilmagan</p>
                )}
              </div> */}
              {/* <div className={styles.usersInfo_container_body_item}>
                <h1>Pasport kim tomonidan berilgan (Lotin alifbosida):</h1>
                {clientData?.data.bossPassportGiveByWhom ? (
                  <p>{clientData?.data.bossPassportGiveByWhom}</p>
                ) : (
                  <p>Kiritilmagan</p>
                )}
              </div> */}
              {/* <div className={styles.usersInfo_container_body_item}>
                <h1>Passport rasmi</h1>
                {clientData?.data.passportImageIds ? (
                  <p>{clientData?.data.passportImageIds}</p>
                ) : (
                  <h3>Kiritilmagan</h3>
                )}
              </div> */}
            </div>
          ) : (
            <div className={styles.usersInfo_container_body}>
              <div className={styles.usersInfo_container_body_item}>
                <h1>Shaxs turi:</h1>
                <p>{clientData?.legal ? 'Yuridik shaxs' : 'Jismoniy shaxs'}</p>
              </div>
              <div className={styles.usersInfo_container_body_item}>
                <h1>Hudud/viloyat nomi:</h1>
                {clientData?.data.cityName ? (
                  <p>{clientData?.data.cityName}</p>
                ) : (
                  <p>Kiritilmagan</p>
                )}
              </div>
              <div className={styles.usersInfo_container_body_item}>
                <h1>Tuman nomi:</h1>
                {clientData?.data.regionName ? (
                  <p>{clientData?.data.regionName}</p>
                ) : (
                  <p>Kiritilmagan</p>
                )}
              </div>
              <div className={styles.usersInfo_container_body_item}>
                <h1>Manzil (Lotin alifbosida):</h1>
                {clientData?.data.addressName ? (
                  <p>{clientData?.data.addressName}</p>
                ) : (
                  <p>Kiritilmagan</p>
                )}
              </div>
              <div className={styles.usersInfo_container_body_item}>
                <h1>Ishtirokchi nomi</h1>
                {clientData?.data.fullName ? (
                  <p>{clientData?.data.fullName}</p>
                ) : (
                  <p>Kiritilmagan</p>
                )}
              </div>
              <div className={styles.usersInfo_container_body_item}>
                <h1>Tug‘ilgan kuni:</h1>
                {clientData?.data.birthDay ? (
                  <p>{clientData?.data.birthDay}</p>
                ) : (
                  <p>Kiritilmagan</p>
                )}
              </div>
              <div className={styles.usersInfo_container_body_item}>
                <h1>Jinsi:</h1>
                {clientData?.data.gender ? (
                  <p>{clientData?.data.gender === 'MALE' ? 'Erkak' : 'Ayol'}</p>
                ) : (
                  <h3>Kiritilmagan</h3>
                )}
              </div>
              <div className={styles.usersInfo_container_body_item}>
                <h1>Pasport berilgan sana:</h1>
                {clientData?.data.passportDateOfIssue ? (
                  <p>{clientData?.data.passportDateOfIssue}</p>
                ) : (
                  <p>Kiritilmagan</p>
                )}
              </div>
              <div className={styles.usersInfo_container_body_item}>
                <h1>Pasport seriyasi va raqami</h1>
                {clientData?.data.passportSerialNumber ? (
                  <p>{clientData?.data.passportSerialNumber}</p>
                ) : (
                  <p>Kiritilmagan</p>
                )}
              </div>
              <div className={styles.usersInfo_container_body_item}>
                <h1>JSHSHIR:</h1>
                {clientData?.data.jshshir ? (
                  <p>{clientData?.data.jshshir}</p>
                ) : (
                  <p>Kiritilmagan</p>
                )}
              </div>
              <div className={styles.usersInfo_container_body_item}>
                <h1>Pasport kim tomonidan berilgan (Lotin alifbosida):</h1>
                {clientData?.data.passportGivenByWhom ? (
                  <p>{clientData?.data.passportGivenByWhom}</p>
                ) : (
                  <p>Kiritilmagan</p>
                )}
              </div>
              <div className={styles.usersInfo_container_body_item}>
                <h1>Passport rasmi</h1>
                {clientData?.data.passportImagIds ? (
                  <p>{clientData?.data.passportImagIds}</p>
                ) : (
                  <h3>Kiritilmagan</h3>
                )}
              </div>
            </div>
          )}
        </Spin>
      </div>
      <Spin spinning={isLoading || isFetching}>
        <div className={styles.usersInfo_container}>
          <div className={styles.usersInfo_container_title}>
            <h1>Aloqa ma’lumotlari</h1>
          </div>
          <div className={styles.usersInfo_container_body}>
            <div className={styles.usersInfo_container_body_item}>
              <h1>Telefon:</h1>
              <p>{clientData?.data.phoneNumber}</p>
            </div>
            <div className={styles.usersInfo_container_body_item}>
              <h1>E-mail:</h1>
              {clientData?.data.email ? (
                <p>{clientData?.data.email}</p>
              ) : (
                <h3>Kiritilmagan</h3>
              )}
            </div>
            <div className={styles.usersInfo_container_body_item}>
              <h1>Qo‘shimcha telefonlar:</h1>
              {clientData?.data.additionalPhoneNumber ? (
                <p>{clientData?.data.additionalPhoneNumber}</p>
              ) : (
                <h3>Kiritilmagan</h3>
              )}
            </div>
          </div>
        </div>
      </Spin>
      <Spin spinning={isLoading || isFetching}>
        <div className={styles.usersInfo_container}>
          <div className={styles.usersInfo_container_title}>
            <h1>Bank ma’lumotlari</h1>
          </div>
          <div className={styles.usersInfo_container_body}>
            <div className={styles.usersInfo_container_body_item}>
              <h1>MFO:</h1>
              <p>{clientData?.data.bankMfo || <span>Kiritilmagan</span>}</p>
            </div>
            <div className={styles.usersInfo_container_body_item}>
              <h1>Bank hisob raqami:</h1>
              <p>
                {clientData?.data.bankAccountNumber || <span>Kiritilmagan</span>}
              </p>
            </div>
            <div className={styles.usersInfo_container_body_item}>
              <h1>Bank nomi:</h1>
              <p>{clientData?.data.bankName || <span>Kiritilmagan</span>}</p>
            </div>
            <div className={styles.usersInfo_container_body_item}>
              <h1>Karta raqami:</h1>
              <p>{clientData?.data.cardNumber || <span>Kiritilmagan</span>}</p>
            </div>
            <div className={styles.usersInfo_container_body_item}>
              <h1>STIR:</h1>
              <p>{clientData?.data.bankInn || <span>Kiritilmagan</span>}</p>
            </div>
            <div className={styles.usersInfo_container_body_item}>
              <h1>OKED:</h1>
              <p>{clientData?.data.bankOked || <span>Kiritilmagan</span>}</p>
            </div>
          </div>
        </div>
      </Spin>
    </div>
  )
}

export default UsersInfo
