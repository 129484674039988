import { Button, Modal } from "antd";
import React, { useEffect, useState } from "react";
import EditLinearIcon from "../../../assets/icon/EditLinearIcon";
import TrashLinearIcon from "../../../assets/icon/TrashLinearIcon";
import TrashLinearRedIcon from "../../../assets/icon/TrashLinearRedIcon";
import AdminSettingModal from "../../../components/setting/admin-setting/AdminSettingModal";
import TableCustom from "../../../components/table/Table";
import { useAdminDelete } from "../../../queries/mutation";
import { useAdminAll } from "../../../queries/queries";
import styles from "./adminSetting.module.scss";

const AdminSetting = () => {
  const [deleteVisible, setDeleteVisible] = useState({
    visible: false,
    id: 0,
  });

  const adminDelete = useAdminDelete(deleteVisible.id as number);
  const [adminSettingModal, setAdminSettingModal] = useState<any>({
    visible: false,
    id: null,
    fullName: "",
    phoneNumber: "",
    username: "",
    password: "",
  });

  const { data, isLoading, isFetching } = useAdminAll();

  const columns = [
    {
      title: "#",
      render: (text: any, record: any, index: number) => index + 1,
    },
    {
      title: "Name",
      dataIndex: "fullName",
    },
    {
      title: "Status",
      dataIndex: "isUnBlocked",
      render: (item: any) => {
        return (
          <>
            {item ? (
              <span className={styles.status_blocked_active}>Active</span>
            ) : (
              <span className={styles.status_blocked}>Bloklangan</span>
            )}
          </>
        );
      },
    },
    {
      title: "Telefon",
      dataIndex: "phoneNumber",
    },
    {
      dataIndex: "action",
      width: 100,
      render: (text: any, record: any) => (
        <div>
          <Button
            shape="circle"
            style={{ border: "none" }}
            onClick={() =>
              setAdminSettingModal({
                visible: true,
                id: record.id,
                fullName: record.fullName,
                phoneNumber: record.phoneNumber,
                username: record.username,
                password: record.password,
              })
            }
          >
            <EditLinearIcon />
          </Button>
          <Button
            shape="circle"
            style={{ border: "none", marginLeft: 10 }}
            onClick={() => setDeleteVisible({ visible: true, id: record.id })}
          >
            <TrashLinearIcon />
          </Button>
        </div>
      ),
    },
  ];

  const deleteHundle = () => {
    adminDelete.mutate();
  };

  useEffect(() => {
    if (adminDelete.isSuccess) {
      setDeleteVisible({ visible: false, id: 0 });
    }
  }, [adminDelete.isSuccess, adminDelete.isError]);
  return (
    <div className={styles.adminSetting}>
      <div className={styles.adminSetting_title}>
        <h1>Admin sozlamalari</h1>
        <Button
          type="link"
          onClick={() =>
            setAdminSettingModal({
              visible: true,
              id: null,
              fullName: "",
              phoneNumber: "",
              username: "",
              password: "",
            })
          }
        >
          + Admin qo’shish
        </Button>
      </div>
      <div className={styles.adminSetting_container}>
        <TableCustom
          columns={columns}
          data={data ? data?.data.sort((a: any, b: any) => a.id - b.id) : []}
          loading={isLoading || isFetching}
        />
      </div>
      <AdminSettingModal
        obj={adminSettingModal}
        setVisible={setAdminSettingModal}
      />
      <Modal
        visible={deleteVisible.visible}
        footer={false}
        closable={false}
        title={false}
        onCancel={() => setDeleteVisible({ visible: false, id: 0 })}
        width={420}
        className="customDeleteModal"
      >
        <span>
          <TrashLinearRedIcon />
        </span>
        <h1>Adminni o’chirib yubormoqchimisiz?</h1>
        <div>
          <Button onClick={() => setDeleteVisible({ visible: false, id: 0 })}>
            Yo’q
          </Button>
          <Button onClick={deleteHundle}>Ha</Button>
        </div>
      </Modal>
    </div>
  );
};

export default AdminSetting;
