import React from 'react'

const UpdateIcon: React.FC = () => {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.1333 6.76221V10.5122"
        stroke="#023047"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.3833 12.3872L10.1333 10.5122"
        stroke="#023047"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.5239 8.30127H17.6489V5.17627"
        stroke="#023047"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.9927 15.3716C14.0315 16.3336 12.8065 16.989 11.4727 17.2547C10.1389 17.5204 8.75631 17.3846 7.49974 16.8644C6.24317 16.3442 5.16911 15.463 4.41343 14.3323C3.65775 13.2016 3.25439 11.8722 3.25439 10.5122C3.25439 9.15222 3.65775 7.82279 4.41343 6.69208C5.16911 5.56137 6.24317 4.68019 7.49974 4.16C8.75631 3.63981 10.1389 3.50399 11.4727 3.76972C12.8065 4.03545 14.0315 4.69079 14.9927 5.65284L17.649 8.30127"
        stroke="#023047"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default UpdateIcon
