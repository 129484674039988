import React from "react";

const PaymentsIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.34 13.94V16.87C19.34 18.25 18.22 19.37 16.84 19.37H5.25C3.87 19.37 2.75 18.25 2.75 16.87V6.25C2.75 4.87 3.87 3.75 5.25 3.75H16.84C18.22 3.75 19.34 4.87 19.34 6.25V9.18"
        stroke="#888888"
        strokeWidth="1.2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.4502 13.9401H19.4002C19.9502 13.9401 20.4002 13.4901 20.4002 12.9401V10.1801C20.4002 9.63005 19.9502 9.18005 19.4002 9.18005H14.4502C13.3502 9.18005 12.4502 10.0801 12.4502 11.1801V11.9401C12.4502 13.0501 13.3402 13.9401 14.4502 13.9401Z"
        stroke="#888888"
        strokeWidth="1.2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.1401 11.5601H15.8801"
        stroke="#888888"
        strokeWidth="1.2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default PaymentsIcon;
