import React from "react";
import "antd/dist/antd.variable.min.css";
import "./styles/main.scss";
import { BrowserRouter } from "react-router-dom";
import RootRouter from "./routing/RootRouter";
import { ConfigProvider } from "antd";
import { Provider } from "react-redux";
import { QueryClient, QueryClientProvider } from "react-query";
import { store } from "./store/store";

const queryClient = new QueryClient();

function App() {
  ConfigProvider.config({
    theme: {
      primaryColor: "#023047",
    },
  });

  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient} contextSharing={true}>
        <BrowserRouter>
          <RootRouter />
        </BrowserRouter>
      </QueryClientProvider>
    </Provider>
  );
}

export default App;
