import React from 'react'

const CupIcon: React.FC = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.5 12.1667H6C5.26667 12.1667 4.66667 12.7667 4.66667 13.5V13.6667H4C3.72667 13.6667 3.5 13.8933 3.5 14.1667C3.5 14.44 3.72667 14.6667 4 14.6667H12C12.2733 14.6667 12.5 14.44 12.5 14.1667C12.5 13.8933 12.2733 13.6667 12 13.6667H11.3333V13.5C11.3333 12.7667 10.7333 12.1667 10 12.1667H8.5V10.64C8.33333 10.66 8.16667 10.6667 8 10.6667C7.83333 10.6667 7.66667 10.66 7.5 10.64V12.1667Z"
        fill="#D9D9D9"
      />
      <path
        d="M12.32 7.75999C12.76 7.59333 13.1467 7.31999 13.4534 7.01333C14.0734 6.32666 14.48 5.50666 14.48 4.54666C14.48 3.58666 13.7267 2.83333 12.7667 2.83333H12.3934C11.96 1.94666 11.0534 1.33333 10 1.33333H6.00002C4.94669 1.33333 4.04002 1.94666 3.60669 2.83333H3.23335C2.27335 2.83333 1.52002 3.58666 1.52002 4.54666C1.52002 5.50666 1.92669 6.32666 2.54669 7.01333C2.85335 7.31999 3.24002 7.59333 3.68002 7.75999C4.37335 9.46666 6.04002 10.6667 8.00002 10.6667C9.96002 10.6667 11.6267 9.46666 12.32 7.75999ZM9.89335 5.63333L9.48002 6.13999C9.41335 6.21333 9.36669 6.35999 9.37335 6.45999L9.41335 7.11333C9.44002 7.51333 9.15335 7.71999 8.78002 7.57333L8.17335 7.33333C8.08002 7.29999 7.92002 7.29999 7.82669 7.33333L7.22002 7.57333C6.84669 7.71999 6.56002 7.51333 6.58669 7.11333L6.62669 6.45999C6.63335 6.35999 6.58669 6.21333 6.52002 6.13999L6.10669 5.63333C5.84669 5.32666 5.96002 4.98666 6.34669 4.88666L6.98002 4.72666C7.08002 4.69999 7.20002 4.60666 7.25335 4.51999L7.60669 3.97333C7.82669 3.63333 8.17335 3.63333 8.39335 3.97333L8.74669 4.51999C8.80002 4.60666 8.92002 4.69999 9.02002 4.72666L9.65335 4.88666C10.04 4.98666 10.1534 5.32666 9.89335 5.63333Z"
        fill="#D9D9D9"
      />
    </svg>
  )
}

export default CupIcon
