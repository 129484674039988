import React from 'react'

const UnacceptIcon = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.9998 16.2C12.9763 16.2 16.1998 12.9764 16.1998 8.99999C16.1998 5.02354 12.9763 1.79999 8.9998 1.79999C5.02335 1.79999 1.7998 5.02354 1.7998 8.99999C1.7998 12.9764 5.02335 16.2 8.9998 16.2Z"
        stroke="#FC2E2E"
        strokeMiterlimit="10"
      />
      <path
        d="M11.6998 6.29999L6.2998 11.7"
        stroke="#FC2E2E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.6998 11.7L6.2998 6.29999"
        stroke="#FC2E2E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default UnacceptIcon
