import { User } from "../models/User";
import { rootPaths } from "../../routing/rootPaths";
import { MainPaths } from "../../routing/main/mainPaths";

export const signInSuccess: (user: User) => void = (user) => {
  //   if (user.role.permissions[0] === Permissions.PERMISSION_DEVELOPER) {
  //     window.location.replace(
  //       window.location.origin + rootPaths.DEVELOPER
  //     )
  //   } else {
  window.location.replace(
    window.location.origin + rootPaths.MAIN + MainPaths.INDEX
  );
  //   }
};
