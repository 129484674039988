import React from "react";

const LotsIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="2.5" y="2.5" width="8" height="8" rx="0.5" stroke="#888888" />
      <rect x="2.5" y="13.5" width="8" height="8" rx="0.5" stroke="#888888" />
      <rect x="13.5" y="2.5" width="8" height="8" rx="0.5" stroke="#888888" />
      <rect x="13.5" y="13.5" width="8" height="8" rx="0.5" stroke="#888888" />
    </svg>
  );
};

export default LotsIcon;
