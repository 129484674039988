import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface HistoryPathnameState {
  pathname: string;
}

const initialState: HistoryPathnameState = {
  pathname: "/",
};

export const historyOathnameSlice = createSlice({
  name: "pathname",
  initialState,
  reducers: {
    setHistoryPathname: (state, action: PayloadAction<string>) => {
      state.pathname = action.payload;
    },
  },
});

export const { setHistoryPathname } = historyOathnameSlice.actions;
export default historyOathnameSlice.reducer;
