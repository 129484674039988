export enum MainPaths {
  INDEX = '/',
  STATISTIKA = '/statistika',
  CONTRACT = '/contract',
  USERS = '/users',
  USERS_ID = '/users/:id',
  PAYMENTS = '/payments',
  SETTINGS = '/settings',
  PROTOCOLS = '/protocols',
}
