import React from "react";

const TrashLinearRedIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.5 4.98332C14.725 4.70832 11.9333 4.56665 9.15 4.56665C7.5 4.56665 5.85 4.64998 4.2 4.81665L2.5 4.98332"
        stroke="#FC2E2E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.08301 4.14169L7.26634 3.05002C7.39967 2.25835 7.49967 1.66669 8.90801 1.66669H11.0913C12.4997 1.66669 12.608 2.29169 12.733 3.05835L12.9163 4.14169"
        stroke="#FC2E2E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.7087 7.61664L15.167 16.0083C15.0753 17.3166 15.0003 18.3333 12.6753 18.3333H7.32532C5.00033 18.3333 4.92533 17.3166 4.83366 16.0083L4.29199 7.61664"
        stroke="#FC2E2E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.6084 13.75H11.3834"
        stroke="#FC2E2E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.91699 10.4167H12.0837"
        stroke="#FC2E2E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default TrashLinearRedIcon;
