import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { navbarLinks } from "./navbarLinks";
import styles from "./navbar.module.scss";
import Logo from "../../../assets/img/Logo.png";
import LogOutIcon from "../../../assets/icon/LogOutIcon";
import { LocalStorage } from "../../../service/LocalStorage";

const NavBar = () => {
  const navigate = useNavigate();
  return (
    <div className={styles.navabar}>
      <div className="navbar_links">
        <img src={Logo} alt="Logo" />
        {navbarLinks.map((navlink: any) => {
          return (
            <NavLink key={navlink.path} to={navlink.path}>
              {navlink.icon}
              <span>{navlink.name}</span>
            </NavLink>
          );
        })}
      </div>
      <button
        onClick={() => {
          LocalStorage.clear();
          navigate("/");
        }}
      >
        <LogOutIcon /> Log Out
      </button>
    </div>
  );
};

export default NavBar;
