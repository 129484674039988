import { Button, Collapse, Drawer, Form, Input, Radio, Space } from "antd";
import dayjs from "dayjs";
import queryString from "query-string";
import React, { FC, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CaretDownIcon from "../../assets/icon/CaretDownIcon";
import SearchIcon from "../../assets/icon/SearchIcon";
import {
  useCityAll,
  useKategorySetting,
  useRegion,
} from "../../queries/queries";
import DatePicker from "../datepicker/DatePicker";

const { Panel } = Collapse;

interface LotsFilterProps {
  visible: boolean;
  onClose: (e: boolean) => void;
}

const LotsFilter: FC<LotsFilterProps> = ({ onClose, visible }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [cityId, setCityId] = useState(0);
  const [form] = Form.useForm();
  const { data: parentCategorys } = useKategorySetting();
  const { data: cityAll } = useCityAll();
  const { data: regionAll } = useRegion(cityId);
  const handleClose = () => {
    onClose(false);
  };

  const onFinish = (values: any) => {
    navigate(
      location.pathname +
        "?" +
        queryString.stringify({
          ...queryString.parse(location.search),
          search: values?.search ? values?.search : "",
          parentCategoryId: values?.parentCategoryId
            ? values?.parentCategoryId
            : "",
          cityId: values?.cityId ? values?.cityId : "",
          regionId: values?.regionId ? values?.regionId : "",
          status: values?.status ? values?.status : "",
          date: values?.date
            ? dayjs(values?.date, "DD.MM.YYYY").format("DD.MM.YYYY")
            : "",
          page: 1,
        })
    );
  };

  const onReset = () => {
    navigate(
      location.pathname +
        "?" +
        queryString.stringify({
          page: 1,
        })
    );
  };

  return (
    <Drawer
      title={false}
      closable={false}
      placement="right"
      onClose={handleClose}
      visible={visible}
      width={236}
      className="lotsFilter"
    >
      <Form form={form} onFinish={onFinish} onReset={onReset}>
        <div className="lotsFilter_header">
          <h1>Filter</h1>
          <div>
            <Button htmlType="reset" type="link">
              Tozalash
            </Button>
            <Button htmlType="submit" type="link">
              Saqlash
            </Button>
          </div>
        </div>
        <div className="lotsFilter_search">
          <Form.Item name={"search"} noStyle>
            <Input
              className="searchInput"
              prefix={<SearchIcon />}
              placeholder="Qidirish..."
            />
          </Form.Item>
        </div>
        <Collapse
          defaultActiveKey={["1"]}
          expandIconPosition="right"
          expandIcon={({ isActive }) => (
            <span style={{ transform: `rotet(${isActive ? 90 : 0}deg)` }}>
              <CaretDownIcon />
            </span>
          )}
        >
          <Panel header="Kategoriyalar" key="1">
            <Form.Item noStyle name={"parentCategoryId"}>
              <Radio.Group>
                <Space direction="vertical">
                  {parentCategorys?.data?.map((item) => (
                    <Radio key={item.id} value={item.id}>
                      {item.name}
                    </Radio>
                  ))}
                </Space>
              </Radio.Group>
            </Form.Item>
          </Panel>
          <Panel header="Viloyat" key="2">
            <Form.Item noStyle name={"cityId"}>
              <Radio.Group onChange={(e) => setCityId(e.target.value)}>
                <Space direction="vertical">
                  {cityAll?.data?.map((item) => (
                    <Radio key={item.id} value={item.id}>
                      {item.nameUz}
                    </Radio>
                  ))}
                </Space>
              </Radio.Group>
            </Form.Item>
          </Panel>
          <Panel header="Tuman" key="3">
            <Form.Item noStyle name={"regionId"}>
              <Radio.Group>
                <Space direction="vertical">
                  {regionAll?.data?.map((item) => (
                    <Radio key={item.id} value={item.id}>
                      {item.nameUz}
                    </Radio>
                  ))}
                </Space>
              </Radio.Group>
            </Form.Item>
          </Panel>
          <Panel header="Status" key="4">
            <Form.Item noStyle name={"status"}>
              <Radio.Group>
                <Space direction="vertical">
                  <Radio value={"NEW"}>Yangi</Radio>
                  <Radio value={"WAIT"}>Kutilmoqda</Radio>
                  <Radio value={"ACTIVE"}>Active</Radio>
                  <Radio value={"FAILED"}>Muvoffaqiyatsiz</Radio>
                  <Radio value={"CANCELED"}>Bekor qilingan</Radio>
                  <Radio value={"COMPLETED"}>Tugallangan</Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
          </Panel>
          <Panel header="Sana" key="5">
            <Form.Item noStyle name={"date"}>
              <DatePicker
                placeholder="kk.oo.yyyy"
                format={"DD.MM.YYYY"}
                className="customInput"
              />
            </Form.Item>
          </Panel>
        </Collapse>
      </Form>
    </Drawer>
  );
};

export default LotsFilter;
