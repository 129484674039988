import { Button, Form, Modal, Select } from 'antd'
import React, { useEffect, useState } from 'react'
import CurrencyInput from 'react-currency-input-field'
import { useParams } from 'react-router-dom'
import { useMakePayment } from '../../../queries/mutation'
import { paymentMethods } from '../../../utils/constants/paymentMethods'
import { paymentTypes } from '../../../utils/constants/paymentTypes'

import styles from './UsersPayments.module.scss'

interface IProps {
  visible: boolean
  setVisible: (visible: boolean) => void
  type: string
}

interface IFormFields {
  paymentType: string
  amount: string
}

const MakingPaymentModal: React.FC<IProps> = ({ visible, setVisible, type }) => {
  const { Item } = Form
  const [amount, setAmount] = useState<string | undefined>('')
  const { Option } = Select
  const [form] = Form.useForm()
  const params = useParams()
  const makePaymentMutation = useMakePayment(setVisible)

  // default value
  useEffect(() => {
    form.setFieldsValue({
      paymentType: paymentMethods.CASH,
    })
  }, [form, visible])

  // on cancel
  const onCancel = () => {
    setVisible(false)
  }

  // title
  const title = () => {
    if (type === paymentTypes.INCOME) {
      return 'Kirim'
    } else return 'Chiqim'
  }

  // options
  const options = [
    {
      value: paymentMethods.BANK,
      label: 'Bank',
    },
    {
      value: paymentMethods.CARD,
      label: 'Karta',
    },
    {
      value: paymentMethods.CASH,
      label: 'Naqd',
    },
  ]

  // on finish
  const onFinish = (fields: IFormFields) => {
    const data = { ...fields, clientId: params.id, amount: amount }
    makePaymentMutation.mutate({ data, type })
  }

  // after close
  const afterClose = () => {
    form.resetFields()
  }

  // change amount
  const changeAmount = (value: string | undefined) => {
    setAmount(value)
  }

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      title={title()}
      className={styles.makingPaymentModal}
      centered
      footer={null}
      afterClose={afterClose}
    >
      <Form layout="vertical" onFinish={onFinish} form={form}>
        <Item
          label="To'lov turi"
          name="paymentType"
          rules={[{ required: true, message: "To'lov turini tanlang!" }]}
        >
          <Select>
            {options.map((option) => (
              <Option key={option.value} value={option.value}>
                {option.label}
              </Option>
            ))}
          </Select>
        </Item>
        <Item
          label={`${title()} summasi`}
          // name="amount"
          rules={[{ required: true, message: "To'lov summasini kiriting!" }]}
        >
          <CurrencyInput
            allowDecimals={false}
            allowNegativeValue={false}
            className="customInput ant-input"
            autoComplete="off"
            value={amount}
            onValueChange={changeAmount}
          />
        </Item>
        <Item>
          <Button className="createButton" type="primary" htmlType="submit">
            Tasdiqlash
          </Button>
        </Item>
      </Form>
    </Modal>
  )
}

export default MakingPaymentModal
