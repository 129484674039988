import { addControllerPrefix } from '../helper/addControllerPrefix'

export const controllers = {
  AUTH: '/v1',
}

// auth
const auth = {
  LOGIN: '/public/admin/login',
  REFRESH: '/public/refresh-token',
  GET_ALL_CLIENT: '/admin/get-all-client',
  ALL_CONFIRMED_CLIENTS: '/admin/get-all-client-for-private',
}

export const authEndpoints: typeof auth = addControllerPrefix(auth, controllers.AUTH)

// setting-category
const settingCategory = {
  CATEGORY_PARENTS: '/public/category/parent',
  CATEGORY_PARENTS_BY_ID: '/admin/category/parent-category',
  CATEGORY_DELETE: '/admin/category',
  CATEGORY_SUB_CATEGORY: '/public/category/sub-category',
  CATEGORY_SUB_CATEGORY_CREATE: '/admin/category/sub-category',
  CATEGORY_PARENTS_SORT: '/admin/category/parent-category/sort',
  CATEGORY_SUB_CATEGORY_SORT: '/admin/category/sub-category/sort',
  CATEGORY_SUB_CATEGORY_EDIT: '/admin/category/sub-category',
}

export const settingCategoryEndpoints: typeof settingCategory = addControllerPrefix(
  settingCategory,
  controllers.AUTH
)

// city-controller
const cityController = {
  CITY_ALL: '/public/city',
}

export const cityControllerEndpoints: typeof cityController = addControllerPrefix(
  cityController,
  controllers.AUTH
)

// region-controller
const regionController = {
  REGION_PUBLIC_CITY: '/public/city/region',
}

export const regionControllerEndpoints: typeof regionController =
  addControllerPrefix(regionController, controllers.AUTH)

// company
const companyController = {
  COMPANY_ID: '/public/company',
  COMPANY_EDIT: '/admin/company/edit',
}

export const companyControllerEndpoints: typeof companyController =
  addControllerPrefix(companyController, controllers.AUTH)

// files

const files = {
  INDEX: '/auth/attachment',
  GET_INDEX: '/public/attachment',
}
export const filesEndpoints: typeof files = addControllerPrefix(
  files,
  controllers.AUTH
)

// admin
const adminController = {
  GET_ALL_ADMIN: '/admin/get-all-admin',
  ADMIN_CREATE: '/admin/admin/create',
  ADMIN_EDIT: '/admin/admin/edit',
  ADMIN_DELETE: '/admin/admin/delete',
}

export const adminControllerEndpoints: typeof adminController = addControllerPrefix(
  adminController,
  controllers.AUTH
)

// lot
const lotController = {
  LOTS: '/admin/lot',
  LOTS_GET: '/public/lot/get-all-lot',
  CANCEL: '/admin/lot/cancel',
  LOT_GET: '/admin/lot/get',
  UPDATE: '/admin/lot/edit',
}

export const lotControllerEndpoints: typeof lotController = addControllerPrefix(
  lotController,
  controllers.AUTH
)

// client
const client = {
  CLIENT: '/admin/client/get-client-data-for-confirm',
  CLIENT_CONFIRM: '/admin/client/confirm',
  CLIENT_ID: '/admin/client/get-client-data-byId',
}

export const clientEndpoints: typeof client = addControllerPrefix(
  client,
  controllers.AUTH
)

// payment
const payment = {
  INDEX: '/admin/payment-operation',
  ALL_PAYMENT: '/admin/payment-operation/all-payment',
  GET_ALL_PAYMENT: '/admin/payment-operation/get-all-payment',
}
export const paymentEndpoints: typeof payment = addControllerPrefix(
  payment,
  controllers.AUTH
)

// petition
const petition = {
  PETITION_CLIENT_LOTS: '/admin/client',
}

export const petitionEndpoints: typeof petition = addControllerPrefix(
  petition,
  controllers.AUTH
)

// contract
const contract = {
  GET_ALL_CONTRACT: '/admin/contract/get-all-contract',
  UPDATE: '/admin/contract/update',
}
export const contractEndpoints: typeof contract = addControllerPrefix(
  contract,
  controllers.AUTH
)

// private contract
const privateContract = {
  INDEX: '/admin/private/contract',
}
export const privateContractEndpoints: typeof privateContract = addControllerPrefix(
  privateContract,
  controllers.AUTH
)
