import { Avatar, Button, Form, Input, Modal, Spin } from 'antd'
import React, { FC, useEffect, useState } from 'react'
import CameraIcon from '../../../assets/icon/CameraIcon'
import ImageDefault from '../../../assets/icon/ImageDefault'
import TrashLinearRedIcon from '../../../assets/icon/TrashLinearRedIcon'
import '../../../pages/settings/kategory-setting/kategorySetting.module.scss'
import {
  useCategoryCreate,
  useCategoryDelete,
  useCategoryEdit,
  useUploadFile,
} from '../../../queries/mutation'
import { useFile } from '../../../queries/queries'
import { BASE_URL } from '../../../service/RequestService'
import { FileObject } from '../../../utils/models/File'

interface KategorySettingModalProps {
  obj: {
    visible: boolean
    id: number | null
    imageId?: number | null
    name: string
  }
  setVisible: React.Dispatch<
    React.SetStateAction<{
      visible: boolean
      id: number | null
      imageId?: number | null
      name: string
    }>
  >
}

const KategorySettingModal: FC<KategorySettingModalProps> = ({
  obj,
  setVisible,
}) => {
  const [deleteVisible, setdeleteVisible] = useState(false)
  const [form] = Form.useForm()
  const categoryEdit = useCategoryEdit(obj?.id as number)
  const categoryCreate = useCategoryCreate()
  const categoryDelete = useCategoryDelete()
  const { data: imageFile } = useFile(obj?.imageId as number)
  const handleClose = () => {
    setVisible({ visible: false, id: null, imageId: null, name: '' })
  }

  const uploadFile = useUploadFile(getFile)

  function getFile(data: FileObject[]) {
    setVisible({
      visible: true,
      id: obj.id ? obj.id : null,
      imageId: data[0].id,
      name: obj.name ? obj.name : '',
    })
  }

  const handleImage = (e: any) => {
    if (e.target?.files[0] && e.target.files[0].size <= 10000000) {
      const file = e.target.files[0]
      const formData = new FormData()
      formData.append('files', file)
      uploadFile.mutate(formData)
    }
  }

  const onFinish = (values: any) => {
    if (obj.id) {
      categoryEdit.mutate({
        imageId: obj?.imageId as number,
        name: values.name,
        parentId: obj.id,
        step: 0,
      })
    } else {
      categoryCreate.mutate({
        imageId: obj?.imageId as number,
        name: values.name,
        step: 0,
      })
    }
  }

  useEffect(() => {
    handleClose()
    setdeleteVisible(false)
    // eslint-disable-next-line
  }, [categoryEdit.isSuccess, categoryCreate.isSuccess, categoryDelete.isSuccess])

  useEffect(() => {
    form.setFieldsValue({
      name: obj.name,
    })
    // eslint-disable-next-line
  }, [obj.visible])

  const deleteHundle = () => {
    categoryDelete.mutate(obj.id as number)
  }

  return (
    <>
      <Modal
        title={obj.id ? 'Kategoriyani tahrirlash' : 'Kategoriya yaratish'}
        visible={obj.visible}
        onCancel={handleClose}
        footer={false}
        wrapClassName="kategorySettingModal"
        width={399}
      >
        <div className="kategorySettingModal_title">
          <Spin spinning={uploadFile.isLoading}>
            <Avatar
              size={71}
              style={{
                background: '#F1F1F1',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              icon={<ImageDefault />}
              src={BASE_URL + '/' + imageFile?.absolutePath}
            />
          </Spin>
          <label htmlFor="kategoryImage">
            <CameraIcon />
            Rasm yuklash
          </label>
          <input
            onChange={handleImage}
            type={'file'}
            style={{ display: 'none' }}
            id="kategoryImage"
            accept="image/*"
          />
        </div>
        <Form layout="vertical" onFinish={onFinish} form={form}>
          <Form.Item
            label="Kategoriya nomi"
            name={'name'}
            rules={[{ required: true }]}
          >
            <Input className="customInput" />
          </Form.Item>
          {obj.id && (
            <Button
              type="link"
              className="customRedColorButton"
              style={{ width: '100%', padding: '11px 0', marginBottom: 10 }}
              onClick={() => setdeleteVisible(true)}
            >
              <TrashLinearRedIcon />
              Kategoriyani o’chirib yuborish
            </Button>
          )}
          <Button
            type="primary"
            className="saveButton"
            htmlType="submit"
            style={{ width: '100%', padding: '11px 0' }}
          >
            Saqlash
          </Button>
          <Button
            className="customWhiteButton"
            style={{ width: '100%', padding: '11px 0' }}
            onClick={handleClose}
          >
            Yopish
          </Button>
        </Form>
      </Modal>
      <Modal
        visible={deleteVisible}
        footer={false}
        closable={false}
        title={false}
        onCancel={() => setdeleteVisible(false)}
        width={420}
        className="customDeleteModal"
      >
        <span>
          <TrashLinearRedIcon />
        </span>
        <h1>Kategoriyani o’chirib yubornoqchimisiz?</h1>
        <div>
          <Button onClick={() => setdeleteVisible(false)}>Yo’q</Button>
          <Button onClick={deleteHundle}>Ha</Button>
        </div>
      </Modal>
    </>
  )
}

export default KategorySettingModal
