import React from "react";

const LogOutIcon = () => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.75 2H3.75C3.19772 2 2.75 2.44772 2.75 3V21C2.75 21.5523 3.19772 22 3.75 22H12.75"
        stroke="#888888"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.75 11.4C8.41863 11.4 8.15 11.6686 8.15 12C8.15 12.3314 8.41863 12.6 8.75 12.6V11.4ZM23.1743 12.4243C23.4086 12.1899 23.4086 11.8101 23.1743 11.5757L19.3559 7.75736C19.1216 7.52304 18.7417 7.52304 18.5074 7.75736C18.273 7.99167 18.273 8.37157 18.5074 8.60589L21.9015 12L18.5074 15.3941C18.273 15.6284 18.273 16.0083 18.5074 16.2426C18.7417 16.477 19.1216 16.477 19.3559 16.2426L23.1743 12.4243ZM8.75 12.6H22.75V11.4H8.75V12.6Z"
        fill="#888888"
      />
    </svg>
  );
};

export default LogOutIcon;
