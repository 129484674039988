import React, { FC } from 'react'
import { Table } from 'antd'

interface TableProps {
  data: any[]
  columns: any[]
  loading?: boolean
  className?: string
  onRow?: (record: any) => {}
  scroll?: {
    x?: string | number | true | undefined
    y?: string | number | undefined
  }
}

const TableCustom: FC<TableProps> = ({
  data,
  columns,
  loading,
  onRow,
  className = '',
  scroll,
}) => {
  return (
    <Table
      className={`customTable ${className}`}
      columns={columns}
      dataSource={data}
      loading={loading}
      pagination={false}
      onRow={onRow}
      rowKey={(record: any) => record.id}
      scroll={scroll}
    />
  )
}

export default TableCustom
