import { Avatar, Button, Form, Input, Select, Spin } from "antd";
import React, { useEffect, useState } from "react";
import CameraIcon from "../../../assets/icon/CameraIcon";
import RandomSymbolsIcon from "../../../assets/icon/RandomSymbolsIcon";
import { useCompanyEdit, useUploadFile } from "../../../queries/mutation";
import {
  useCityAll,
  useCompany,
  useFile,
  useRegion,
} from "../../../queries/queries";
import { BASE_URL } from "../../../service/RequestService";
import { FileObject } from "../../../utils/models/File";
import styles from "./kompanySetting.module.scss";

const KompanySetting = () => {
  const [form] = Form.useForm();
  const [cityId, setCityId] = useState(0);
  const [fileId, setFileId] = useState<number>(0);
  const { data: cityAll, isLoading: cityLoading } = useCityAll();
  const { data: region, isLoading: regionLoading } = useRegion(cityId);
  const {
    data: company,
    isLoading: companyLoading,
    isFetching: companyFetching,
  } = useCompany();
  const { data: file, isLoading: fileLoading } = useFile(fileId);
  const companyEdit = useCompanyEdit(company?.id as number);
  const uploadFile = useUploadFile(getFile);

  function getFile(data: FileObject[]) {
    setFileId(data[0].id);
  }

  const handleImage = (e: any) => {
    if (e.target?.files[0] && e.target.files[0].size <= 10000000) {
      const file = e.target.files[0];
      const formData = new FormData();
      formData.append("files", file);
      uploadFile.mutate(formData);
    }
  };

  useEffect(() => {
    setCityId(company?.cityId as number);
    setFileId(company?.logoId as number);
    form.setFieldsValue({
      director: company?.director,
      name: company?.name,
      phoneNumber: company?.phoneNumber,
      cityId: company?.cityId,
      regionId: company?.regionId,
      email: company?.email,
      bankName: company?.bankName,
      bankAccountNumber: company?.bankAccountNumber,
      bankInn: company?.bankInn,
      bankMfo: company?.bankMfo,
      bankOked: company?.bankOked,
      address: company?.address,
      telegramLink: company?.telegramLink,
    });
    // eslint-disable-next-line
  }, [company]);

  const onFinish = (values: any) => {
    companyEdit.mutate({
      director: values.director,
      name: values.name,
      phoneNumber: values.phoneNumber,
      cityId: values.cityId,
      regionId: values.regionId,
      email: values.email,
      bankName: values.bankName,
      accountNumber: values.bankAccountNumber,
      INN: values.bankInn,
      MFO: values.bankMfo,
      OKED: values.bankOked,
      address: values.address,
      telegramSilka: values.telegramLink,
      logoId: file?.id as number,
    });
  };

  return (
    <div className={styles.kompanySetting}>
      <div className={styles.kompanySetting_title}>
        <Spin spinning={fileLoading}>
          <Avatar
            size={99}
            style={{
              background: "transparent",
              border: "1px solid #E5E7EB",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            src={BASE_URL + "/" + file?.absolutePath}
            icon={<RandomSymbolsIcon />}
          />
        </Spin>
        <label htmlFor="photoFile">
          <CameraIcon />
          Logo yuklash
        </label>
        <input
          type="file"
          id="photoFile"
          style={{ display: "none" }}
          accept="image/*"
          onChange={handleImage}
        />
      </div>
      <Spin spinning={companyLoading || companyFetching}>
        <Form
          layout="vertical"
          className="kompanySetingForm"
          form={form}
          onFinish={onFinish}
        >
          <div className={styles.kompanySetting_grid}>
            <Form.Item
              label="Korxona nomi"
              rules={[{ required: true }]}
              name="name"
            >
              <Input className="customInput" />
            </Form.Item>
            <Form.Item
              label="Korxona Raxbari"
              rules={[{ required: true }]}
              name="director"
            >
              <Input className="customInput" />
            </Form.Item>
          </div>
          <div className={styles.kompanySetting_grid}>
            <Form.Item
              label="Telefon raqam"
              rules={[{ required: true }]}
              name="phoneNumber"
            >
              <Input className="customInput" />
            </Form.Item>
            <Form.Item label="E-mail" rules={[{ required: true }]} name="email">
              <Input className="customInput" />
            </Form.Item>
          </div>
          <div className={styles.kompanySetting_grid}>
            <Form.Item
              label="Telegram (havolasi)"
              rules={[{ required: true }]}
              name="telegramLink"
            >
              <Input className="customInput" />
            </Form.Item>
            <Form.Item
              label="Viloyat"
              rules={[{ required: true }]}
              name="cityId"
            >
              <Select
                className="customSelect"
                loading={cityLoading}
                onChange={(e: any) => {
                  setCityId(e);
                  form.setFieldsValue({ city: e, region: undefined });
                }}
              >
                {cityAll?.data?.map((item) => (
                  <Select.Option value={item.id}>{item.nameUz}</Select.Option>
                ))}
              </Select>
            </Form.Item>
          </div>
          <Form.Item label="Manzil" rules={[{ required: true }]} name="address">
            <Input.TextArea cols={2} className="customInput" />
          </Form.Item>
          <div className={styles.kompanySetting_grid}>
            <Form.Item
              label="Shaxar"
              rules={[{ required: true }]}
              name="regionId"
            >
              <Select className="customSelect" loading={regionLoading}>
                {region?.data?.map((item) => (
                  <Select.Option value={item.id}>{item.nameUz}</Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="Bank nomi"
              rules={[{ required: true }]}
              name="bankName"
            >
              <Input className="customInput" />
            </Form.Item>
          </div>
          <div className={styles.kompanySetting_grid}>
            <Form.Item
              label="Hisob raqam"
              rules={[{ required: true }]}
              name="bankAccountNumber"
            >
              <Input className="customInput" />
            </Form.Item>
            <Form.Item label="INN" rules={[{ required: true }]} name="bankInn">
              <Input className="customInput" />
            </Form.Item>
          </div>
          <div className={styles.kompanySetting_grid}>
            <Form.Item label="MFO" rules={[{ required: true }]} name="bankMfo">
              <Input className="customInput" />
            </Form.Item>
            <Form.Item
              label="OKED"
              rules={[{ required: true }]}
              name="bankOked"
            >
              <Input className="customInput" />
            </Form.Item>
          </div>
          <Button
            type="primary"
            className="saveButton"
            htmlType="submit"
            style={{ float: "right" }}
          >
            Saqlash
          </Button>
        </Form>
      </Spin>
    </div>
  );
};

export default KompanySetting;
