import React from 'react'

const ProtocolIcon: React.FC = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.9196 20.92H5.59961C4.49961 20.92 3.59961 20.02 3.59961 18.92V6.59998C3.59961 5.49998 4.49961 4.59998 5.59961 4.59998H17.9196C19.0196 4.59998 19.9196 5.49998 19.9196 6.59998V18.92C19.9196 20.02 19.0196 20.92 17.9196 20.92Z"
        stroke="#888888"
        strokeWidth="1.2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.9202 11.16C19.9202 16.55 15.5502 20.92 10.1602 20.92"
        stroke="#888888"
        strokeWidth="1.2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.13965 10.93H12.7596"
        stroke="#888888"
        strokeWidth="1.2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.13965 14.58H15.3696"
        stroke="#888888"
        strokeWidth="1.2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default ProtocolIcon
