import { LoadingOutlined } from '@ant-design/icons'
import { Button, Form, Input, message, Modal, Select, Spin } from 'antd'
import React, { FC, useEffect, useState } from 'react'
import CurrencyInput from 'react-currency-input-field'
import Dropzone from 'react-dropzone'
import FilePlusIcon from '../../assets/icon/FilePlusIcon'
import FileTextIcon from '../../assets/icon/FileTextIcon'
import TrashLinearIcon from '../../assets/icon/TrashLinearIcon'
import { useLot, useUpdateLot, useUploadFile } from '../../queries/mutation'
import {
  useCityAll,
  useClientAllConfirmed,
  useGetDocsPath,
  useGetImagesPath,
  useGetOneLot,
  useKategorySetting,
  useRegion,
  useSubKategorySetting,
} from '../../queries/queries'
import { FileObject } from '../../utils/models/File'

import {
  closestCenter,
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import {
  arrayMove,
  rectSortingStrategy,
  SortableContext,
  sortableKeyboardCoordinates,
} from '@dnd-kit/sortable'
import dayjs from 'dayjs'
import { useQueryClient } from 'react-query'
import CloseIcon from '../../assets/icon/CloseIcon'
import { auctionTypes } from '../../utils/constants/auctionTypes'
import { Client } from '../../utils/models/Client'
import DatePicker from '../datepicker/DatePicker'
import { DragImageItem } from './DragImageItem'
import TrashLinearRedIcon from '../../assets/icon/TrashLinearRedIcon'
import CupIcon from '../../assets/icon/CupIcon'
import { parseLocaledString } from '../../utils/helper/parseLocaledString'

interface LotModalCreateProps {
  obj: {
    id: number | null
    visible: boolean
  }
  setVisible: React.Dispatch<
    React.SetStateAction<{
      id: number | null
      visible: boolean
    }>
  >
}

const LotModalCreate: FC<LotModalCreateProps> = ({ setVisible, obj }) => {
  const [sumValue, setSumValue] = useState<string | undefined>('')
  const [step, setStep] = useState(1)
  const [files, setFiles] = useState<FileObject[]>([])
  const [cityId, setCityId] = useState(0)
  const [categoryId, setCategoryId] = useState(0)
  const [clientId, setClientId] = useState(0)
  const [clientSearch, setClientSearch] = useState<string>('')
  const { data: lotData, isLoading: lotIsloading } = useGetOneLot(obj.id)
  const queryClient = useQueryClient()
  const [items, setItems] = useState<
    { step: string; path: string; id: null | number }[]
  >([
    { step: '1', path: '', id: null },
    { step: '2', path: '', id: null },
    { step: '3', path: '', id: null },
    { step: '4', path: '', id: null },
    { step: '5', path: '', id: null },
    { step: '6', path: '', id: null },
    { step: '7', path: '', id: null },
    { step: '8', path: '', id: null },
    { step: '9', path: '', id: null },
    { step: '10', path: '', id: null },
  ])
  useGetImagesPath(lotData?.images, setItems, items)
  useGetDocsPath(lotData?.docs, setFiles)
  const [auctionType, setAuctionType] = useState(auctionTypes.OPEN)

  const uploadFile = useUploadFile(getFile)

  function getFile(data: FileObject[]) {
    setFiles((prev) => [...prev, ...data])
  }

  const inputSum = (value: string | undefined) => {
    setSumValue(value)
  }

  const [formOne] = Form.useForm()
  const [formTwo] = Form.useForm()
  const [formThree] = Form.useForm()
  const [formFour] = Form.useForm()

  const { data: cityAll, isLoading: cityLoading } = useCityAll()
  const { data: region, isLoading: regionLoading } = useRegion(cityId)
  const { data: parentCategory, isLoading: parentCategoryLoading } =
    useKategorySetting()
  const { data: subCategory, isLoading: subCategoryLoading } =
    useSubKategorySetting(categoryId)
  // const { data: clients, isLoading: clientsLoading } = useClientAll(
  //   1,
  //   5,
  //   clientSearch,
  //   'confirmed'
  // )
  const { data: clients, isLoading: clientsLoading } =
    useClientAllConfirmed(clientSearch)
  const [closeAuctionParticipants, setCloseAuctionParticipants] = useState<Client[]>(
    []
  )
  const [winnerParticipantId, setWinnerParticipantId] = useState<number>()
  const [winnerSum, setWinnerSum] = useState('')

  // initial values
  useEffect(() => {
    if (lotData) {
      formOne.setFieldsValue({
        productName: lotData.productName,
        categoryId: lotData.parentCategoryId,
        subCategoryId: lotData.categoryId,
        attributes: lotData.attributes,
      })
      setCategoryId(lotData.parentCategoryId)
      setClientId(lotData.clientId)
      formTwo.setFieldsValue({
        name: lotData.clientId,
      })
      setSumValue(`${lotData.startingPrice}`)
      formThree.setFieldsValue({
        stepPercentage: lotData.stepPercentage,
        initialPercentage: lotData.initialPercentage,
        endPetitionTime: dayjs(lotData.endPetitionTime, 'DD.MM.YYYY HH:mm'),
        startSaleTime: dayjs(lotData.startSaleTime, 'DD.MM.YYYY HH:mm'),
        regionId: lotData.cityId,
        cityId: lotData.regionId,
        address: lotData.address,
        location: lotData.location,
      })
      setCityId(lotData.cityId)
    }
  }, [lotData, formOne, formTwo, formThree, queryClient])

  // on cancel
  const handleCancel = () => {
    setVisible({ id: null, visible: false })
    setStep(1)
    setItems([
      { step: '1', path: '', id: null },
      { step: '2', path: '', id: null },
      { step: '3', path: '', id: null },
      { step: '4', path: '', id: null },
      { step: '5', path: '', id: null },
      { step: '6', path: '', id: null },
      { step: '7', path: '', id: null },
      { step: '8', path: '', id: null },
      { step: '9', path: '', id: null },
      { step: '10', path: '', id: null },
    ])
    setFiles([])
    formOne.resetFields()
    formTwo.resetFields()
    formThree.resetFields()
  }

  // update lot mutation
  const updateLotMutation = useUpdateLot(handleCancel)

  // create lot mutation
  const createLot = useLot(handleCancel)

  const handleDrop = (acceptedFiles: any) => {
    const formData = new FormData()
    acceptedFiles.forEach((file: any) => {
      formData.append('files', file)
    })
    uploadFile.mutate(formData)
  }

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  )

  // lot payload data
  const lotPayloadData = () => {
    return {
      productName: formOne.getFieldsValue().productName,
      categoryId: formOne.getFieldsValue().subCategoryId,
      attributes: formOne.getFieldsValue().attributes,
      docs: files.filter((file) => file.id !== null).map((file) => file.id),
      clientId: clientId,
      images: items
        .filter((item: any) => item.id !== null)
        .map((item, number) => {
          return {
            imageId: item.id,
            step: number + 1,
          }
        }),
      regionId: formThree.getFieldsValue().cityId,
      startSaleTime: dayjs(formThree.getFieldsValue().startSaleTime).format(
        'DD.MM.YYYY HH:mm'
      ),
      endPetitionTime: dayjs(formThree.getFieldsValue().endPetitionTime).format(
        'DD.MM.YYYY HH:mm'
      ),
      stepPercentage: Number(formThree.getFieldsValue().stepPercentage),
      initialPercentage: Number(formThree.getFieldsValue().initialPercentage),
      startingPrice: Number(sumValue),
      address: formThree.getFieldsValue().address,
      location: formThree.getFieldsValue().location,
    }
  }

  const onFinishOne = (values: any) => {
    if (items.some((item) => item.id !== null)) {
      setStep(2)
    } else {
      message.info('Kamida bitta rasm yuklang')
    }
  }

  const onFinishTwo = (values: any) => {
    if (files.some((item) => item.id !== null)) {
      setStep(3)
    } else {
      message.info('Kamida bitta fayl yuklang')
    }
  }

  const onFinishThree = (values: any) => {
    if (!isCloseAuction()) {
      if (obj.id) {
        updateLotMutation.mutate({ lot: lotPayloadData(), id: obj.id })
      } else {
        createLot.mutate(lotPayloadData())
      }
    } else {
      setDefaultWinnerSum()
      setStep(4)
    }
  }

  const onFinishFour = () => {
    let closeAuctionData: any = {
      ...lotPayloadData(),
      private: isCloseAuction(),
      participants: participantsIds(),
      winningParticipant: winnerParticipantId,
      winningAmount: parseLocaledString(winnerSum),
    }

    if (
      isCloseAuction() &&
      participantsIds().length > 0 &&
      winnerParticipantId &&
      winnerSum
    ) {
      createLot.mutate(closeAuctionData)
    }
  }

  function handleDragEnd(event: any) {
    let a: any = document?.querySelector(
      '[for=DragImageItem' + event.active.id + ']'
    )

    a.style.pointerEvents = 'inherit'

    const { active, over } = event

    if (active.id !== over.id) {
      setItems((items) => {
        const oldIndex = items.findIndex(({ step }) => step === active.id)
        const newIndex = items.findIndex(({ step }) => step === over.id)

        return arrayMove(items, oldIndex, newIndex)
      })
    }
  }

  const handleTab = (key: number) => {
    switch (key) {
      case 1:
        setStep(1)
        break
      case 2:
        formOne.submit()
        break
      case 3:
        formTwo.submit()
        break
      case 4:
        formThree.submit()
        break
    }
  }

  // after close modal
  const afterCloseModal = () => {
    setVisible({ id: null, visible: false })
    setSumValue('')
    setAuctionType(auctionTypes.OPEN)
    setCloseAuctionParticipants([])
    setWinnerParticipantId(0)
    setWinnerSum('')
  }

  // handle auction type change
  const handleAuctionTypeChange = (auctionType: string) => {
    setAuctionType(auctionType)
  }

  // is close auction
  const isCloseAuction = () => {
    if (auctionType === auctionTypes.CLOSE) return true
    return false
  }

  // select participant
  const onChange = (value: string) => {
    const participantId = JSON.parse(value)?.id

    // set winner
    if (closeAuctionParticipants.length === 0) {
      setWinnerParticipantId(participantId)
    }

    // checking existing participant
    if (!participantsIds().includes(participantId)) {
      setCloseAuctionParticipants((prev) => [...prev, JSON.parse(value)])
    }
    formFour.setFieldsValue({ participant: null })
  }

  // remove participant
  const removeParticipant = (participantId: number) => {
    let newParticipants: Client[] = closeAuctionParticipants?.filter(
      (item) => item.id !== participantId
    )

    setCloseAuctionParticipants(newParticipants)

    // set winner
    if (winnerParticipantId === participantId) {
      setWinnerParticipantId(closeAuctionParticipants[1]?.id)
    }
  }

  // participant ids
  const participantsIds = () => {
    return closeAuctionParticipants?.map((item) => item.id)
  }

  // set winner participant
  const setWinnerParticipant = (participantId: number) => {
    setWinnerParticipantId(participantId)
  }

  // is not empty participants
  const isNotEmptyParticipants = () => {
    return closeAuctionParticipants?.length > 0
  }

  // winner name
  const winnerName = () => {
    if (winnerParticipantId) {
      let name

      name = closeAuctionParticipants?.find(
        (item) => item.id === winnerParticipantId
      )?.name

      return <p>{name}</p>
    } else {
      return <p className="null">Kiritilmagan</p>
    }
  }

  // handle winner sum
  const handleWinnerSum = (value: string | undefined) => {
    setWinnerSum(value || '')
  }

  // default winning sum
  const setDefaultWinnerSum = () => {
    const price = Number(sumValue)
    const percentage = Number(formThree.getFieldsValue().stepPercentage)
    if (price && percentage) {
      setWinnerSum(String((percentage * price) / 100 + price))
    }
  }

  return (
    <Modal
      title={
        <div className="d-f j-c-s-b">
          <div className="ant-modal-title">
            Lot yaratish &nbsp;
            {lotIsloading && (
              <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
            )}
            {!obj.id && (
              <Select value={auctionType} onChange={handleAuctionTypeChange}>
                <Select.Option value={auctionTypes.OPEN}>
                  Ochiq auksion
                </Select.Option>
                <Select.Option value={auctionTypes.CLOSE}>
                  Yopiq auksion
                </Select.Option>
              </Select>
            )}
          </div>
          <span className="cur-p" onClick={handleCancel}>
            <CloseIcon />
          </span>
        </div>
      }
      visible={obj.visible}
      wrapClassName={'lot_modal'}
      footer={false}
      width={564}
      closable={false}
      afterClose={afterCloseModal}
    >
      <div className="lot_modal_title">
        <p onClick={() => handleTab(1)} className={step === 1 ? 'active' : ''}>
          Lot ma’lumotlari
        </p>
        <p onClick={() => handleTab(2)} className={step === 2 ? 'active' : ''}>
          Hujjatlar
        </p>
        <p onClick={() => handleTab(3)} className={step === 3 ? 'active' : ''}>
          Auksion ma’lumotlari
        </p>
        {isCloseAuction() && (
          <p onClick={() => handleTab(4)} className={step === 4 ? 'active' : ''}>
            Yopiq auksion
          </p>
        )}
      </div>
      <Form
        form={formOne}
        layout="vertical"
        onFinish={onFinishOne}
        style={{ display: step === 1 ? 'block' : 'none' }}
      >
        <Form.Item label="Nomi" name={'productName'} rules={[{ required: true }]}>
          <Input className="customInput" />
        </Form.Item>
        <Form.Item label="Bo’lim" name={'categoryId'} rules={[{ required: true }]}>
          <Select
            className="customSelect"
            loading={parentCategoryLoading}
            onChange={(value: any) => {
              setCategoryId(value)
              formOne.setFieldsValue({ sectionGroup: undefined })
            }}
          >
            {parentCategory?.data?.map((item: any) => (
              <Select.Option key={item.id} value={item.id}>
                {item.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name={'subCategoryId'} rules={[{ required: true }]}>
          <Select className="customSelect" loading={subCategoryLoading}>
            {subCategory?.data?.map((item: any) => (
              <Select.Option key={item.id} value={item.id}>
                {item.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Atributlar"
          name={'attributes'}
          rules={[{ required: true }]}
        >
          <Input.TextArea className="customInput" />
        </Form.Item>
        <div className="lot_modal_image_title">
          <h1>Rasmlar biriktirish</h1>
          <p>
            Birinchi surat e’loningiz asosiy rasmi bo’ladi. Suratlar tartibini
            ularning ustiga bosib va olib o’tish bilan o’zgartirishingiz mumkin
          </p>
        </div>
        <Form.Item>
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
            onDragMove={(event) => {
              let a: any = document?.querySelector(
                '[for=DragImageItem' + event.active.id + ']'
              )
              a.style.pointerEvents = 'none'
            }}
          >
            <SortableContext
              items={items.map(({ step }) => step)}
              strategy={rectSortingStrategy}
            >
              <div
                style={{
                  display: 'inline-grid',
                  gridTemplateColumns: 'repeat(5, 1fr)',
                  gridGap: 10,
                }}
              >
                {items.map((item) => (
                  <DragImageItem
                    key={item.step}
                    item={item}
                    setItems={setItems}
                    items={items}
                  />
                ))}
              </div>
            </SortableContext>
          </DndContext>
        </Form.Item>
        <div className="d-f j-c-r">
          <Button
            style={{ width: 160 }}
            className="customWhiteButton"
            onClick={handleCancel}
          >
            Yopish
          </Button>
          <Button
            htmlType="submit"
            style={{ width: 160 }}
            type="primary"
            className="saveButton"
          >
            Davom etish
          </Button>
        </div>
      </Form>
      <Form
        form={formTwo}
        layout="vertical"
        onFinish={onFinishTwo}
        style={{ display: step === 2 ? 'block' : 'none' }}
      >
        <Form.Item label="Sotuvchi " name={'name'} rules={[{ required: true }]}>
          <Select
            disabled={Boolean(obj.id)}
            showSearch
            onChange={(value: any) => {
              setClientId(Number(value))
            }}
            onSearch={(value: any) => {
              setClientSearch(value)
            }}
            loading={clientsLoading}
            className="customSelect"
            filterOption={false}
            defaultActiveFirstOption={false}
            showArrow={false}
            notFoundContent={clientsLoading ? <Spin size="small" /> : null}
          >
            {clients?.data?.map((item) => (
              <Select.Option key={item.id} value={item.id}>
                {item.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <div className="lot_modal_document">
          <h1>Hujjatlar</h1>
          <Spin spinning={uploadFile.isLoading}>
            <Dropzone onDrop={handleDrop} minSize={1024} maxSize={3072000}>
              {({
                getRootProps,
                getInputProps,
                isDragActive,
                isDragAccept,
                isDragReject,
              }) => {
                const additionalClass = isDragAccept
                  ? 'lot_modal_document_file_accept'
                  : isDragReject
                  ? 'lot_modal_document_file_reject'
                  : ''

                return (
                  <div
                    {...getRootProps({
                      className: `lot_modal_document_file ${additionalClass}`,
                    })}
                  >
                    <div>
                      <input {...getInputProps()} />
                      <FilePlusIcon />
                      <div>
                        <h1>Fayl yuklash</h1>
                        <p>DOC, DOCX, EXCEL,PDF</p>
                      </div>
                    </div>
                  </div>
                )
              }}
            </Dropzone>
          </Spin>
          {files.map((item) => {
            return (
              <div key={item.id} className="lot_modal_document_files">
                <div>
                  <FileTextIcon />
                  <div>
                    <h1>{item.originalName}</h1>
                    <p>
                      {item.size >= 1024 * 1024
                        ? Math.floor(item.size / (1024 * 1024)) + ' MB'
                        : Math.floor(item.size / 1024) + ' KB'}
                    </p>
                  </div>
                </div>
                <Button
                  shape="circle"
                  onClick={() => setFiles(files.filter((el) => el.id !== item.id))}
                >
                  <TrashLinearIcon />
                </Button>
              </div>
            )
          })}
        </div>
        <div className="d-f j-c-r">
          <Button
            style={{ width: 160 }}
            className="customWhiteButton"
            onClick={() => setStep(1)}
          >
            Ortga
          </Button>
          <Button
            htmlType="submit"
            style={{ width: 160 }}
            type="primary"
            className="saveButton"
          >
            Davom etish
          </Button>
        </div>
      </Form>
      <Form
        form={formThree}
        layout="vertical"
        onFinish={onFinishThree}
        style={{ display: step === 3 ? 'block' : 'none' }}
      >
        <Form.Item label="Boshlang’ich narx " rules={[{ required: true }]}>
          <CurrencyInput
            disabled={Boolean(obj.id)}
            allowDecimals={false}
            allowNegativeValue={false}
            className="customInput ant-input"
            autoComplete="off"
            value={sumValue}
            onValueChange={inputSum}
          />
        </Form.Item>
        <div className="d-f j-c-s-b ">
          <Form.Item
            label="Oshib borish qadami (%) "
            name={'stepPercentage'}
            rules={[{ required: true }]}
          >
            <Input
              className="customInput"
              type="number"
              max={100}
              min={0.001}
              step={0.001}
              style={{ width: 251 }}
            />
          </Form.Item>
          <Form.Item
            label="Boshlang’ich badal (%) "
            name={'initialPercentage'}
            rules={[{ required: true }]}
          >
            <Input
              className="customInput"
              type="number"
              max={100}
              min={0.001}
              step={0.001}
              style={{ width: 251 }}
              disabled={Boolean(obj.id)}
            />
          </Form.Item>
        </div>
        <div className="d-f j-c-s-b ">
          <Form.Item
            label="Ariza topshirish tugatish vaqti"
            name={'endPetitionTime'}
            rules={[{ required: true }]}
          >
            <DatePicker
              placeholder=""
              className="customInput"
              style={{ width: 251 }}
              showTime={{ format: 'HH:mm' }}
              format="DD-MM-YYYY HH:mm"
              disabled={Boolean(obj.id)}
            />
          </Form.Item>
          <Form.Item
            label="Auksion bashlanish vaqti "
            name={'startSaleTime'}
            rules={[{ required: true }]}
          >
            <DatePicker
              placeholder=""
              className="customInput"
              style={{ width: 251 }}
              showTime={{ format: 'HH:mm' }}
              format="DD-MM-YYYY HH:mm"
              disabled={Boolean(obj.id)}
            />
          </Form.Item>
        </div>
        <div className="d-f j-c-s-b ">
          <Form.Item
            label="Hudud/viloyat nomi "
            name={'regionId'}
            rules={[{ required: true }]}
          >
            <Select
              className="customSelect"
              loading={cityLoading}
              onChange={(e: any) => {
                setCityId(e)
                formThree.setFieldsValue({ city: undefined })
              }}
              style={{ width: 251 }}
            >
              {cityAll?.data?.map((item) => (
                <Select.Option key={item.id} value={item.id}>
                  {item.nameUz}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="Tuman nomi" name={'cityId'} rules={[{ required: true }]}>
            <Select
              className="customSelect"
              loading={regionLoading}
              style={{ width: 251 }}
            >
              {region?.data?.map((item) => (
                <Select.Option value={item.id}>{item.nameUz}</Select.Option>
              ))}
            </Select>
          </Form.Item>
        </div>
        <Form.Item
          label="Manzil (Lotin alifbosida)"
          name={'address'}
          rules={[{ required: true }]}
        >
          <Input className="customInput" />
        </Form.Item>
        <Form.Item
          label="Xarita havolasi"
          name={'location'}
          rules={[{ required: true }]}
        >
          <Input className="customInput" />
        </Form.Item>
        <div className="d-f j-c-r">
          <Button
            style={{ width: 160 }}
            className="customWhiteButton"
            onClick={() => setStep(2)}
          >
            Ortga
          </Button>
          <Button
            htmlType="submit"
            style={{ width: 160 }}
            type="primary"
            className="saveButton"
            loading={createLot.isLoading}
          >
            {isCloseAuction() ? 'Davom etish' : 'Saqlosh'}
          </Button>
        </div>
      </Form>
      <Form
        form={formFour}
        layout="vertical"
        onFinish={onFinishFour}
        style={{ display: step === 4 ? 'block' : 'none' }}
        className="close_auction"
      >
        <Form.Item label="Ishtirokchilarni qidirish" name={'participant'}>
          <Select
            disabled={Boolean(obj.id)}
            showSearch
            onChange={(value) => onChange(value)}
            onSearch={(value) => setClientSearch(value)}
            loading={clientsLoading}
            className="customSelect"
            filterOption={false}
            defaultActiveFirstOption={false}
            showArrow={false}
            notFoundContent={clientsLoading ? <Spin size="small" /> : null}
          >
            {clients?.data?.map((item) => {
              return (
                <Select.Option
                  key={item.id}
                  value={JSON.stringify(item)}
                  className={participantsIds()?.includes(item.id) ? 'selected' : ''}
                >
                  {item.name}
                </Select.Option>
              )
            })}
          </Select>
        </Form.Item>
        <div className="close_auction_participants">
          {isNotEmptyParticipants() && (
            <>
              <h3>Auksion ishtirokchilari</h3>
              {closeAuctionParticipants.map((item) => (
                <div className="participant_cont" key={item.id}>
                  <p onClick={() => setWinnerParticipant(item.id)}>{item.name}</p>
                  <div className="right">
                    <span
                      className={`${
                        winnerParticipantId === item.id ? 'winner' : ''
                      } cup`}
                      onClick={() => setWinnerParticipant(item.id)}
                    >
                      <CupIcon />
                    </span>
                    <span
                      className="delete"
                      onClick={() => removeParticipant(item.id)}
                    >
                      <TrashLinearRedIcon />
                    </span>
                  </div>
                </div>
              ))}
            </>
          )}
        </div>
        <div className="winner">
          <span>G'olib ishtirokchi</span>
          {winnerName()}
        </div>
        <Form.Item label="G'olib yutgan summa (so'm)">
          <CurrencyInput
            allowDecimals={false}
            allowNegativeValue={false}
            className="customInput ant-input"
            autoComplete="off"
            value={winnerSum}
            onValueChange={handleWinnerSum}
          />
        </Form.Item>
        <div className="d-f j-c-r">
          <Button
            style={{ width: 160 }}
            className="customWhiteButton"
            onClick={() => setStep(3)}
          >
            Ortga
          </Button>
          <Button
            htmlType="submit"
            style={{ width: 160 }}
            type="primary"
            className="saveButton"
            loading={createLot.isLoading}
          >
            Saqlash
          </Button>
        </div>
      </Form>
    </Modal>
  )
}

export default LotModalCreate
