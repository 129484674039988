import React from "react";

const ContractIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.9201 20.92H5.6001C4.5001 20.92 3.6001 20.02 3.6001 18.92V6.59998C3.6001 5.49998 4.5001 4.59998 5.6001 4.59998H17.9201C19.0201 4.59998 19.9201 5.49998 19.9201 6.59998V18.92C19.9201 20.02 19.0201 20.92 17.9201 20.92Z"
        stroke="#888888"
        strokeWidth="1.2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.9202 11.16C19.9202 16.55 15.5502 20.92 10.1602 20.92"
        stroke="#888888"
        strokeWidth="1.2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.14014 10.93H12.7601"
        stroke="#888888"
        strokeWidth="1.2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.14014 14.58H15.3701"
        stroke="#888888"
        strokeWidth="1.2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ContractIcon;
