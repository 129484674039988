import React from "react";

const RandomSymbolsIcon = () => {
  return (
    <svg
      width="53"
      height="60"
      viewBox="0 0 53 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.6299 23.9726H32.3044V16.0125C30.6263 13.9373 23.5169 14.1259 21.6299 16.0125V23.9726Z"
        fill="#0D988C"
      />
      <path
        d="M51.0026 6.58857C43.7263 2.27573 35.4239 0 26.9656 0C18.5072 0 10.2047 2.27573 2.92849 6.58857C1.64071 10.6569 0.93457 15.9717 0.93457 20.5476C0.93457 36.4797 9.45865 51.0196 21.6303 57.0674C23.3439 57.92 25.1295 58.619 26.9664 59.157C28.8042 58.6194 30.5908 57.9197 32.3048 57.0662C44.4741 51.0192 53.0006 36.4789 53.0006 20.5476C53.0002 15.9717 52.2884 10.6569 51.0026 6.58857ZM35.1298 42.2329L32.3048 51.0568V29.3127C30.6268 27.2359 23.5173 27.4252 21.6303 29.3127V51.0568L18.8058 42.2217C13.7803 36.7375 10.0847 28.9852 10.0847 20.5476C10.0827 18.2544 10.3107 15.9669 10.7652 13.7192C15.8321 11.3307 21.3645 10.0921 26.9662 10.0921C32.5678 10.0921 38.1002 11.3307 43.1671 13.7192C43.6234 15.9666 43.8528 18.2543 43.8516 20.5476C43.8508 28.9908 40.1608 36.7475 35.1298 42.2329Z"
        fill="#0D988C"
      />
    </svg>
  );
};

export default RandomSymbolsIcon;
