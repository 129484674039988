import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Button, Input } from "antd";
import React, { FC, useEffect, useState } from "react";
import DragIcon from "../../../assets/icon/DragIcon";
import TrashLinearIcon from "../../../assets/icon/TrashLinearIcon";
import {
  useCategorySubsDelete,
  useCategorySubsEdit,
} from "../../../queries/mutation";
import { CategorySubs } from "../../../utils/models/CategorySubs";

interface SortableSubItemProps {
  id: number;
  handle: boolean;
  value: number;
  data: CategorySubs[];
  parentId: number;
}

const SortableSubItem: FC<SortableSubItemProps> = ({
  id,
  data,
  parentId,
  handle,
  value,
}) => {
  const [getData, setGetData] = useState(data[0]);
  const [timeRespons, setTimeRespons] = useState(false);

  const deleteCategory = useCategorySubsDelete(parentId);
  const editCategory = useCategorySubsEdit(data[0]?.id as number, parentId);
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: id });

  const categoryChange = (e: any) => {
    setGetData({ ...getData, name: e.target.value });
    setTimeRespons(true);
  };

  useEffect(() => {
    if (timeRespons) {
      setTimeout(() => {
        editCategory.mutate({
          name: getData.name,
          parentId: parentId,
          step: data[0].step,
        });
        setTimeRespons(false);
      }, 1500);
    }
    // eslint-disable-next-line
  }, [timeRespons]);

  return (
    <div
      ref={setNodeRef}
      style={{
        transform: CSS.Transform.toString(transform),
        transition,
        zIndex: isDragging ? "100" : "auto",
        opacity: isDragging ? 0.3 : 1,
        display: "flex",
        marginBottom: 8,
      }}
      className={`CollapseSortablePanel_content_item`}
    >
      <Button {...listeners} {...attributes} style={{ border: "none" }}>
        <DragIcon />
      </Button>
      <Input
        placeholder="Yangi kategory nomini kiriting"
        value={getData.name}
        style={{ width: "100%" }}
        className="customInput"
        onChange={categoryChange}
      />
      <Button
        onClick={() => {
          deleteCategory.mutate(data[0].id as number);
        }}
        shape={"circle"}
      >
        <TrashLinearIcon />
      </Button>
    </div>
  );
};

export default SortableSubItem;
