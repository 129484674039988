import React, { FC, ReactNode } from "react";
import styles from "./header.module.scss";

interface MainHeaderProps {
  children?: ReactNode;
}

const MainHeader: FC<MainHeaderProps> = ({ children }) => {
  return <div className={styles.header}>{children}</div>;
};

export default MainHeader;
