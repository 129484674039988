import React, { FC } from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Avatar, Spin } from "antd";
import ImageDefault from "../../assets/icon/ImageDefault";
import { useUploadFile } from "../../queries/mutation";
import { FileObject } from "../../utils/models/File";
import { BASE_URL } from "../../service/RequestService";

interface DragImageItemProps {
  item: {
    step: string;
    path: string;
    id: number | null;
  };
  setItems: React.Dispatch<
    React.SetStateAction<
      {
        step: string;
        path: string;
        id: number | null | any;
      }[]
    >
  >;
  items: {
    step: string;
    path: string;
    id: number | null | any;
  }[];
}

export const DragImageItem: FC<DragImageItemProps> = ({
  item,
  setItems,
  items,
}) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: item.step });
  const uploadFile = useUploadFile(getFile);

  function getFile(data: FileObject[]) {
    setItems(
      items?.map((el: any) => {
        if (el.step === item.step && el.id === item.id) {
          return {
            step: el.step,
            path: BASE_URL + "/" + data[0].absolutePath,
            id: data[0].id,
          };
        } else {
          return {
            step: el.step,
            path: el.path,
            id: el.id,
          };
        }
      })
    );
  }

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const handleImage = (e: any) => {
    if (e.target?.files[0] && e.target.files[0].size <= 10000000) {
      const file = e.target.files[0];
      const formData = new FormData();
      formData.append("files", file);
      uploadFile.mutate(formData);
    }
  };

  return (
    <Spin spinning={uploadFile.isLoading}>
      <div
        ref={setNodeRef}
        style={style}
        {...attributes}
        {...listeners}
        className="DragImageItem"
      >
        <label htmlFor={"DragImageItem" + item.step}>
          <Avatar src={item.path} icon={<ImageDefault />} />
          <input
            type="file"
            style={{ display: "none" }}
            id={"DragImageItem" + item.step}
            onChange={handleImage}
            accept="image/*"
          />
        </label>
      </div>
    </Spin>
  );
};
