import { Button, Input } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import FilterIcon from "../../assets/icon/FilterIcon";
import SearchIcon from "../../assets/icon/SearchIcon";
import TickCircleGreenIcon from "../../assets/icon/TickCircleGreenIcon";
import MainHeader from "../../components/headers/MainHeader";
import PaginationGlobal from "../../components/pagination/PaginationGlobal";
import TableCustom from "../../components/table/Table";
import UserAppendModal from "../../components/users/UserAppendModal";
import UserConfirmationModal from "../../components/users/UserConfirmationModal";
import { useClientAll } from "../../queries/queries";
import { MainPaths } from "../../routing/main/mainPaths";
import { rootPaths } from "../../routing/rootPaths";
import { UsersPaths } from "../../routing/users/usersPath";
import styles from "./usersAll.module.scss";

const UsersAll = () => {
  const [addUser, setAddUser] = useState(false);
  const [open, setOpen] = useState<any>({ id: null, visible: false });
  const navigate = useNavigate();

  const [searchInput, setSearchInput] = useState("");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [total, setTotal] = useState(1);
  const { data, isLoading, isFetching } = useClientAll(
    page,
    pageSize,
    searchInput
  );

  const pageHandle = (page: any, size: number) => {
    setPage(page);
    setPageSize(size);
  };

  useEffect(() => {
    if (data && data?.data.length > 0) {
      setTotal(Number(data?.totalElements));
    }
  }, [data]);

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      render: (text: any, record: any, index: number) =>
        pageSize * (Number(data?.currentPage) - 1) + index + 1,
    },
    {
      title: "Foydalanuvchi",
      dataIndex: "name",
    },

    {
      title: "Pasport",
      dataIndex: "legal",
      render: (legal: any) => (
        <span
          className="passportTag"
          style={{ background: legal ? "#219EBC" : "#3772FF" }}
        >
          {legal ? "Yuridik" : "Jismoniy"}
        </span>
      ),
    },
    {
      title: "Telefon",
      dataIndex: "phoneNumber",
    },
    {
      title: "Tasdiqlanganligi",
      dataIndex: "confirmed",
      render: (confirmed: boolean, record: any) => (
        <>
          {confirmed ? (
            <span
              className="d-f j-c-c a-i-c"
              style={{
                color: "#14804A",
                display: "inline-flex",
                padding: "8px 4px",
                boxSizing: "border-box",
              }}
            >
              <TickCircleGreenIcon />
              &nbsp; Tasdiqlangan
            </span>
          ) : (
            <Button
              className="customWhiteButton"
              style={{ border: "1px solid #023047" }}
              onClick={(e: any) => {
                e.stopPropagation();
                setOpen({ id: record.id, visible: true });
              }}
            >
              Tasdiqlash
            </Button>
          )}
        </>
      ),
    },
  ];

  return (
    <>
      <MainHeader>Foydalanuvchilar</MainHeader>
      <div style={{ margin: "16px" }}>
        <div className={styles.usersAll}>
          <div className={styles.usersAll_header}>
            <div className={styles.usersAll_header_left}>
              <Button className="filterButton">
                <FilterIcon />
              </Button>
              <Input
                className="searchInput"
                prefix={<SearchIcon />}
                placeholder="Qidirish..."
                onChange={(e) => {
                  setPage(1);
                  setSearchInput(e.target.value);
                }}
              />
            </div>
          </div>
          <TableCustom
            className="usersAll_table lotsTableSize"
            data={data?.data ? data.data : []}
            columns={columns}
            loading={isLoading || isFetching}
            onRow={(record: any) => {
              return {
                onClick: () => {
                  navigate(
                    rootPaths.MAIN +
                      MainPaths.USERS +
                      "/" +
                      record.id +
                      UsersPaths.GENERAL_INFO
                  );
                },
              };
            }}
          />
        </div>
        <PaginationGlobal
          onChange={pageHandle}
          total={total}
          pageSize={pageSize}
        />
        <UserAppendModal visible={addUser} setVisible={setAddUser} />
        <UserConfirmationModal obj={open} setVisible={setOpen} />
      </div>
    </>
  );
};

export default UsersAll;
