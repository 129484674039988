import { Button, Image, Modal } from "antd";
import React, { FC } from "react";
import passport from "../../assets/img/passport.png";
import { useClientConfirm } from "../../queries/mutation";
import { useClient } from "../../queries/queries";

interface UserConfirmationModalProps {
  obj: {
    id: number | null;
    visible: boolean;
  };
  setVisible: React.Dispatch<
    React.SetStateAction<{
      id: number | null;
      visible: boolean;
    }>
  >;
}

const UserConfirmationModal: FC<UserConfirmationModalProps> = ({
  setVisible,
  obj,
}) => {
  const confirmMutation = useClientConfirm();
  const { data } = useClient(obj.id as number);
  const handleCansel = () => {
    setVisible({ id: null, visible: false });
  };

  const handleConfirm = () => {
    confirmMutation.mutate(obj.id as number);
    handleCansel();
  };
  return (
    <Modal
      title="Foydalanuvchini tasdiqlash"
      visible={obj.visible}
      onCancel={handleCansel}
      footer={null}
      className="userConfirmationModal"
      width={612}
    >
      <div className="userConfirmationModalContent">
        <div>
          <p>Pasport rasmi:</p>
          <Image width={93} height={59} src={passport} />
        </div>
        <div>
          <h1>Kiritilgan ma’lumotlar:</h1>
          <div>
            <div>
              <h1>F.I.O:</h1>
              <p>{data?.clientName}</p>
            </div>
            <div>
              <h1>Jinsi:</h1>
              <p>{data?.gender}</p>
            </div>
            <div>
              <h1>Pasport berilgan sana:</h1>
              <p>21.04.2022</p>
            </div>
            <div>
              <h1>Pasport seriyasi va raqami</h1>
              <p>{data?.serialNumber}</p>
            </div>
            <div>
              <h1>Pasport kim tomonidan berilgan:</h1>
              <p>{data?.givenByWhom}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="d-f j-c-r" style={{ marginTop: 16 }}>
        <Button
          className="saveButton"
          type="primary"
          style={{
            background: "#FC2E2E",
            border: "1px solid #FC2E2E",
            marginRight: 6,
          }}
          onClick={handleCansel}
        >
          Bakor qilish
        </Button>
        <Button className="saveButton" type="primary" onClick={handleConfirm}>
          Tasdiqlash
        </Button>
      </div>
    </Modal>
  );
};

export default UserConfirmationModal;
