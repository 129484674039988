import { FormInstance, message } from 'antd'
import axios from 'axios'
import dayjs from 'dayjs'
import { useMutation, useQueryClient } from 'react-query'
import { useLocation } from 'react-router-dom'
import { useAppDispatch } from '../hooks/reduxHooks'
import { LocalStorage } from '../service/LocalStorage'
import { $api } from '../service/RequestService'
import { setUser } from '../store/reducers/UserSlice'
import {
  adminControllerEndpoints,
  authEndpoints,
  clientEndpoints,
  companyControllerEndpoints,
  contractEndpoints,
  filesEndpoints,
  lotControllerEndpoints,
  paymentEndpoints,
  privateContractEndpoints,
  settingCategoryEndpoints,
} from '../utils/constants/apis'
import { TOKEN_KEY } from '../utils/constants/localStorageKeys'
import { queryNames } from '../utils/constants/queryNames'
import { signInSuccess } from '../utils/helper/signSucces'
import { AdminCreate } from '../utils/models/Admin'
import { CategoryParentsEdit } from '../utils/models/CategoryParents'
import { CategorySubs } from '../utils/models/CategorySubs'
import { CompanySettingUpdate } from '../utils/models/CompanySetting'
import { ContractCreatePayloadData } from '../utils/models/ContractCreateFormFields'
import { FileObject } from '../utils/models/File'
import { Login } from '../utils/models/Login'
import { Lot } from '../utils/models/Lot'
import { Sort } from '../utils/models/Sort'
import { User } from '../utils/models/User'
import { baseErrorHandler } from './baseErrorHandler'
import { showMessage } from './showMessage'

export function useSignIn() {
  const dispatch = useAppDispatch()

  return useMutation(
    async (data: Login) => {
      const res = await $api.post(authEndpoints.LOGIN, data, {
        withCredentials: true,
      })
      if (!res.data.accept) return Promise.reject(res.data.message)
      return res.data.data as User
    },
    {
      onSuccess: (data) => {
        LocalStorage.set(TOKEN_KEY, data.accessToken)
        dispatch(setUser(data))
        signInSuccess(data)
      },
      onError: (err) => baseErrorHandler(err),
    }
  )
}

export function useUploadFile(getFile?: (data: FileObject[]) => void) {
  return useMutation(
    async (data: FormData) => {
      const res = await $api.post(filesEndpoints.INDEX, data)
      return res.data
    },
    {
      onSuccess: (data) => {
        if (getFile) getFile(data)
        showMessage('Yuklandi', true)
      },
      onError: (err) => {
        baseErrorHandler(err)
      },
    }
  )
}

export function useCategoryCreate() {
  const qc = useQueryClient()

  return useMutation(
    async (data: Omit<CategoryParentsEdit, 'parentId'>) => {
      const res = await $api.post(
        settingCategoryEndpoints.CATEGORY_PARENTS_BY_ID,
        data
      )
      return res.data
    },
    {
      onSuccess: (data) => {
        showMessage(data.message, data.accept)
        qc.invalidateQueries([queryNames.CATEGORY_PARENTS])
      },
      onError: baseErrorHandler,
    }
  )
}

export function useCategoryEdit(id: number) {
  const qc = useQueryClient()

  return useMutation(
    async (data: CategoryParentsEdit) => {
      const res = await $api.put(
        settingCategoryEndpoints.CATEGORY_PARENTS_BY_ID + '/' + id,
        data
      )
      return res.data
    },
    {
      onSuccess: (data) => {
        showMessage(data.message, data.accept)
        qc.invalidateQueries([queryNames.CATEGORY_PARENTS])
      },
      onError: baseErrorHandler,
    }
  )
}

export function useCategoryDelete() {
  const qc = useQueryClient()

  return useMutation(
    async (id: number) => {
      const res = await $api.delete(
        settingCategoryEndpoints.CATEGORY_DELETE + '/' + id
      )
      return res.data
    },
    {
      onSuccess: (data) => {
        showMessage(data.message, data.accept)
        qc.invalidateQueries([queryNames.CATEGORY_PARENTS])
      },
      onError: baseErrorHandler,
    }
  )
}

export function useCategorySubsCreate(id: number) {
  const qc = useQueryClient()

  return useMutation(
    async (data: CategorySubs) => {
      const res = await $api.post(
        settingCategoryEndpoints.CATEGORY_SUB_CATEGORY_CREATE,
        data
      )
      return res.data
    },
    {
      onSuccess: (data) => {
        showMessage(data.message, data.accept)
        qc.invalidateQueries([queryNames.CATEGORY_SUB_CATEGORY, id])
      },
      onError: baseErrorHandler,
    }
  )
}

export function useCategorySubsDelete(parentId: number) {
  const qc = useQueryClient()

  return useMutation(
    async (id: number) => {
      const res = await $api.delete(
        settingCategoryEndpoints.CATEGORY_DELETE + '/' + id
      )
      return res.data
    },
    {
      onSuccess: (data) => {
        showMessage(data.message, data.accept)
        qc.invalidateQueries([queryNames.CATEGORY_SUB_CATEGORY, parentId])
      },
      onError: baseErrorHandler,
    }
  )
}

export function useCategorySortParents() {
  const qc = useQueryClient()

  return useMutation(
    async (data: Sort[]) => {
      const res = await $api.put(
        settingCategoryEndpoints.CATEGORY_PARENTS_SORT,
        data
      )
      return res.data
    },
    {
      onSuccess: (data) => {
        showMessage(data.message, data.accept)
        qc.invalidateQueries([queryNames.CATEGORY_PARENTS])
      },
      onError: baseErrorHandler,
    }
  )
}

export function useCategorySortSubs(id: number) {
  const qc = useQueryClient()

  return useMutation(
    async (data: Sort[]) => {
      const res = await $api.put(
        settingCategoryEndpoints.CATEGORY_SUB_CATEGORY_SORT,
        data
      )
      return res.data
    },
    {
      onSuccess: (data) => {
        showMessage(data.message, data.accept)
        qc.invalidateQueries([queryNames.CATEGORY_SUB_CATEGORY, id])
      },
      onError: baseErrorHandler,
    }
  )
}

export function useCategorySubsEdit(id: number, parentId: number) {
  const qc = useQueryClient()

  return useMutation(
    async (data: Omit<CategorySubs, 'id'>) => {
      const res = await $api.put(
        settingCategoryEndpoints.CATEGORY_SUB_CATEGORY_EDIT + '/' + id,
        data
      )
      return res.data
    },
    {
      onSuccess: (data) => {
        showMessage(data.message, data.accept)
        qc.invalidateQueries([queryNames.CATEGORY_SUB_CATEGORY, parentId])
      },
      onError: baseErrorHandler,
    }
  )
}

export function useCompanyEdit(id: number = 1) {
  const qc = useQueryClient()

  return useMutation(
    async (data: CompanySettingUpdate) => {
      const res = await $api.put(
        companyControllerEndpoints.COMPANY_EDIT + '/' + id,
        data
      )
      return res.data
    },
    {
      onSuccess: (data) => {
        showMessage(data.message, data.accept)
        qc.invalidateQueries([queryNames.COMPANY_ID])
      },
      onError: baseErrorHandler,
    }
  )
}

// admin
export function useAdminCreate() {
  const qc = useQueryClient()

  return useMutation(
    async (data: AdminCreate) => {
      const res = await $api.post(adminControllerEndpoints.ADMIN_CREATE, data)
      return res.data
    },
    {
      onSuccess: (data) => {
        showMessage(data.message, data.accept)
        qc.invalidateQueries([queryNames.ADMIN_GET_ALL_ADMIN])
      },
      onError: baseErrorHandler,
    }
  )
}

export function useAdminEdit(id: number) {
  const qc = useQueryClient()

  return useMutation(
    async (data: AdminCreate) => {
      const res = await $api.put(
        adminControllerEndpoints.ADMIN_EDIT + '/' + id,
        data
      )
      return res.data
    },
    {
      onSuccess: (data) => {
        showMessage(data.message, data.accept)
        qc.invalidateQueries([queryNames.ADMIN_GET_ALL_ADMIN])
      },
      onError: baseErrorHandler,
    }
  )
}

export function useAdminDelete(id: number | null) {
  const qc = useQueryClient()

  return useMutation(
    async () => {
      const res = await $api.delete(adminControllerEndpoints.ADMIN_DELETE + '/' + id)
      return res.data
    },
    {
      onSuccess: (data) => {
        showMessage(data.message, data.accept)
        qc.invalidateQueries([queryNames.ADMIN_GET_ALL_ADMIN])
      },
      onError: baseErrorHandler,
    }
  )
}

export function useLot(handleCancel: () => void) {
  const qc = useQueryClient()

  return useMutation(
    async (data: Lot) => {
      const res = await $api.post(lotControllerEndpoints.LOTS, data)
      return res.data
    },
    {
      onSuccess: (data) => {
        if (data.accept) {
          handleCancel()
        }
        showMessage(data.message, data.accept)
        qc.invalidateQueries([queryNames.LOTS])
      },
      onError: baseErrorHandler,
    }
  )
}

// update lot
export function useUpdateLot(handleCancel: () => void) {
  const qc = useQueryClient()
  return useMutation(
    async (data: { lot: Lot; id: number }) => {
      const res = await $api.put(
        `${lotControllerEndpoints.UPDATE}/${data.id}`,
        data.lot
      )
      return res.data
    },
    {
      onSuccess: (data) => {
        if (data.accept) {
          handleCancel()
        }
        showMessage(data.message, data.accept)
        qc.invalidateQueries([queryNames.LOTS])
      },
      onError: baseErrorHandler,
    }
  )
}

export function useClientConfirm() {
  const qc = useQueryClient()

  return useMutation(
    async (id: number) => {
      const res = await $api.get(clientEndpoints.CLIENT_CONFIRM + '/' + id)
      return res.data
    },
    {
      onSuccess: (data) => {
        showMessage(data.message, data.accept)
        qc.invalidateQueries([queryNames.GET_ALL_CLIENT])
      },
      onError: baseErrorHandler,
    }
  )
}

// make payment
export function useMakePayment(setVisible?: (value: boolean) => void) {
  const location = useLocation()
  const qc = useQueryClient()
  return useMutation(
    async ({
      data,
      type,
    }: {
      data: {
        clientId: string | undefined
        amount: string | undefined
        paymentType: string
      }
      type: string
    }) => {
      const res = await $api.post(
        `${paymentEndpoints.INDEX}/${type.toLocaleLowerCase()}`,
        data
      )
      return res.data
    },
    {
      onSuccess: (data) => {
        showMessage(data.message, data.accept)
        setVisible && setVisible(false)
        qc.invalidateQueries([queryNames.GET_ALL_PAYMENT, location.search])
        qc.invalidateQueries(queryNames.USERS_PAYMENTS)
      },
      onError: baseErrorHandler,
    }
  )
}

// cancel lot
export function useCancelLot() {
  const qc = useQueryClient()
  return useMutation(
    async (id: number) => {
      const res = await $api.put(`${lotControllerEndpoints.CANCEL}/${id}`)
      return res.data
    },
    {
      onSuccess: (data) => {
        showMessage(data?.message, data?.accept)
        qc.invalidateQueries(queryNames.LOTS)
      },
      onError: (err) => {
        baseErrorHandler(err)
      },
    }
  )
}

// get info of user with passport
export function useGetInfoWithPassport(formInstance: FormInstance, type: string) {
  return useMutation(
    async (data: {
      passportSeria: string
      passportNumber: string
      birthday: string
    }) => {
      const res = await axios.get(
        `https://api.online-mahalla.uz/api/v1/public/tax/passport?series=${data.passportSeria}&number=${data.passportNumber}&birth_date=${data.birthday}`
      )
      return res?.data?.data?.info?.data
    },
    {
      onSuccess: (data) => {
        formInstance.setFieldsValue({
          [type]: data?.full_name,
          [`${type}_passport_given_date`]: dayjs(
            data?.given_date,
            'YYYY-MM-DD'
          ).format('DD.MM.YYYY'),
          [`${type}_passport_given_place`]: data?.given_place,
          [`${type}_address`]: data?.address,
        })
      },
      onError: () => {
        message.error('Xatolik!')
      },
    }
  )
}

// create private contarct
export function useCreateUpdatePrivateContract(
  setVisible: (visible: boolean) => void
) {
  const qc = useQueryClient()

  return useMutation(
    async (data: ContractCreatePayloadData) => {
      const res = await $api.post(privateContractEndpoints.INDEX, data)
      return res.data
    },
    {
      onSuccess: (data) => {
        showMessage(data?.message, data?.accept)
        qc.invalidateQueries([queryNames.CONTRACTS])
        setVisible(false)
      },
      onError: (err) => {
        baseErrorHandler(err)
      },
    }
  )
}

// download file
export function useDownloadFile() {
  return useMutation(
    async (data: { path: string; name: string }) => {
      const res = await $api.get(data?.path, { responseType: 'blob' })
      const downloadUrl = window.URL.createObjectURL(new Blob([res.data]))
      const link = document.createElement('a')
      link.href = downloadUrl
      link.setAttribute('download', `${data?.name}`)
      document.body.appendChild(link)
      link.click()
      link.remove()
    },
    {
      onError: () => {
        message.error('Xatolik!')
      },
    }
  )
}

// update contracts and protocols
export function useUpdateContractsProtocols() {
  return useMutation(
    async (id: number) => {
      const res = await $api.put(`${contractEndpoints.UPDATE}?lotId=${id}`)
      return res.data
    },
    {
      onSuccess: (data) => {
        showMessage(data?.message, data?.accept)
      },
      onError: (err) => {
        baseErrorHandler(err)
      },
    }
  )
}
