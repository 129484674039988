import { Button, Collapse, Drawer, Form, Radio, Space } from "antd";
import dayjs from "dayjs";
import queryString from "query-string";
import React, { FC } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CaretDownIcon from "../../assets/icon/CaretDownIcon";
import DatePicker from "../datepicker/DatePicker";

const { Panel } = Collapse;

interface PaymentFilterProps {
  visible: boolean;
  onClose: (e: boolean) => void;
}

const PaymentFilter: FC<PaymentFilterProps> = ({ onClose, visible }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const handleClose = () => {
    onClose(false);
  };

  const onFinish = (values: any) => {
    navigate(
      location.pathname +
        "?" +
        queryString.stringify({
          ...queryString.parse(location.search),
          typeOfPayment: values?.typeOfPayment ? values?.typeOfPayment : "",
          paymentStatus: values?.paymentStatus ? values?.paymentStatus : "",
          outcome: values?.outcome ? values?.outcome : "",
          goal: values?.goal ? values?.goal : "",
          date: values?.date
            ? dayjs(values?.date, "DD.MM.YYYY").format("DD.MM.YYYY")
            : "",
          page: 1,
        })
    );
  };

  const onReset = () => {
    navigate(
      location.pathname +
        "?" +
        queryString.stringify({
          page: 1,
        })
    );
  };

  return (
    <Drawer
      title={false}
      closable={false}
      placement="right"
      onClose={handleClose}
      visible={visible}
      width={236}
      className="lotsFilter"
    >
      <Form form={form} onFinish={onFinish} onReset={onReset}>
        <div className="lotsFilter_header">
          <h1>Filter</h1>
          <div>
            <Button htmlType="reset" type="link">
              Tozalash
            </Button>
            <Button htmlType="submit" type="link">
              Saqlash
            </Button>
          </div>
        </div>
        {/* <div className="lotsFilter_search">
          <Form.Item name={"search"} noStyle>
            <Input
              className="searchInput"
              prefix={<SearchIcon />}
              placeholder="Qidirish..."
            />
          </Form.Item>
        </div> */}
        <Collapse
          defaultActiveKey={["1"]}
          expandIconPosition="right"
          expandIcon={({ isActive }) => (
            <span style={{ transform: `rotet(${isActive ? 90 : 0}deg)` }}>
              <CaretDownIcon />
            </span>
          )}
        >
          <Panel header="To'lov turi" key="1">
            <Form.Item noStyle name={"typeOfPayment"}>
              <Radio.Group>
                <Space direction="vertical">
                  <Radio value={"CASH"}>Naqd</Radio>
                  <Radio value={"CARD"}>Kart</Radio>
                  <Radio value={"BANK"}>Bank</Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
          </Panel>
          <Panel header="To'lov holati" key="2">
            <Form.Item noStyle name={"paymentStatus"}>
              <Radio.Group>
                <Space direction="vertical">
                  <Radio value={"UNACCEPTED"}>Qaytarish</Radio>
                  <Radio value={"ACCEPTED"}>Qabul qilingan</Radio>
                  <Radio value={"WAITING"}>Kutilmoqda</Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
          </Panel>
          <Panel header="Natija" key="3">
            <Form.Item noStyle name={"outcome"}>
              <Radio.Group>
                <Space direction="vertical">
                  <Radio value={"false"}>Kirim</Radio>
                  <Radio value={"true"}>Chiqim</Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
          </Panel>
          <Panel header="Maqsad" key="4">
            <Form.Item noStyle name={"goal"}>
              <Radio.Group>
                <Space direction="vertical">
                  <Radio value={"REPARATION"}>Tuzish</Radio>
                  <Radio value={"REFUNDED"}>Qaytarish</Radio>
                  <Radio value={"AUCTION"}>Auksion</Radio>
                  <Radio value={"BALANCE"}>Balans</Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
          </Panel>
          <Panel header="Sana" key="5">
            <Form.Item noStyle name={"date"}>
              <DatePicker
                placeholder="kk.oo.yyyy"
                format={"DD.MM.YYYY"}
                className="customInput"
              />
            </Form.Item>
          </Panel>
        </Collapse>
      </Form>
    </Drawer>
  );
};

export default PaymentFilter;
