import { Button, Form, Input } from "antd";
import React from "react";
import styles from "./auksionSetting.module.scss";

const AuksionSetting = () => {
  const onFinish = (values: any) => {
    console.log(values);
  };
  return (
    <div className={styles.auksionSetting}>
      <h1>Auksion sozlamalari</h1>
      <Form
        layout="vertical"
        onFinish={onFinish}
        className="auksionSettingForm"
      >
        <div className={styles.auksionSetting_container}>
          <div>
            <Form.Item
              label="Oshib borish qiymati (%)"
              name={"increase"}
              rules={[{ required: true }]}
            >
              <Input type="number" className="customInput" />
            </Form.Item>
          </div>
          <div>
            <Form.Item
              label="Boshlang’ich badal qiymati (%)"
              name={"primaryIncrease"}
              rules={[{ required: true }]}
            >
              <Input type="number" className="customInput" />
            </Form.Item>
          </div>
        </div>
        <Button
          type="primary"
          htmlType="submit"
          className="saveButton"
          style={{ float: "right" }}
        >
          Saqlash
        </Button>
      </Form>
    </div>
  );
};

export default AuksionSetting;
