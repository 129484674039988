import React from "react";

const SketchLogoIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.75 3.75H17.25L22.5 9.75L12 21L1.5 9.75L6.75 3.75Z"
        stroke="#888888"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.6027 9.75L11.9996 21L7.39648 9.75L11.9996 3.75L16.6027 9.75Z"
        stroke="#888888"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.5 9.75H22.5"
        stroke="#888888"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SketchLogoIcon;
