import React from "react";

const QrCodeIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.75 4.5H5.25C4.83579 4.5 4.5 4.83579 4.5 5.25V9.75C4.5 10.1642 4.83579 10.5 5.25 10.5H9.75C10.1642 10.5 10.5 10.1642 10.5 9.75V5.25C10.5 4.83579 10.1642 4.5 9.75 4.5Z"
        stroke="#888888"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.75 13.5H5.25C4.83579 13.5 4.5 13.8358 4.5 14.25V18.75C4.5 19.1642 4.83579 19.5 5.25 19.5H9.75C10.1642 19.5 10.5 19.1642 10.5 18.75V14.25C10.5 13.8358 10.1642 13.5 9.75 13.5Z"
        stroke="#888888"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.75 4.5H14.25C13.8358 4.5 13.5 4.83579 13.5 5.25V9.75C13.5 10.1642 13.8358 10.5 14.25 10.5H18.75C19.1642 10.5 19.5 10.1642 19.5 9.75V5.25C19.5 4.83579 19.1642 4.5 18.75 4.5Z"
        stroke="#888888"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.5 13.5V16.5"
        stroke="#888888"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.5 19.5H16.5V13.5"
        stroke="#888888"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.5 15H19.5"
        stroke="#888888"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.5 18V19.5"
        stroke="#888888"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default QrCodeIcon;
