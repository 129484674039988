import React from "react";
import { NavLink } from "react-router-dom";
import MainHeader from "../../components/headers/MainHeader";
import settingLink from "../../components/setting/settingLink";
import SettingsRouter from "../../routing/setting/SettingRouter";
import styles from "./setting.module.scss";

const Settings = () => {
  return (
    <>
      <MainHeader>Sozlamalar</MainHeader>
      <div className={styles.setting}>
        <div className={styles.setting_navbar}>
          {settingLink.map((item, index) => {
            return (
              <NavLink to={item.to} key={index} className="settingLink">
                {item.icon && <item.icon />}
                <span>{item.name}</span>
              </NavLink>
            );
          })}
        </div>
        <div className={styles.setting_container}>
          <SettingsRouter />
        </div>
      </div>
    </>
  );
};

export default Settings;
