import AdminSetting from '../../pages/settings/admin-setting/AdminSetting'
import AuksionSetting from '../../pages/settings/auksion-setting/AuksionSetting'
import KategorySetting from '../../pages/settings/kategory-setting/KategorySetting'
import KompanySetting from '../../pages/settings/kompany-setting/KompanySetting'
import { Permissions } from '../../utils/constants/permissions'
import { SettingsPaths } from './settingPath'

const routes = [
  {
    path: SettingsPaths.AUKSION_SETTING,
    component: AuksionSetting,
    exact: true,
    permissions: Permissions.ROLE_SUPPER_ADMIN,
  },
  {
    path: SettingsPaths.ADMIN_SETTING,
    component: AdminSetting,
    exact: true,
    permissions: Permissions.ROLE_SUPPER_ADMIN,
  },
  {
    path: SettingsPaths.KOMPANY_SETTING,
    component: KompanySetting,
    exact: true,
    permissions: Permissions.ROLE_SUPPER_ADMIN,
  },
  {
    path: SettingsPaths.KATEGORY_SETTING,
    component: KategorySetting,
    exact: true,
    permissions: Permissions.ROLE_SUPPER_ADMIN,
  },
]

// const settingRoutes: typeof routes = []
const settingRoutes: typeof routes = routes

// if (typeof user === 'object' && user) {
//   routes.forEach((route) => {
//     if (route.permission) {
//       if (user.role.permissions.includes(route.permission)) {
//         settingRoutes.push(route)
//       }
//     } else {
//       settingRoutes.push(route)
//     }
//   })
// }

export default settingRoutes
