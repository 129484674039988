import { Button, Modal } from 'antd'
import React, { FC, useState } from 'react'

interface UserAppendModalProps {
  visible: boolean
  setVisible: (visible: boolean) => void
}

const UserAppendModal: FC<UserAppendModalProps> = ({ visible, setVisible }) => {
  const [activeTitle, setActiveTitle] = useState(false)
  const handleCansel = () => {
    setVisible(false)
  }

  return (
    <Modal
      title="Foydalanuvchi qo’shish"
      visible={visible}
      onCancel={handleCansel}
      wrapClassName="user-append-modal"
      footer={false}
      width={564}
      centered
    >
      <div className="user-append-modal_title">
        <Button
          onClick={() => setActiveTitle(true)}
          className={activeTitle ? 'active' : ''}
        >
          Jismoniy shaxs
        </Button>
        <Button
          onClick={() => setActiveTitle(false)}
          className={activeTitle ? '' : 'active'}
        >
          Yuridik shaxs
        </Button>
      </div>
      <div className="user-append-modal_header">
        {activeTitle ? (
          <>
            <p>Raqamni tasdiqlash</p>
            <p>Qo’shimcha ma’lumotlar</p>
          </>
        ) : (
          <>
            <p>Raqamni tasdiqlash</p>
            <p>Qo’shimcha ma’lumotlar</p>
            <p>Bank ma’lumotlari</p>
          </>
        )}
      </div>
    </Modal>
  )
}

export default UserAppendModal
