import React from "react";

const TickCircleIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.0003 18.3333C14.5837 18.3333 18.3337 14.5833 18.3337 9.99999C18.3337 5.41666 14.5837 1.66666 10.0003 1.66666C5.41699 1.66666 1.66699 5.41666 1.66699 9.99999C1.66699 14.5833 5.41699 18.3333 10.0003 18.3333Z"
        stroke="#3772FF"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.66699 10.6917L9.02533 13.05L13.7503 8.33334"
        stroke="#3772FF"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default TickCircleIcon;
