import React from "react";
import { Route, Routes } from "react-router-dom";
import settingRoutes from "./settingRoutes";

const SettingsRouter = () => {
  return (
    <Routes>
      {settingRoutes.map((route, index) => (
        <Route
          key={index}
          path={route.exact ? route.path : `${route.path}/*`}
          element={<route.component />}
        />
      ))}
      <Route path={"*"} element={""} />
    </Routes>
  );
};

export default SettingsRouter;
