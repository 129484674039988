import React from "react";

const UserCircleGearIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 15C14.0711 15 15.75 13.3211 15.75 11.25C15.75 9.17893 14.0711 7.5 12 7.5C9.92893 7.5 8.25 9.17893 8.25 11.25C8.25 13.3211 9.92893 15 12 15Z"
        stroke="#888888"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.98145 18.6937C6.54574 17.5823 7.40678 16.6487 8.46914 15.9967C9.5315 15.3446 10.7537 14.9994 12.0002 14.9994C13.2467 14.9994 14.4689 15.3446 15.5312 15.9967C16.5936 16.6487 17.4547 17.5823 18.0189 18.6937"
        stroke="#888888"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.75 6.75C19.5784 6.75 20.25 6.07843 20.25 5.25C20.25 4.42157 19.5784 3.75 18.75 3.75C17.9216 3.75 17.25 4.42157 17.25 5.25C17.25 6.07843 17.9216 6.75 18.75 6.75Z"
        stroke="#888888"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.75 3.75V2.625"
        stroke="#888888"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.4471 4.5L16.4814 3.9375"
        stroke="#888888"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.4471 6L16.4814 6.5625"
        stroke="#888888"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.75 6.75V7.875"
        stroke="#888888"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.0527 6L21.0184 6.5625"
        stroke="#888888"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.0527 4.5L21.0184 3.9375"
        stroke="#888888"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.9344 10.9219C20.9799 11.2794 21.0018 11.6396 21 12C21 13.78 20.4722 15.5201 19.4832 17.0001C18.4943 18.4802 17.0887 19.6337 15.4442 20.3149C13.7996 20.9961 11.99 21.1743 10.2442 20.8271C8.49836 20.4798 6.89472 19.6226 5.63604 18.364C4.37737 17.1053 3.5202 15.5017 3.17294 13.7558C2.82567 12.01 3.0039 10.2004 3.68509 8.55586C4.36628 6.91132 5.51983 5.50572 6.99987 4.51678C8.47991 3.52785 10.22 3.00001 12 3.00001C12.2786 2.99825 12.5571 3.01077 12.8344 3.03751"
        stroke="#888888"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default UserCircleGearIcon;
