import Contract from '../../pages/contract/Contract'
import Lots from '../../pages/lots/Lots'
import Payments from '../../pages/payments/Payments'
import Protocols from '../../pages/protocols/Protocols'
import Settings from '../../pages/settings/Settings'
import Statistika from '../../pages/statistika/Statistika'
import Users from '../../pages/users/Users'
import UsersAll from '../../pages/users/UsersAll'
import { LocalStorage } from '../../service/LocalStorage'
import { USER_KEY } from '../../utils/constants/localStorageKeys'
import { Permissions } from '../../utils/constants/permissions'
import { User } from '../../utils/models/User'
import { MainPaths } from './mainPaths'

const user = LocalStorage.get<User>(USER_KEY)

const routes = [
  {
    path: MainPaths.INDEX,
    component: Lots,
    exact: true,
    permission: Permissions.ROLE_SUPPER_ADMIN,
  },
  {
    path: MainPaths.STATISTIKA,
    component: Statistika,
    exact: true,
    permission: Permissions.ROLE_SUPPER_ADMIN,
  },
  {
    path: MainPaths.CONTRACT,
    component: Contract,
    exact: true,
    permission: Permissions.ROLE_SUPPER_ADMIN,
  },
  {
    path: MainPaths.USERS_ID,
    component: Users,
    exact: false,
    permission: Permissions.ROLE_SUPPER_ADMIN,
  },
  {
    path: MainPaths.USERS,
    component: UsersAll,
    exact: false,
    permission: Permissions.ROLE_SUPPER_ADMIN,
  },
  {
    path: MainPaths.PAYMENTS,
    component: Payments,
    exact: true,
    permission: Permissions.ROLE_SUPPER_ADMIN,
  },
  {
    path: MainPaths.SETTINGS,
    component: Settings,
    exact: false,
    permission: Permissions.ROLE_SUPPER_ADMIN,
  },
  {
    path: MainPaths.PROTOCOLS,
    component: Protocols,
    exact: true,
    permission: Permissions.ROLE_SUPPER_ADMIN,
  },
]

const mainRoutes: typeof routes = routes

if (user && typeof user === 'object') {
  routes.forEach((route) => {
    if (route.permission) {
      if (user.roles.includes(route.permission)) {
        mainRoutes.push(route)
      }
    } else {
      mainRoutes.push(route)
    }
  })
}

export default mainRoutes
