import React from 'react'

const DownloadIcon: React.FC = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.75 10H17.5C17.6658 10 17.8247 10.0658 17.9419 10.1831C18.0592 10.3003 18.125 10.4592 18.125 10.625V15.625C18.125 15.7908 18.0592 15.9497 17.9419 16.0669C17.8247 16.1842 17.6658 16.25 17.5 16.25H2.5C2.33424 16.25 2.17527 16.1842 2.05806 16.0669C1.94085 15.9497 1.875 15.7908 1.875 15.625V10.625C1.875 10.4592 1.94085 10.3003 2.05806 10.1831C2.17527 10.0658 2.33424 10 2.5 10H6.25"
        stroke="#023047"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 1.875V10"
        stroke="#023047"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.25 6.25L10 10L13.75 6.25"
        stroke="#023047"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.6875 13.75C15.0327 13.75 15.3125 13.4702 15.3125 13.125C15.3125 12.7798 15.0327 12.5 14.6875 12.5C14.3423 12.5 14.0625 12.7798 14.0625 13.125C14.0625 13.4702 14.3423 13.75 14.6875 13.75Z"
        fill="#023047"
      />
    </svg>
  )
}

export default DownloadIcon
