import { Button, Form, Input, Modal } from 'antd'
import { ValidateErrorEntity } from 'rc-field-form/lib/interface'
import React, { ChangeEvent, useState } from 'react'
import ReactInputMask from 'react-input-mask'

import CalendarIcon from '../../assets/icon/CalendarIcon'
import CloseIcon from '../../assets/icon/CloseIcon'
import CustomDatePicker from '../../utils/helper/CustomDatePicker'
import GetInfoWithPassport from './GetInfoWithPassport'

import styles from './CreateContractModal.module.scss'
import ProductInfo from './ProductInfo'
import { getInfoWithPassportTypes } from '../../utils/constants/GetInfoWithPassportTypes'
import { personTypes } from '../../utils/constants/PersonTypes'
import {
  ContractCreateFormFields,
  ContractCreatePayloadData,
} from '../../utils/models/ContractCreateFormFields'
import { useCreateUpdatePrivateContract } from '../../queries/mutation'
import { customCurrency } from '../../utils/helper/customCurrency'
import { parseLocaledString } from '../../utils/helper/parseLocaledString'
import { validateDateString } from '../../utils/helper/validatorDateString'

interface IProps {
  visible: boolean
  setVisible: (visible: boolean) => void
}

const CreateContractModal: React.FC<IProps> = ({ visible, setVisible }) => {
  const { Item } = Form
  const [formInstance] = Form.useForm()
  const [onFinishErr, setOnFinishErr] = useState<string[]>([])
  const [productPrice, setProductPrice] = useState<string | undefined>('')
  const [salerType, setSalerType] = useState(personTypes.PHYSICAL)
  const [customerType, setCustomerType] = useState(personTypes.PHYSICAL)
  const createUpdatePrivateContract = useCreateUpdatePrivateContract(setVisible)

  // after close
  const afterClose = () => {
    formInstance.resetFields()
    setOnFinishErr([])
    setProductPrice('')
  }

  // on cancel
  const onCancel = () => {
    setVisible(false)
    afterClose()
  }

  // on finish
  const onFinish = (fields: ContractCreateFormFields) => {
    setOnFinishErr([])

    let data: ContractCreatePayloadData = {
      date: fields?.contract_date,
      productPrice: +productPrice!,
      productCategory: fields?.product_category,
      productDate: fields?.product_date,
      productName: fields?.product_description,
      buyerType: customerType,
      salesmanType: salerType,
      auctionAmount: parseLocaledString(fields?.broker_price),
    }

    // saler
    let saler = {}
    switch (salerType) {
      case personTypes.PHYSICAL: {
        saler = {
          salesmanFullName: fields?.saler,
          salesmanAddress: fields?.saler_address,
          salesmanBirthday: fields?.saler_birthday,
          salesmanPassportDate: fields?.saler_passport_given_date,
          salesmanPassportPlace: fields?.saler_passport_given_place,
          salesmanPassport:
            fields?.saler_passport_seria?.toUpperCase() +
            fields?.saler_passport_number,
        }
        break
      }
      case personTypes.LEGAL: {
        saler = {
          salesmanAccountNumber: fields?.saler_account_number,
          salesmanBank: fields?.saler_bank,
          salesmanCompanyDirector: fields?.saler_company_director,
          salesmanCompanyName: fields?.saler_company_name,
          salesmanInn: fields?.saler_inn,
          salesmanMfo: fields?.saler_mfo,
          salesmanAddress: fields?.saler_company_address,
        }
        break
      }
    }

    // customer
    let customer = {}
    switch (customerType) {
      case personTypes.PHYSICAL: {
        customer = {
          buyerFullName: fields?.customer,
          buyerAddress: fields?.customer_address,
          buyerBirthday: fields?.customer_birthday,
          buyerPassportDate: fields?.customer_passport_given_date,
          buyerPassportPlace: fields?.customer_passport_given_place,
          buyerPassport:
            fields?.customer_passport_seria?.toUpperCase() +
            fields?.customer_passport_number,
        }
        break
      }
      case personTypes.LEGAL: {
        customer = {
          buyerAccountNumber: fields?.customer_account_number,
          buyerBank: fields?.customer_bank,
          buyerCompanyDirector: fields?.customer_company_director,
          buyerCompanyName: fields?.customer_company_name,
          buyerInn: fields?.customer_inn,
          buyerMfo: fields?.customer_mfo,
          buyerAddress: fields?.customer_company_address,
        }
        break
      }
    }

    data = { ...data, ...saler, ...customer }

    createUpdatePrivateContract.mutate(data)
  }

  // on finish failed
  const onFinishFailed = (data: ValidateErrorEntity) => {
    let errorList: string[] = []
    data.errorFields?.map((item) => errorList.push(item?.name[0] as string))

    setOnFinishErr(errorList)
  }

  // add error classname
  const addErrClassName = (itemName: string) => {
    if (onFinishErr.includes(itemName)) {
      return 'error_input'
    }
  }

  // handle auction amount
  const handleAuctionAmount = (e: ChangeEvent<HTMLInputElement> | undefined) => {
    formInstance.setFieldsValue({
      broker_price: customCurrency(e?.target?.value || ''),
    })
  }

  return (
    <Modal
      centered
      visible={visible}
      footer={false}
      closable={false}
      className={styles.contractModal}
      afterClose={afterClose}
    >
      <div className="header">
        <h3>Shartnoma tuzish</h3>
        <button onClick={onCancel}>
          <CloseIcon />
        </button>
      </div>
      <div className="body">
        <Form
          form={formInstance}
          layout="vertical"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <h4 className="little_title">Shartnoma ma’lumotlari</h4>
          <div className="contract_date customDatePickerCont">
            <Item
              name="contract_date"
              label="Shartnoma sanasi"
              rules={[
                { required: true },
                {
                  validator: (_, value) => {
                    if (value) {
                      formInstance.setFieldsValue({
                        contract_date: validateDateString(value, true),
                      })
                    }
                    return Promise.resolve()
                  },
                },
              ]}
              className={addErrClassName('contract_date')}
            >
              <ReactInputMask className="ant-input" mask="99.99.9999" />
            </Item>
            <span className="calendar_icon">
              <CustomDatePicker
                formInstance={formInstance}
                formItemName="contract_date"
              >
                <CalendarIcon />
              </CustomDatePicker>
            </span>
          </div>
          <GetInfoWithPassport
            formInstance={formInstance}
            onFinishErr={onFinishErr}
            setOnFinishErr={setOnFinishErr}
            type={getInfoWithPassportTypes.SALER}
            salerType={salerType}
            setSalerType={setSalerType}
            customerType={customerType}
            setCustomerType={setCustomerType}
          />
          <ProductInfo
            onFinishErr={onFinishErr}
            amount={productPrice}
            setAmount={setProductPrice}
            formInstance={formInstance}
          />
          <GetInfoWithPassport
            salerType={salerType}
            setSalerType={setSalerType}
            customerType={customerType}
            setCustomerType={setCustomerType}
            formInstance={formInstance}
            onFinishErr={onFinishErr}
            setOnFinishErr={setOnFinishErr}
            type={getInfoWithPassportTypes.CUSTOMER}
          />
          <Item
            name="broker_price"
            label="Vositachi to'lovi"
            rules={[{ required: true }]}
            className={addErrClassName('broker_price')}
          >
            <Input onChange={handleAuctionAmount} />
          </Item>
          {/* for working submit when press enter key */}
          <Button htmlType="submit" className="dn" />
        </Form>
      </div>
      <div className="footer">
        <Button className="customWhiteButton" onClick={onCancel}>
          Yopish
        </Button>
        <Button
          className="createButton"
          type="primary"
          onClick={() => formInstance.submit()}
          loading={createUpdatePrivateContract.isLoading}
        >
          Saqlash
        </Button>
      </div>
    </Modal>
  )
}

export default CreateContractModal
