import Login from "../pages/login/Login";
import MainRouter from "./main/MainRouter";
import { rootPaths } from "./rootPaths";

export const rootRoutes = [
  {
    path: rootPaths.INDEX,
    component: Login,
    exact: true,
  },
  {
    path: rootPaths.MAIN,
    component: MainRouter,
    exact: false,
  },
  {
    path: "*",
    component: "404",
    exact: true,
  },
];
