import { Layout } from "antd";
import React from "react";
import { Route, Routes } from "react-router-dom";
import NavBar from "../../components/navbars/main/NavBar";
import mainRoutes from "./mainRoutes";

const { Content } = Layout;

const MainRouter = () => {
  return (
    <div>
      <Layout style={{ height: "100vh", flexDirection: "row" }}>
        <NavBar />
        <Layout>
          <Content
            style={{
              overflowY: "auto",
              height: "50vh",
            }}
          >
            <Routes>
              {mainRoutes.map((route) => (
                <Route
                  element={<route.component />}
                  path={route.exact ? route.path : `${route.path}/*`}
                  key={route.path}
                />
              ))}
            </Routes>
          </Content>
        </Layout>
      </Layout>
    </div>
  );
};

export default MainRouter;
