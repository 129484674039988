import React, { FC } from "react";
import { Pagination } from "antd";
import "./paginationGlobal.scss";

interface PaginationProps {
  total?: number;
  pageSize?: number;
  onChange?: ((page: number, pageSize: number) => void) | undefined;
}

const PaginationGlobal: FC<PaginationProps> = ({
  onChange,
  pageSize = 1,
  total = 1,
}) => (
  <Pagination
    onChange={onChange}
    defaultCurrent={1}
    total={total}
    pageSize={pageSize}
    showSizeChanger
    className="customPagination"
  />
);

export default PaginationGlobal;
