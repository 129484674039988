import { Button, Form, Input, Modal } from "antd";
import React, { FC, useEffect, useState } from "react";
import "../../../pages/settings/admin-setting/adminSetting.module.scss";
import { useAdminCreate, useAdminEdit } from "../../../queries/mutation";

interface AdminSettingModalProps {
  obj: {
    visible: boolean;
    id: number | null;
    fullName: string;
    phoneNumber: string;
    username: string;
    password: string;
  };
  setVisible: React.Dispatch<
    React.SetStateAction<{
      visible: boolean;
      id: null;
    }>
  >;
}

const AdminSettingModal: FC<AdminSettingModalProps> = ({ obj, setVisible }) => {
  const [form] = Form.useForm();
  const [visiblePassword, setVisiblePassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const createAdmin = useAdminCreate();
  const editAdmin = useAdminEdit(obj.id as number);

  useEffect(() => {
    form.setFieldsValue({
      fullName: obj.fullName,
      phoneNumber: obj.phoneNumber,
      username: obj.username,
      password: "",
    });
    if (obj.id) {
      setVisiblePassword(true);
    } else {
      setVisiblePassword(false);
    }
    // eslint-disable-next-line
  }, [obj.visible]);

  const handleClose = () => {
    setVisible({ visible: false, id: null });
  };

  const onFinish = (values: any) => {
    if (obj.id) {
      editAdmin.mutate({
        fullName: values.fullName,
        phoneNumber: values.phoneNumber,
        username: values.username,
        password: !visiblePassword ? values.password : obj.password,
      });
    } else {
      createAdmin.mutate({
        fullName: values.fullName,
        phoneNumber: values.phoneNumber,
        password: values.password,
        username: values.username,
      });
    }
    setLoading(true);
  };

  useEffect(() => {
    setLoading(false);
    if (createAdmin.isSuccess || editAdmin.isSuccess) {
      handleClose();
    }
    // eslint-disable-next-line
  }, [
    createAdmin.isSuccess,
    createAdmin.isError,
    editAdmin.isSuccess,
    editAdmin.isError,
  ]);

  return (
    <Modal
      title={obj.id ? "Admin tahrirlash" : "Admin qo’shish"}
      visible={obj.visible}
      onCancel={handleClose}
      width={399}
      footer={false}
      wrapClassName="adminSettingModal"
    >
      <Form
        layout="vertical"
        onFinish={onFinish}
        form={form}
        autoComplete={"false"}
      >
        <Form.Item
          label="Xodimning F.I.Sh"
          name={"fullName"}
          rules={[{ required: true }]}
        >
          <Input className="customInput" />
        </Form.Item>
        <Form.Item
          label="Telefon raqami"
          name={"phoneNumber"}
          rules={[{ required: true }]}
        >
          <Input className="customInput" />
        </Form.Item>
        <Form.Item label="Login" name={"username"} rules={[{ required: true }]}>
          <Input className="customInput" />
        </Form.Item>
        {visiblePassword ? (
          <Form.Item>
            <p
              style={{ color: "#4A07D7", cursor: "pointer" }}
              onClick={(e: any) => {
                setVisiblePassword(false);
                form.setFieldsValue({ password: "" });
              }}
            >
              Parolni o’zgartirish
            </p>
          </Form.Item>
        ) : (
          <>
            <Form.Item
              label="Parol"
              name={"passwords"}
              style={{
                opacity: 0,
                position: "absolute",
                pointerEvents: "none",
              }}
            >
              <Input.Password className="customInput" />
            </Form.Item>
            <Form.Item
              label="Parol"
              name={"password"}
              rules={[{ required: true }]}
            >
              <Input.Password className="customInput" />
            </Form.Item>
          </>
        )}
        <Button
          type="primary"
          className="saveButton"
          htmlType="submit"
          style={{ width: "100%" }}
          loading={loading}
        >
          Saqlash
        </Button>
      </Form>
    </Modal>
  );
};

export default AdminSettingModal;
