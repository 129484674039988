import React, { FC } from "react";
interface ImageDefaultProps {
  width?: string;
  height?: string;
}

const ImageDefault: FC<ImageDefaultProps> = ({ height = 41, width = 41 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 41 41`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M34.192 7.59824H6.3318C5.6324 7.59824 5.06543 8.16521 5.06543 8.86461V31.6593C5.06543 32.3587 5.6324 32.9257 6.3318 32.9257H34.192C34.8914 32.9257 35.4584 32.3587 35.4584 31.6593V8.86461C35.4584 8.16521 34.8914 7.59824 34.192 7.59824Z"
        stroke="#B8B8B8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.06543 26.5938L13.0277 18.6315C13.1456 18.5119 13.286 18.4169 13.4409 18.352C13.5958 18.2871 13.7621 18.2537 13.93 18.2537C14.098 18.2537 14.2642 18.2871 14.4191 18.352C14.574 18.4169 14.7145 18.5119 14.8323 18.6315L21.8924 25.6916C22.0102 25.8112 22.1506 25.9062 22.3056 25.9711C22.4605 26.0359 22.6267 26.0693 22.7946 26.0693C22.9626 26.0693 23.1288 26.0359 23.2837 25.9711C23.4386 25.9062 23.5791 25.8112 23.6969 25.6916L26.9578 22.4306C27.0757 22.311 27.2161 22.216 27.371 22.1511C27.5259 22.0862 27.6922 22.0528 27.8601 22.0528C28.0281 22.0528 28.1943 22.0862 28.3492 22.1511C28.5041 22.216 28.6446 22.311 28.7624 22.4306L35.4584 29.1266"
        stroke="#B8B8B8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.6945 17.7292C25.7436 17.7292 26.594 16.8788 26.594 15.8297C26.594 14.7806 25.7436 13.9301 24.6945 13.9301C23.6454 13.9301 22.7949 14.7806 22.7949 15.8297C22.7949 16.8788 23.6454 17.7292 24.6945 17.7292Z"
        fill="#B8B8B8"
      />
    </svg>
  );
};

export default ImageDefault;
