import { Button, FormInstance, Popover } from 'antd'
import dayjs, { Dayjs } from 'dayjs'
import React, { useState } from 'react'
import Calendar from '../../components/calendar/Calendar'

type props = {
  children: React.ReactNode
  formInstance: FormInstance
  formItemName: string
}

const CustomDatePicker: React.FC<props> = ({
  children,
  formInstance,
  formItemName,
}) => {
  const [visible, setVisible] = useState(false)

  // open popover
  const openPopover = () => {
    setVisible(true)
  }

  // popover content
  const popoverContent = () => {
    let dateVar: string

    // change
    const onChange = (date: Dayjs) => {
      dateVar = dayjs(date).format('DD.MM.YYYY')
    }

    // on finish
    const onFinish = () => {
      formInstance.setFieldsValue({
        [formItemName]: dateVar || dayjs().format('DD.MM.YYYY'),
      })
      setVisible(false)
    }

    return (
      <div className="w300">
        <Calendar fullscreen={false} onChange={onChange} />
        <div className="d-f j-c-r">
          <Button type="primary" onClick={onFinish}>
            OK
          </Button>
        </div>
      </div>
    )
  }

  return (
    <Popover title={''} visible={visible} content={popoverContent()} trigger="click">
      <span onClick={openPopover}>{children}</span>
    </Popover>
  )
}

export default CustomDatePicker
