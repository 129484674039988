import axios, { AxiosRequestConfig } from 'axios'
import { TOKEN_KEY, USER_KEY } from '../utils/constants/localStorageKeys'
import { LocalStorage } from './LocalStorage'
import { authEndpoints } from '../utils/constants/apis'
import { User } from '../utils/models/User'

export const BASE_URL = process.env.REACT_APP_BASE_URL

let isRefreshing = false
let failedQueue: any = []

const processQueue = (error: any, token = null) => {
  failedQueue.forEach((prom: any) => {
    if (error) {
      prom.reject(error)
    } else {
      prom.resolve(token)
    }
  })
  failedQueue = []
}

const addToken = (config: AxiosRequestConfig) => {
  const token = LocalStorage.get<string>(TOKEN_KEY)
  if (token) {
    config.headers!.Authorization = `Bearer ${token}`
  }
  return config
}

const resentRequest = async (err: any) => {
  const originalRequest = err?.config
  if (err.response.status === 401 && !originalRequest._retry) {
    if (!LocalStorage.get<string>(TOKEN_KEY)) {
      window.location.replace(window.location.origin)
    }
    if (isRefreshing) {
      // If I'm refreshing the token I send request to a queue
      return new Promise((resolve, reject) => {
        failedQueue.push({ resolve, reject })
      })
        .then((token) => {
          originalRequest.headers.Authorization = `Bearer ${token}`
          return axios(originalRequest)
        })
        .catch((err) => {
          console.log(err)
        })
    }
    // If header of the request has changed, it means I've refreshed the token
    const token = LocalStorage.get<string>(TOKEN_KEY)
    if (originalRequest.headers.Authorization !== `Bearer ${token}`) {
      originalRequest.headers.Authorization = `Bearer ${token}`
      return Promise.resolve(axios(originalRequest))
    }
    originalRequest._retry = true // mark request a retry
    isRefreshing = true // set the refreshing var to true

    // If none of the above, refresh the token and process the queue
    return new Promise((resolve, reject) => {
      axios
        .get(BASE_URL + authEndpoints.REFRESH, {
          withCredentials: true,
        }) // The method that refreshes my token
        .then((response) => {
          const user: User | null | string = LocalStorage.get(USER_KEY)
          let isEqual = true
          if (typeof user === 'object') {
            if (user?.roles?.length !== response.data?.data?.roles?.length) {
              isEqual = false
            }
            user?.roles?.forEach((permission) => {
              if (
                response.data?.data?.roles?.permissions.indexOf(permission) === -1
              ) {
                isEqual = false
              }
            })
          }
          LocalStorage.set(TOKEN_KEY, response.data?.data?.accessToken) // The method that sets my token to localstorage/Redux/whatever
          processQueue(null, response.data?.data?.accessToken) // Resolve queued
          if (!isEqual) {
            LocalStorage.set(USER_KEY, JSON.stringify(response.data?.data))
            window.location.reload()
          }
          originalRequest.headers.Authorization = `Bearer ${response.data?.data?.accessToken}`
          resolve(axios(originalRequest)) // Resolve current
        })
        .catch((err) => {
          LocalStorage.remove(TOKEN_KEY) // The method that removes my token from localstorage/Redux/whatever
          LocalStorage.remove(USER_KEY)
          window.location.replace(window.location.origin)
          processQueue(err, null)
          reject(err)
        })
        .finally(() => {
          isRefreshing = false
        })
    })
  }
  throw err
}

export const $api = axios.create({
  baseURL: BASE_URL,
})

$api.interceptors.request.use(addToken)

$api.interceptors.response.use(undefined, resentRequest)
