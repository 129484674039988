import { Button, Input } from 'antd'
import { parse, stringify } from 'query-string'
import React, { ChangeEvent, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import FilterIcon from '../../assets/icon/FilterIcon'
import SearchIcon from '../../assets/icon/SearchIcon'
import UpdateIcon from '../../assets/icon/UpdateIcon'
import MainHeader from '../../components/headers/MainHeader'
import PaginationGlobal from '../../components/pagination/PaginationGlobal'

import TableCustom from '../../components/table/Table'
import { useDownloadFile, useUpdateContractsProtocols } from '../../queries/mutation'
import { useGetAllProtocols } from '../../queries/queries'
import { OneProtocol } from '../../utils/models/Protocols'

import styles from './Protocols.module.scss'

const Protocols: React.FC = () => {
  const { data, isLoading } = useGetAllProtocols()
  const navigate = useNavigate()
  const location = useLocation()
  const [pageSize, setPageSize] = useState(10)
  const downloadFileMutation = useDownloadFile()
  const updateContractMutation = useUpdateContractsProtocols()

  // change search text
  const changeSearchText = (e: ChangeEvent<HTMLInputElement>) => {
    navigate(
      location.pathname +
        '?' +
        stringify({
          ...parse(location.search),
          page: 1,
          search: e.target.value,
        })
    )
  }

  // download file
  const downloadFile = (path: string, name: string) => {
    downloadFileMutation.mutate({ path, name })
  }

  // update  contract
  const updateContract = (id: number) => {
    updateContractMutation.mutate(id)
  }

  // columns
  const columns = [
    {
      title: '#',
      render: (_: any, __: any, index: number) => {
        return pageSize * (Number(data?.currentPage) - 1) + index + 1
      },
    },
    {
      title: 'LOT RAQAMI',
      align: 'center',
      dataIndex: 'lotNumber',
    },
    {
      title: "AUKSION G'OLIBI",
      dataIndex: 'clientName',
    },
    {
      title: 'SOTILGAN BAHOSI',
      align: 'right',
      render: (record: OneProtocol) => (
        <span> {record?.winningAmount?.toLocaleString()} UZS</span>
      ),
    },
    {
      title: 'SHARTNOMA IMZOLANGANLIGI',
      render: (record: OneProtocol) => (
        <span>{record?.lotStatus ? 'Imzolangan' : 'Imzolanmagan'}</span>
      ),
      align: 'center',
    },
    {
      title: 'YANGILASH',
      render: (record: OneProtocol) => {
        return (
          <span className="cur-p" onClick={() => updateContract(+record?.lotNumber)}>
            <UpdateIcon />
          </span>
        )
      },
      align: 'center',
    },
    {
      title: 'YUKLAB OLISH',
      render: (record: OneProtocol) => {
        return (
          <div className="d-f">
            <Button
              type="primary"
              onClick={() =>
                downloadFile(
                  record?.protocolFile?.absolutePath,
                  record?.protocolFile?.name
                )
              }
            >
              Bayonnoma
            </Button>
            &nbsp;
            <Button
              type="primary"
              onClick={() =>
                downloadFile(
                  record?.contractFile?.absolutePath,
                  record?.contractFile?.name
                )
              }
            >
              Shartnoma
            </Button>
          </div>
        )
      },
      align: 'center',
    },
  ]

  // handle page
  const handlePage = (page: any, size: number) => {
    setPageSize(size)
    navigate(
      location.pathname +
        '?' +
        stringify({
          ...parse(location.search),
          page: page,
          size: size,
        })
    )
  }

  return (
    <>
      <MainHeader>Bayonnamalar</MainHeader>
      <div className={styles.protocols}>
        <div className={styles.protocols_body}>
          <div className={styles.protocols_body_top}>
            <Button className="filterButton">
              <FilterIcon />
            </Button>
            <Input
              className="searchInput"
              prefix={<SearchIcon />}
              placeholder="Qidirish..."
              onChange={changeSearchText}
            />
          </div>
          <TableCustom columns={columns} data={data?.data!} loading={isLoading} />
        </div>
        <PaginationGlobal
          onChange={handlePage}
          total={data?.totalElements}
          pageSize={pageSize}
        />
      </div>
    </>
  )
}

export default Protocols
