import React from "react";

const SettingIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.99 6.08997C18.73 5.77997 18.3 5.68997 17.92 5.80997C17.11 6.07997 16.19 6.00997 15.39 5.55997C14.59 5.09997 14.07 4.33997 13.89 3.50997C13.8 3.10997 13.5 2.77997 13.1 2.71997C12.1 2.55997 11.06 2.55997 10.04 2.72997C9.64001 2.79997 9.35001 3.11997 9.26001 3.51997C9.08001 4.35997 8.57001 5.11997 7.77001 5.58997C6.97001 6.05997 6.05001 6.11997 5.24001 5.85997C4.86001 5.73997 4.43001 5.82997 4.17001 6.13997C3.52001 6.94997 3.01001 7.84997 2.66001 8.79997C2.52001 9.17997 2.66001 9.59997 2.96001 9.85997C3.59001 10.44 3.99001 11.27 4.00001 12.19C4.00001 13.12 3.60001 13.94 2.97001 14.52C2.67001 14.79 2.54001 15.21 2.68001 15.58C2.86001 16.05 3.08001 16.52 3.34001 16.97C3.60001 17.42 3.90001 17.84 4.22001 18.23C4.48001 18.54 4.91001 18.63 5.29001 18.51C6.10001 18.24 7.02001 18.31 7.82001 18.76C8.62001 19.22 9.14001 19.98 9.32001 20.81C9.41001 21.2 9.70001 21.53 10.1 21.59C11.11 21.75 12.14 21.75 13.16 21.58C13.56 21.51 13.85 21.19 13.94 20.79C14.12 19.95 14.63 19.19 15.43 18.72C16.23 18.25 17.15 18.19 17.96 18.45C18.35 18.57 18.78 18.48 19.03 18.16C19.68 17.35 20.19 16.45 20.55 15.5C20.69 15.12 20.55 14.7 20.25 14.44C19.61 13.87 19.21 13.04 19.2 12.12C19.2 11.19 19.6 10.37 20.23 9.78997C20.53 9.51997 20.66 9.09997 20.52 8.72997C20.34 8.25997 20.12 7.78997 19.86 7.33997C19.61 6.88997 19.31 6.47997 18.99 6.08997Z"
        stroke="#888888"
        strokeWidth="1.2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.61 15.12C13.2448 15.12 14.57 13.7947 14.57 12.16C14.57 10.5252 13.2448 9.19998 11.61 9.19998C9.97526 9.19998 8.65002 10.5252 8.65002 12.16C8.65002 13.7947 9.97526 15.12 11.61 15.12Z"
        stroke="#888888"
        strokeWidth="1.2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SettingIcon;
