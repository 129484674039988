import {
  closestCenter,
  DndContext,
  DragOverlay,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  rectSortingStrategy,
  SortableContext,
  sortableKeyboardCoordinates,
} from "@dnd-kit/sortable";
import { Button, Spin } from "antd";
import React, { useEffect, useState } from "react";
import KategorySettingModal from "../../../components/setting/kategory-setting/KategorySettingModal";
import SortableItem from "../../../components/setting/kategory-setting/SortableItem";
import { useCategorySortParents } from "../../../queries/mutation";
import { useKategorySetting } from "../../../queries/queries";
import styles from "./kategorySetting.module.scss";

const KategorySetting = () => {
  const { data, isLoading, isFetching } = useKategorySetting();
  const sortMutation = useCategorySortParents();
  const [activeId, setActiveId] = useState(null);
  const [items, setItems] = useState<any>([]);
  useEffect(() => {
    setItems(
      data?.data
        .sort((a: any, b: any) => a.step - b.step)
        .map((item) => item.id)
    );
  }, [data]);
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleDragStart = (event: any) => {
    document.body.style.cursor = "move";
    setActiveId(event.active.id);
  };

  const handleDragEnd = (event: any) => {
    document.body.style.cursor = "default";
    setActiveId(null);
    const { active, over } = event;
    const arr: any = arrayMove(
      items,
      items.indexOf(active.id),
      items.indexOf(over.id)
    );

    if (active.id !== over.id) {
      setItems((items: any) => {
        const oldIndex = items.indexOf(active.id);
        const newIndex = items.indexOf(over.id);

        return arrayMove(items, oldIndex, newIndex);
      });
    }

    sortMutation.mutate(
      arr.map((item: any, index: number) => {
        return {
          categoryId: item,
          step: index + 1,
        };
      })
    );
  };

  const [kategorySettingModal, setKategorySettingModal] = useState<any>({
    visible: false,
    id: null,
    imageId: null,
    name: "",
  });

  return (
    <div className={styles.kategorySetting}>
      <div className={styles.kategorySetting_title}>
        <h1>Kategoriya sozlamalari</h1>
        <Button
          type="link"
          onClick={() =>
            setKategorySettingModal({
              id: null,
              visible: true,
              imageId: null,
              name: "",
            })
          }
        >
          + Kategoriya yaratish
        </Button>
      </div>
      <Spin spinning={isLoading || isFetching}>
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
          onDragStart={handleDragStart}
        >
          <SortableContext
            items={items ? items : []}
            strategy={rectSortingStrategy}
          >
            {items?.map((id: any) => (
              <SortableItem
                key={id}
                id={id}
                handle={true}
                value={id}
                data={data ? data?.data.filter((el) => el.id === id && el) : []}
                setKategorySettingModal={setKategorySettingModal}
              />
            ))}
            <DragOverlay>
              {activeId ? (
                <div
                  style={{
                    width: "100%",
                    height: "70px",
                    border: "1px solid #ccc",
                  }}
                ></div>
              ) : null}
            </DragOverlay>
          </SortableContext>
        </DndContext>
      </Spin>
      <KategorySettingModal
        obj={kategorySettingModal}
        setVisible={setKategorySettingModal}
      />
    </div>
  );
};

export default KategorySetting;
