import React from "react";

const NumberCircleEightIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
        stroke="#888888"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.0628 9.56248C14.06 10.037 13.8929 10.496 13.5899 10.8612C13.2869 11.2265 12.8668 11.4755 12.4009 11.5659C11.935 11.6564 11.4522 11.5826 11.0346 11.3572C10.617 11.1319 10.2903 10.7688 10.1102 10.3297C9.93006 9.89067 9.90759 9.40277 10.0466 8.94903C10.1856 8.49528 10.4775 8.1037 10.8727 7.84089C11.2678 7.57808 11.7418 7.46028 12.214 7.50751C12.6862 7.55475 13.1275 7.76411 13.4628 8.09998C13.8465 8.49016 14.0619 9.01524 14.0628 9.56248Z"
        stroke="#888888"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.4378 14.0625C14.4362 14.6239 14.2402 15.1674 13.883 15.6006C13.5259 16.0337 13.0297 16.3297 12.4789 16.4382C11.9281 16.5467 11.3567 16.4609 10.862 16.1956C10.3673 15.9302 9.97976 15.5016 9.76545 14.9828C9.55113 14.4639 9.52323 13.8868 9.68649 13.3497C9.84975 12.8125 10.1941 12.3486 10.6609 12.0367C11.1277 11.7249 11.6882 11.5845 12.2469 11.6393C12.8056 11.6941 13.328 11.9409 13.7253 12.3375C13.952 12.5639 14.1316 12.8328 14.2539 13.1289C14.3762 13.4249 14.4387 13.7422 14.4378 14.0625Z"
        stroke="#888888"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default NumberCircleEightIcon;
