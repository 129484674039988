import React from "react";

const FilePlusIcon = () => {
  return (
    <svg
      width="36"
      height="37"
      viewBox="0 0 36 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28.125 32H7.875C7.57663 32 7.29048 31.8815 7.07951 31.6705C6.86853 31.4595 6.75 31.1734 6.75 30.875V6.125C6.75 5.82663 6.86853 5.54048 7.07951 5.32951C7.29048 5.11853 7.57663 5 7.875 5H21.375L29.25 12.875V30.875C29.25 31.1734 29.1315 31.4595 28.9205 31.6705C28.7095 31.8815 28.4234 32 28.125 32Z"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.375 5V12.875H29.25"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.5 19.625H22.5"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.5 24.125H22.5"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M28.8452 26.9666C28.0169 26.9666 27.2071 27.2122 26.5184 27.6724C25.8296 28.1326 25.2928 28.7867 24.9758 29.552C24.6588 30.3173 24.5759 31.1595 24.7375 31.9719C24.8991 32.7843 25.298 33.5306 25.8837 34.1164C26.4694 34.7021 27.2157 35.101 28.0282 35.2626C28.8406 35.4242 29.6827 35.3413 30.448 35.0243C31.2133 34.7073 31.8674 34.1704 32.3277 33.4817C32.7879 32.7929 33.0335 31.9832 33.0335 31.1548C33.0314 30.0447 32.5894 28.9806 31.8044 28.1956C31.0195 27.4106 29.9554 26.9687 28.8452 26.9666Z"
        fill="white"
      />
      <path
        d="M28.8452 26.9666C28.0169 26.9666 27.2071 27.2122 26.5184 27.6724C25.8296 28.1326 25.2928 28.7867 24.9758 29.552C24.6588 30.3173 24.5759 31.1595 24.7375 31.9719C24.8991 32.7843 25.298 33.5306 25.8837 34.1164C26.4694 34.7021 27.2157 35.101 28.0282 35.2626C28.8406 35.4242 29.6827 35.3413 30.448 35.0243C31.2133 34.7073 31.8674 34.1704 32.3277 33.4817C32.7879 32.7929 33.0335 31.9832 33.0335 31.1548C33.0314 30.0447 32.5894 28.9806 31.8044 28.1956C31.0195 27.4106 29.9554 26.9687 28.8452 26.9666ZM30.4561 31.477H29.1674V32.7657C29.1674 32.8511 29.1335 32.9331 29.0731 32.9935C29.0126 33.0539 28.9307 33.0879 28.8452 33.0879C28.7598 33.0879 28.6779 33.0539 28.6174 32.9935C28.557 32.9331 28.5231 32.8511 28.5231 32.7657V31.477H27.2344C27.1489 31.477 27.067 31.443 27.0066 31.3826C26.9461 31.3222 26.9122 31.2403 26.9122 31.1548C26.9122 31.0694 26.9461 30.9874 27.0066 30.927C27.067 30.8666 27.1489 30.8326 27.2344 30.8326H28.5231V29.5439C28.5231 29.4585 28.557 29.3766 28.6174 29.3161C28.6779 29.2557 28.7598 29.2218 28.8452 29.2218C28.9307 29.2218 29.0126 29.2557 29.0731 29.3161C29.1335 29.3766 29.1674 29.4585 29.1674 29.5439V30.8326H30.4561C30.5416 30.8326 30.6235 30.8666 30.6839 30.927C30.7443 30.9874 30.7783 31.0694 30.7783 31.1548C30.7783 31.2403 30.7443 31.3222 30.6839 31.3826C30.6235 31.443 30.5416 31.477 30.4561 31.477Z"
        fill="#3772FF"
      />
    </svg>
  );
};

export default FilePlusIcon;
