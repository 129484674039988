import { PlusOutlined } from "@ant-design/icons";
import {
  closestCenter,
  DndContext,
  DragOverlay,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  rectSortingStrategy,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Avatar, Button, Input } from "antd";
import React, { FC, useEffect, useState } from "react";
import ArrowCollapseIcon from "../../../assets/icon/ArrowCollapseIcon";
import DragIcon from "../../../assets/icon/DragIcon";
import EditLinearIcon from "../../../assets/icon/EditLinearIcon";
import ImageDefault from "../../../assets/icon/ImageDefault";
import TickCircleIcon from "../../../assets/icon/TickCircleIcon";
import TrashLinearIcon from "../../../assets/icon/TrashLinearIcon";
import {
  useCategorySortSubs,
  useCategorySubsCreate,
} from "../../../queries/mutation";
import { useSubKategorySetting } from "../../../queries/queries";
import { BASE_URL } from "../../../service/RequestService";
import { CategoryParents } from "../../../utils/models/CategoryParents";
import { CategorySubs } from "../../../utils/models/CategorySubs";
import SortableSubItem from "./SortableSubItem";

interface SortableItemProps {
  id: number;
  handle: boolean;
  value: number;
  data: CategoryParents[];
  setKategorySettingModal: (value: any) => void;
}

const SortableItem: FC<SortableItemProps> = ({
  id,
  data,
  handle,
  value,
  setKategorySettingModal,
}) => {
  const { data: subCategory } = useSubKategorySetting(data[0]?.id);

  const subsSort = useCategorySortSubs(data[0]?.id);
  const [activeId, setActiveId] = useState(null);
  const [items, setItems] = useState<any>([]);
  useEffect(() => {
    setItems(
      subCategory?.data
        .sort((a: any, b: any) => a.step - b.step)
        .map((item) => item.id)
    );
  }, [subCategory]);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleDragStart = (event: any) => {
    document.body.style.cursor = "move";
    setActiveId(event.active.id);
  };

  const handleDragEnd = (event: any) => {
    document.body.style.cursor = "default";
    setActiveId(null);
    const { active, over } = event;
    const arr: any = arrayMove(
      items,
      items.indexOf(active.id),
      items.indexOf(over.id)
    );

    if (active.id !== over.id) {
      setItems((items: any) => {
        const oldIndex = items.indexOf(active.id);
        const newIndex = items.indexOf(over.id);

        return arrayMove(items, oldIndex, newIndex);
      });
    }

    subsSort.mutate(
      arr.map((item: any, index: number) => {
        return {
          categoryId: item,
          step: index,
        };
      })
    );
  };

  const [active, setActive] = useState(false);
  const [height, setHeight] = useState(0);
  const [subCategoryCreate, setSubCategoryCreate] = useState<CategorySubs[]>(
    []
  );
  const createSubCategory = useCategorySubsCreate(id);

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: id });

  const sortableHandle = (e: any) => {
    e.stopPropagation();
    setActive(!active);
    setHeight(
      e.target.parentElement.querySelector(".CollapseSortablePanel_content")
        .scrollHeight
    );
  };

  return (
    <div
      ref={setNodeRef}
      style={{
        transform: CSS.Transform.toString(transform),
        transition,
        zIndex: isDragging ? "100" : "auto",
        opacity: isDragging ? 0.3 : 1,
        marginBottom: active ? `${height + 8}px` : "8px",
      }}
      className={`CollapseSortablePanel ${active ? "active" : ""}`}
    >
      <div className="CollapseSortablePanel_header" onClick={sortableHandle}>
        <div style={{ pointerEvents: "none" }}>
          <Avatar
            size={50}
            icon={<ImageDefault width="27" height="27" />}
            src={BASE_URL + "/" + data[0].image?.absolutePath}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          />
          <b>{data[0].name}</b>
        </div>
        <div>
          <Button
            shape="circle"
            onClick={(e) => {
              e.stopPropagation();
              setKategorySettingModal({
                id: data[0].id,
                visible: true,
                imageId: data[0].image?.id,
                name: data[0].name,
              });
            }}
          >
            <EditLinearIcon />
          </Button>
          <span style={{ pointerEvents: "none" }}>
            <ArrowCollapseIcon />
          </span>
          <Button
            {...listeners}
            {...attributes}
            onDragStart={() => setActive(false)}
            style={{ border: "none" }}
          >
            <DragIcon />
          </Button>
        </div>
      </div>
      <div
        className="CollapseSortablePanel_content"
        style={{
          maxHeight: active ? `${height}px` : "0px",
        }}
      >
        <div>
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
            onDragStart={handleDragStart}
          >
            <SortableContext
              items={items ? items : []}
              strategy={rectSortingStrategy}
            >
              {items?.map((id: any) => (
                <SortableSubItem
                  key={id}
                  id={id}
                  handle={true}
                  value={id}
                  parentId={data[0].id}
                  data={
                    subCategory
                      ? subCategory?.data.filter((el) => el.id === id && el)
                      : []
                  }
                />
              ))}
              <DragOverlay>
                {activeId ? (
                  <div
                    style={{
                      width: "100%",
                      height: "48px",
                      border: "1px solid #ccc",
                    }}
                  ></div>
                ) : null}
              </DragOverlay>
            </SortableContext>
          </DndContext>

          {subCategoryCreate?.map((item, index) => {
            return (
              <>
                <div
                  style={{ display: "flex", marginBottom: 8 }}
                  className="CollapseSortablePanel_content_item"
                >
                  <Input
                    placeholder="Yangi kategory nomini kiriting"
                    defaultValue={item.name}
                    value={item.name}
                    onChange={(e) => {
                      setSubCategoryCreate(
                        subCategoryCreate.map((el) => {
                          if (el.step === item.step) {
                            return { ...item, name: e.target.value };
                          }
                          return el;
                        })
                      );
                    }}
                    onKeyUp={(e) => {
                      if (e.keyCode === 13) {
                        createSubCategory.mutate({
                          name: item.name,
                          step: item.step,
                          parentId: id,
                        });
                        setSubCategoryCreate(
                          subCategoryCreate.filter(
                            (el) => el.step !== item.step
                          )
                        );
                      }
                    }}
                    style={{ width: "100%" }}
                    className="customInput"
                  />
                  {item.name.length > 0 && (
                    <Button
                      shape={"circle"}
                      onClick={() => {
                        createSubCategory.mutate({
                          name: item.name,
                          step: item.step,
                          parentId: id,
                        });
                        setSubCategoryCreate(
                          subCategoryCreate.filter(
                            (el) => el.step !== item.step
                          )
                        );
                      }}
                    >
                      <TickCircleIcon />
                    </Button>
                  )}
                  <Button
                    onClick={() => {
                      setSubCategoryCreate(
                        subCategoryCreate.filter((el) => el.step !== item.step)
                      );
                      setHeight(height - 44);
                    }}
                    shape={"circle"}
                  >
                    <TrashLinearIcon />
                  </Button>
                </div>
              </>
            );
          })}
          <Button
            onClick={() => {
              setSubCategoryCreate((prev) => [
                ...prev,
                {
                  name: "",
                  step: subCategory
                    ? subCategory?.data.length + subCategoryCreate.length + 1
                    : subCategoryCreate.length + 1,
                },
              ]);
              setHeight(height + 44);
            }}
            icon={<PlusOutlined />}
            block
          >
            Kichik kategoriya yaratish
          </Button>
        </div>
      </div>
    </div>
  );
};

export default SortableItem;
